import _ from 'lodash';
import dayjs from 'dayjs';
import { VariantsCRUD, updateVariantDocs, deleteVariantDocs, createFirstVariant } from "./VariantsCRUD";
import { registerBlockDefinitions, registerBlocks } from "../blockStack/BlockStack";
import BlockProductGridWithFilters from "./BlockProductGridWithFilters";
import BlockProductView from "./BlockProductView";
import BlockCartView from "./BlockCartView";
import { UnderListItemTitleForCartItems } from './UnderListItemTitleForCartItems';
import dataTypeFields from '../entity/dataTypeFields';
import { IonButton, IonButtons, IonIcon, IonLabel } from '@ionic/react';
import { gridOutline } from 'ionicons/icons';
import { urlPrefix } from '../instance/utilsInstance';
import { MaxCountLimitAlert } from '../entity/MaxCountLimitAlert';
import { usePanel } from '../panel/usePanel';
import dataTypeBlockStack from '../blockStack/dataTypeBlockStack';

/*
  carts: [{

    userId,
    
    isPaid,
    paymentImage,

    observation,

    priceTotal,

    items: [{
      id,        // id in cart
      extraOfId, // sub item of item in cart, example: toppings
      
      itemId,    // id of the entityDoc
      itemType,  // entitySlug of the item, example: realEstates, products, meals
      
      qty,
      priceOne,
      priceTotal
    }]

  }],
*/

const canCreate = (contractModelMetaData) => {
  return _.isUndefined(contractModelMetaData?.serviceLimits?.createItems) 
          ? true // true by defaut
          : contractModelMetaData?.serviceLimits?.createItems === true;
};

const getCrudSettingsCartItems = ({ contractModelMetaData }) => {
  return {
    redirectOnSave: 'form',
    queryToCountForLimits: { 
      mainAvailable: 'true',
      deleted: 'false'
    },
    fieldForCountLimit: 'maxItems',
    showBtnAdd: canCreate(contractModelMetaData)
  };
};

const ListHeaderExtraActionsCartItems = ({ Model, isAllowed, canAddMoreDocs, entitySlug, entityDoc, location, entitySpecs, moduleSettings }) => {
  const { attachPrefix } = usePanel();
  return (
    <IonButtons slot="start">
      {!canCreate(canAddMoreDocs.contractModelMetaData) ? (
        <IonButtons slot="start">
          <IonButton
            routerLink={attachPrefix(`/a/instancesBilling/update/#/openSelector/true`)}
            size="small"
            fill="outline"
            color="primary"
          >
            Aumentar Plan
          </IonButton>
          <IonLabel color="primary" className="ml-2 text-xs md:text-sm">
            Para agregar items al catálogo
          </IonLabel>
        </IonButtons>
      ) : (
        <MaxCountLimitAlert canAddMoreDocs={canAddMoreDocs} actionToDo="ocultar o eliminar" />
      )}
    </IonButtons>
  );
};

const FormToolbarEndCartItems = ({ doc, form, values, classes, handleSubmit, submitting, fieldsRequired, instance, entitySlug, isAllowed, history, match }) => (<>
  <IonButton href={urlPrefix(instance, `/m/p/items/#/main/${doc.id}/variant/first`)} color="medium" fill="clear" size="small">
    <IonIcon slot="start" icon={gridOutline} className="text-xl"></IonIcon>
    Ver
  </IonButton>
</>);

const UnderListItemTitleForCarts = ({ doc, ViewData }) => (<>
  <span className="px-1 py-0.5 font-mono bg-gray-900 text-white rounded-md">{doc?.id}</span>
  <div className={`py-0.5 text-sm text-black`}>{dayjs(doc?.data?.createdAt).format('LLLL')}</div>
</>);

registerBlocks([
  BlockProductGridWithFilters,
  BlockProductView,
  BlockCartView
]);

registerBlockDefinitions({
  productGrid: {
    title: 'Catálogo: Productos con Filtros',
    description: `
      Muestra una cuadrícula de productos con filtros para que los usuarios puedan navegar 
      y seleccionar los productos que desean comprar.
    `
  },
  productView: {
    title: 'Catálogo: Vista de Producto',
    description: `
      Muestra la información detallada de un producto, incluyendo imágenes, 
      descripción, precio y opciones de compra.
    `
  },
  cartView: {
    title: 'Catálogo: Vista del Carrito',
    description: `
      Muestra los productos que el usuario ha agregado al carrito, incluyendo la cantidad y el precio,
      además tiene la opción de contactar con el vendedor por WhatsApp enviando el enlace del pedido.
    `
  }
});

export default function () {
  return {
    name: 'Catálogo y pedidos',
    slug: 'cart',
    scope: 'instance',
    bundles: ['store'],
    entities: [
      'carts',
      'cartItems',
      'cartItemVariants',
      'cartItemCategories',
      'cartItemFields'
    ],

    permissions: [
      { slug: 'carts', label: 'Pedidos' },
      { slug: 'cartItems', label: 'Productos' },
      { slug: 'cartItemVariants', label: 'Variantes de Productos' },
      { slug: 'cartItemCategories', label: 'Categorías de productos' },
      { slug: 'cartItemFields', label: 'Atributos de items' }
    ],

    components: {
      // 'main': {
      //   AdminHomeBeforeLinksList
      // },
      'carts': {
        crud: {
          UnderListItemTitle: UnderListItemTitleForCarts,
        }
      },
      'cartItems': {
        crud: {
          settings: getCrudSettingsCartItems,
          UnderListItemTitle: UnderListItemTitleForCartItems,
          'FormAppend-mainAvailable': VariantsCRUD,
          FormToolbarEnd: FormToolbarEndCartItems,
          ListHeaderExtraActions: ListHeaderExtraActionsCartItems
        },
        codedDataTypes: {
          'blocks': dataTypeBlockStack
        }
      },
      'cartItemFields': {
        codedDataTypes: {
          'fields': dataTypeFields
        }
      },
    },
    events: {
      'cartItems': {
        beforeSave: updateVariantDocs,
        afterSave: createFirstVariant,
        beforeDelete: deleteVariantDocs
      }
    }
  };
};