import _ from 'lodash';
import useBreakpoints from "../../../libs/useBreakpoints";
import { useMemo } from 'react';
import config from '../../../config';
import { parseScreensStyles, screensField } from './stylesFields';
import { Card } from './PartCard';


export const textSizesOptions = ['xs', 'xsm', 'sm', 'base', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl'];
export const alignOptions = [{ value: 'left', label: 'Izquierda' }, { value: 'center', label: 'Centro' }, { value: 'right', label: 'Derecha' }, { value: 'justify', label: 'Justificado' }];
export const fontVariations = [{ value: 'normal', label: 'Normal' }, { value: 'bold', label: 'Negrita' }, { value: 'italic', label: 'Cursiva' }, { value: 'underline', label: 'Subrayado' }, { value: 'overline', label: 'Subrayado superior' }, { value: 'line-through', label: 'Tachado' }];
export const fontsOptions = [{ value: 'main', label: 'Principal' }, { value: 'secondary', label: 'Secundaria' }];

export const textFields = {
  'screen': screensField,
  'textSize': {
    type: 'select',
    name: 'Tamaño de fuente',
    options: textSizesOptions,
    design: 'buttons'
  },
  'textAlign': {
    type: 'select',
    name: 'Alineación del texto',
    options: alignOptions,
    design: 'buttons'
  },
  'fontVariations': {
    type: 'multiselect',
    name: 'Estilo de fuente',
    options: fontVariations,
    design: 'buttons'
  },
  'font': {
    type: 'select',
    name: 'Fuente',
    options: fontsOptions,
    design: 'buttons'
  },
  'textColor': {
    type: 'color',
    name: 'Color del texto'
  }
};

export default function () {
  return {
    part: 'text',
    name: 'Estilos de texto',
    template: Text,
    type: 'collection',
    fields: textFields
  };
};

export const textClasses = `
  text-xs text-sm text-base text-md text-lg text-xl text-2xl text-3xl text-4xl text-5xl text-6xl
  text-left text-center text-right text-justify
  text-normal font-bold italic underline line-through
  font-brand-main font-brand-secondary
`;

export const textClassesParser = (props) => {
  if (!props) { return ''; }
  const { textSize, textAlign, font } = props;
  let className = '';
  // textSize
  if (textSize) {
    className += ` text-${textSize}`;
  }
  // textAlign
  if (textAlign) {
    className += ` text-${textAlign}`;
  }
  // fontVariations
  if (props.fontVariations) {
    props.fontVariations.forEach(variation => {
      if (variation === 'bold') {
        className += ` font-${variation}`;
      }
      else {
        className += ` ${variation}`;
      }
    }); 
  }
  // font
  if (font) {
    className += ` font-brand-${font}`;
  }
  return className;
}

export function Text (props) {
  let {
    textParams,
    cardParams,
    specDesign,
    classDefault = '',
    className,
    children
  } = props;
  const { brkPnt } = useBreakpoints();
  const { classesByScreen, stylesByScreen } = useMemo(() => parseScreensStyles(textParams, textClassesParser), [textParams]);
  const classesToUse = textParams ? classesByScreen[brkPnt] : classDefault;
  const colorsMap = props.colors || specDesign?.colors || config?.colors || {};
  
  const getColorHex = (val) => {
    return val?.hex || colorsMap[val] || val;
  };

  let stylesToUse = {
    color: getColorHex(stylesByScreen[brkPnt]?.textColor)
  };
  
  return (
    <div>
      <Card params={cardParams} specDesign={specDesign}>
        <div className={`${className || ''} ${classesToUse}`} style={stylesToUse}>
          {children}
        </div>
      </Card>
    </div>
  );
}
