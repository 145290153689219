import config from "../../../config";
import Model from "../../../libs/ModelClass";

/**
 * Eliminar taxonomyType de menuLogo en MainPartsEntity
 */
export default async function migration() {
  console.log('migration 002-eliminar-menuMain');
  
  const EntityModel = Model.extend(config.modules.entity.entitiesEntitySlug);
  
  // Eliminar taxonomyType de menuLogo en MainPartsEntity
  const TaxonomyTypeModel = Model.extend(config.modules.entity.taxonomyTypesEntitySlug);
  const instancesMainParts = await EntityModel.filterByAttributes({
    'nameSlug': config.modules.instances.instancesMainPartsEntitySlug,
    'isInstance': 'false',
    'deleted': 'false'
  });
  const entityDoc = instancesMainParts[0];
  console.log('entityDoc', entityDoc);
  if (entityDoc) {
    const taxonomyTypeDocs = await TaxonomyTypeModel.filterByAttributes({
      nameSlug: 'menuLogo',
      entityId: entityDoc.id,
      deleted: 'false'
    });
    const taxonomyTypeDoc = taxonomyTypeDocs[0];
    if (taxonomyTypeDoc) {
      taxonomyTypeDoc.data.deleted = true;
      console.log('taxonomyTypeDoc', taxonomyTypeDoc)
      await taxonomyTypeDoc.save();
    }
    else {
      console.log('taxonomyTypeDoc not found');
    }
  }
  else {
    console.log('entityDoc de instancesMainParts not found');
  }
}