import { noneOption } from "./stylesFields";

export const roundField = {
  type: 'select',
  name: 'Redondeado del borde',
  descriptionText: 'Redondeado del borde',
  options: [noneOption, 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl', '7xl', '8xl', '9xl', 'full'],
  design: 'buttons'
};

export const applyClassRound = (round, className = '') => {
  if (round) {
    if (round === 'xs') {
      className += ' rounded-xs';
    }
    else if (round === 'sm') {
      className += ' rounded-sm';
    }
    else if (round === 'md') {
      className += ' rounded-md';
    }
    else if (round === 'lg') {
      className += ' rounded-lg';
    }
    else if (round === 'xl') {
      className += ' rounded-xl';
    }
    else if (round === '2xl') {
      className += ' rounded-2xl';
    }
    else if (round === '3xl') {
      className += ' rounded-3xl';
    }
    else if (round === '4xl') {
      className += ' rounded-[2rem]';
    }
    else if (round === '5xl') {
      className += ' rounded-[2.5rem]';
    }
    else if (round === '6xl') {
      className += ' rounded-[3.2rem]';
    }
    else if (round === '7xl') {
      className += ' rounded-[4rem]';
    }
    else if (round === '8xl') {
      className += ' rounded-[5rem]';
    }
    else if (round === '9xl') {
      className += ' rounded-[6.5rem]';
    }
    else if (round === 'full') {
      className += ' rounded-full';
    }

    className += ' overflow-hidden';
  }

  return className;
};

export const roundClasses = `
  rounded-xs rounded-sm rounded-md rounded-lg rounded-xl rounded-2xl rounded-3xl rounded-full
  rounded-[2rem] rounded-[2.5rem] rounded-[3.2rem] rounded-[4rem] rounded-[5rem] rounded-[6.5rem]
  overflow-hidden
`;