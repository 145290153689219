import { useState } from "react";
import { IonAlert } from "@ionic/react";
import toast from "react-hot-toast";
import { useModule } from "../../libs/ModuleContext";


const ModalConfirmDelete = ({ entitySlug, selectedDoc, afterDelete, onCancel }) => {
  const { fireEventReducer } = useModule();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleConfirmedDelete = async () => {
    if (!selectedDoc) return;
    
    try {
      setIsProcessing(true);
      await fireEventReducer('beforeDelete', { doc: selectedDoc, entitySlug });
      await selectedDoc.delete();
      toast.success('Documento eliminado con éxito');
      afterDelete && afterDelete();  // Ejecutar acción post eliminación
    } catch (error) {
      console.error(error);
      toast.error('Error al eliminar el documento');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <IonAlert
      isOpen={!!selectedDoc}
      onDidDismiss={onCancel}
      header="Confirmación"
      message="¿Estás seguro de que deseas eliminar este elemento?"
      buttons={[
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: onCancel,
        },
        {
          text: isProcessing ? 'Eliminando...' : 'Eliminar',
          role: 'destructive',
          cssClass: 'danger',
          handler: handleConfirmedDelete,
        },
      ]}
    />
  );
};

export default ModalConfirmDelete;
