import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import toast from 'react-hot-toast';
import IonBtnLoading from '../../components/ui/IonBtnLoading';
import SectionCrudObject from '../../components/SectionCrudObject';
import SectionCrudForm from '../../components/SectionCrudForm';
import { blocksDefinitions, blocksMap } from './BlockStack';
import { FormFieldSelect, FormFieldSelectButtons } from '../../components/Form';
import { EntityTaxonomyForm, inputClasses } from '../../components/EntityTaxonomyForm';
import { nanoid } from 'nanoid';
import { IonCard, IonCardContent, IonFooter, IonItem, IonLabel, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonButton, IonActionSheet, IonIcon } from '@ionic/react';
import { objectFormToTaxonomyTypes } from '../entity/ObjectForm';


export const BlockTypeSelector = (props) => {
  const { blocksDefinitions, assignBlock, design = "select", selectProps={}, buttonProps={} } = props;
  const [showActionSheet, setShowActionSheet] = useState(false);

  const blocksOptions = _.map(blocksDefinitions, (blockDef, blockName) => ({
    label: blockDef.title,
    value: blockName
  }));

  if (design === "select") {
    // Usar IonSelect si el diseño es "select"
    return (
      <IonSelect
        interface="action-sheet"
        size="small"
        placeholder="Agregar bloque"
        {...selectProps}
        interfaceOptions={{
          header: 'Bloques',
          subHeader: 'Seleccione el bloque para agregarlo',
        }}
        onIonChange={(e) => assignBlock(e.detail.value)}
        value={null}
      >
        {blocksOptions.map((option) => (
          <IonSelectOption key={option.value} value={option.value}>
            {option.label}
          </IonSelectOption>
        ))}
      </IonSelect>
    );
  } else {
    // Usar IonButton y IonActionSheet si el diseño es "button"
    return (
      <>
        <IonButton onClick={() => setShowActionSheet(true)} size="small" fill="outline" {...buttonProps}>
          {buttonProps.ionIcon ? (<>
            <IonIcon slot="icon-only" icon={buttonProps.ionIcon} />
            <span className="ml-1 5">Agregar bloque</span>
          </>) : (
            <span>Agregar bloque</span>
          )}
        </IonButton>
        
        <IonActionSheet
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          header="Bloques"
          buttons={blocksOptions.map((option) => ({
            text: option.label,
            handler: () => {
              assignBlock(option.value);
              setShowActionSheet(false); // Cierra el action sheet después de seleccionar
            }
          }))}
        />
      </>
    );
  }
};

export const getBlockVariantsByType = (blockType) => {
  return _.map(blocksMap[blockType], (def, variantName) => ({
    label: def.title || variantName,
    value: variantName
  }));
};

export const getBlockPresetsByType = (blockType, variantName) => {
  const blockDef = blocksMap[blockType][variantName];
  if (!blockDef) {
    return null;
  }
  let presets = _.map(blockDef.presets, (presetDef, presetName) => ({
    label: presetDef.title || presetName,
    value: presetName
  }));
  presets.unshift({
    label: 'Ninguno',
    value: null
  });
  return presets;
};

const ListItem = (({ doc }) => (<>
  <div className="text-sm text-gray-800 font-semibold">
    {blocksDefinitions[doc?.type]?.title}
    <span className="ml-1 text-xs text-gray-500">
      {blocksMap[doc.type] ? blocksMap[doc.type][doc.variant]?.title : ''}
    </span>
  </div>
  {doc?.variant ? (
    <div className="space-x-2">
      <span className="text-xs text-gray-500">
        {doc?.title || doc?.subtitle || doc?.items?.[0]?.title || doc?.items?.[0]?.subtitle}
      </span>
    </div>
  ) : null}

  <div className="text-sm text-gray-800">
    {doc?.params?.name}
  </div>
</>));

export const FormInputFields = (props) => {
  const { fieldName, index, instance, entitySlug, form, values, handleSubmit, submitting, fieldsRequired } = props;
  
  const blockParamsTaxonomyTypes = useMemo(() => (
    objectFormToTaxonomyTypes(blocksMap[values.type][values.variant]?.params, { instance })
  ), [values.variant]);
  const blockStringsTaxonomyTypes = useMemo(() => (
    objectFormToTaxonomyTypes(blocksMap[values.type][values.variant]?.strings, { instance })
  ), [values.variant]);

  const [ tab, setTab ] = useState(blocksMap[values.type][values.variant]?.showStack ? 'cards' : 'texts');

  const presetsList = getBlockPresetsByType(values.type, values.variant);

  // apply preset
  const applyPreset = (presetSlug) => {
    if (!presetSlug) {
      return null;
    }
    const presetDef = blocksMap[values.type][values.variant].presets[presetSlug];
    if (presetDef) {
      _.forEach(presetDef, (paramDef, paramName) => {
        form.change(paramName, paramDef);
      });
    }
  };

  if (!values.type) {
    return null;
  }

  const onCardListChange = (cardList) => {
    form.change(`stack`, cardList)
  };

  return (<>
    {/* title */}
    <div className="text-lg text-gray-800 font-semibold">
      {blocksDefinitions[values.type].title}
    </div>
    <div className="mb-4 border-l-4 border-gray-200 pl-2 text-xs text-gray-500">
      {blocksDefinitions[values.type].description}
    </div>

    <FormFieldSelectButtons
      name="variant"
      title="Variante"
      options={getBlockVariantsByType(values.type)}
      value={values.variant}
      classes={inputClasses}
      defaultValue="default"
    />

    {blockParamsTaxonomyTypes ? (<>
      <IonSegment value={tab} className="mb-4" size="small">
        {blockStringsTaxonomyTypes?.length ? (
          <IonSegmentButton value="texts" onClick={() => setTab('texts')}>
            <IonLabel>Contenido</IonLabel>
          </IonSegmentButton>
        ) : null}
        {blocksMap[values.type][values.variant]?.showStack ? (
          <IonSegmentButton value="cards" onClick={() => setTab('cards')}>
            <IonLabel>Tarjetas</IonLabel>
          </IonSegmentButton>
        ) : null}
        <IonSegmentButton value="configs" onClick={() => setTab('configs')}>
          <IonLabel>Estilos</IonLabel>
        </IonSegmentButton>
      </IonSegment>
    </>) : null}

    {tab === 'configs' && blockParamsTaxonomyTypes ? (<>
      {presetsList?.length > 1 ? (
        <div className="mb-2">
          <FormFieldSelect
            name="preset"
            title="Preajustes"
            options={presetsList}
            value={values.preset}
            classes={inputClasses}
            defaultValue="default"
            onSelect={applyPreset}
          />
        </div>
      ) : null}
      <EntityTaxonomyForm
        {...props}
        fieldName={null} // to use from taxonomyTypes
        taxonomyTypesDataList={blockParamsTaxonomyTypes}
        classes={inputClasses}
      />
    </>) : null}
    {tab === 'texts' && blockParamsTaxonomyTypes ? (<>
      <EntityTaxonomyForm
        {...props}
        fieldName={null} // to use from taxonomyTypes
        taxonomyTypesDataList={blockStringsTaxonomyTypes}
        classes={inputClasses}
      />
    </>) : null}
    {tab === 'cards' && blockParamsTaxonomyTypes ? (<>
      {/* <FormChangeSubscriber doc={doc} onFormChange={onFormChange} /> */}
      <BlockStackCreator {...{
        ...props,
        instance,
        entitySlug,
        stackType: 'card',
        stack: values.stack,
        showBtnSave: false,
        cardColor: 'medium',
        onChange: onCardListChange
      }} />
    </>) : null}
  </>);
};

export const BlockStackCreator = (props) => {
  let {
    instance,
    entitySlug,
    stackType,
    fieldName,
    stack = [],
    isSaving,
    save,
    showBtnSave,
    onChange
  } = props;
  const [ stackList, setStackList ] = useState(_.isArray(stack) ? stack : []);
  const [ selectedBlock, setSelectedBlock ] = useState(null);

  useEffect(() => {
    onChange && onChange(stackList);
  }, [stackList]);

  let blocksOptions = _.map(blocksDefinitions, (blockDef, blockName) => ({
    label: blockDef.title,
    value: blockName,
    blockDef
  }));

  const handleSaveAll = () => {
    return save(stackList);
  };

  const assignBlock = (optionValue) => {
    let variantToAdd = blocksMap[optionValue][_.keys(blocksMap[optionValue])[0]];
    const newBlock = {
      id: nanoid(),
      type: optionValue,
      variant: variantToAdd.variant
    };
    setStackList([...stackList, newBlock]);
    setSelectedBlock(null);
  };

  const handleSave = (formValues) => {
    setStackList((prevItems) => {
      const updatedItems = [...prevItems];
      const index = updatedItems.findIndex((doc) => doc.id === formValues.id);
      if (index !== -1) {
        updatedItems[index] = { ...formValues };
      }
      return updatedItems;
    });
  };

  const handleDelete = (docToDelete) => {
    setStackList((prevItems) => {
      return prevItems.filter((doc) => doc.id !== docToDelete.id);
    });
  };

  const handleReorder = (reorderedMap) => {
    setStackList(reorderedMap);
  };

  const onFormChange = (values, previousValues, form, doc) => {
    handleSave(values);
  };

  return (
    <IonCard className="m-0">
      <IonCardContent className={`p-0`}>
        {/* Stack list */}
        {stackList?.length ? (
          <SectionCrudObject
            instance={instance}
            entitySlug={entitySlug}
            editStyle="onsite"
            // title="Bloques seleccionados"
            docs={stackList}
            reorder={true}
            // show
            showBtnAdd={false}
            showToast={false}

            // validations
            // fieldsRequired={fieldsRequiredMenuFilter}
            // onValidation={onValidationMenuFilter}

            // callbacks
            onSave={handleSave}
            onDelete={handleDelete}
            onReorder={handleReorder}
            
            // UI
            ListItem={ListItem}
            FormInputFields={FormInputFields}
            FormSection={SectionCrudForm}
            formSectionProps={{
              showTitle: true,
              showSaveButton: false,
              saveBtnLabel: 'Aplicar',
              saveBtnColor: 'secondary',
              fieldName,
              onFormChange
            }}

            // classes
            classes={{
              sectionCrudContainer: "",
              formSectionContainer: "!mb-0",
              formSectionContainerOpen: "border-2 border-brand-secondary",
              formTitle: "!text-xs !font-semibold !text-inherit",
              cardContainer: "!m-0",
              listItemContainer: ""
            }}
          />
        ) : ''}
      </IonCardContent>
    
      <IonFooter className="grid grid-cols-2 gap-4">
        <IonItem lines="none">
          <BlockTypeSelector blocksDefinitions={blocksDefinitions} assignBlock={assignBlock} />
        </IonItem>

        {showBtnSave ? (
          <IonBtnLoading
            type="button"
            size="small"
            onClickAsync={handleSaveAll}
            disabled={isSaving}
            label={isSaving ? 'Guardando...' : 'Guardar'}
            className="col-start-2"
          />
        ) : null}
      </IonFooter>
      
    </IonCard>
  );
};

export const PageBlockStackCRUD = (props) => {
  let {
    instance,
    doc,
    entitySlug,
    taxonomyTypesDocList,
    isAllowed
  } = props;
  const [ isSaving, setIsSaving ] = useState(false);

  if (!doc.id) {
    return null;
  }

  const save = async (stack) => {
    try {
      setIsSaving(true);
      doc.data.stack = stack;
      await doc.save();
      toast.success('Datos guardados');
      setIsSaving(false);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      setIsSaving(false);
    }
  };

  const onFormChange = (values, previousValues, form) => {
    console.log('PageBlockStackCRUD onFormChange', values, previousValues, form);
    // doc.data.stack = form.getState().values?.stack;
  };

  return (
    <BlockStackCreator {...{
      instance,
      entitySlug,
      stackType: 'block',
      stack: doc?.data.stack,
      isSaving,
      save,
      showBtnSave: false,
      onFormChange
    }} />
  );
};