import { useState } from "react";
import { IonButton, IonIcon } from "@ionic/react";
import ReactMarkdown from "react-markdown";
import { 
  globeOutline,
  logoGoogle
} from 'ionicons/icons';
import BtnLoading from "./BtnLoading";
import Modal from "./Modal";
import { useAuth } from "../../modules/user/AuthContext";
import { useLoginUtils } from "../../modules/user/Login";
import { stackClasses } from "../../libs/utils";


export default function BtnSignUpModal(props) {
  let {
    color, fill, size,
    design,
    strings,
    history,
    instance
  } = props;
  const { user, loginWithGoogleAndVerify } = useAuth();
  const { redirectOnLogin, handleGoogleSignin } = useLoginUtils(user, false, loginWithGoogleAndVerify, history, instance);
  let [ isOpenModal, setOpenModal ] = useState(false);

  let classes = {
    btnIcon: '!-mb-1 w-7 h-7',
    btnButton: '!text-xl px-4 py-2 !rounded-lg'
  };
  if (design === 'small') {
    classes = {
      btnIcon: '!-mb-1 w-5 h-5',
      btnButton: '!text-base px-3 py-1.5 !rounded-md'
    };
  }
  classes = stackClasses(classes, props.classes);
  classes.hoverColors = 'hover:!bg-brand-tertiary hover:!text-brand-primary-contrast';

  return (<>
    <IonButton
      onClick={() => setOpenModal(true)}
      {...{color, fill, size}}
    >
      <IonIcon icon={globeOutline} slot="start" />
      {strings?.ctaLabel}
    </IonButton>

    {isOpenModal ? (
      <Modal
        title={strings?.modalTitle}
        open={isOpenModal}
        setOpen={setOpenModal}
      >
        <div className="p-4 markdown prose lg:prose-sm text-left">
          <ReactMarkdown>{strings?.modalContent}</ReactMarkdown>
        </div>

        <div className="p-4 bg-brand-secondary-shade">
          <BtnLoading
            label="Acceder con Gmail"
            icon={<IonIcon icon={logoGoogle} className="!-mb-1 w-5 h-5 text-red-600" />}
            onClickAsync={handleGoogleSignin}
            className="block mx-auto !items-start !justify-start px-3 py-2 w-[190px] !bg-white !text-gray-900 shadow-md !no-underline hover:underline hover:shadow-xl transition-all"
            colorClass="text-gray-400 hover:text-gray-700"           
          />
        </div>
      </Modal>
    ) : null}
  </>);
}