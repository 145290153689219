import _ from 'lodash';
import { colorGroups, colorModes } from '../layoutHome/applyLayoutDesign';
import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import ReactSelect, { components } from 'react-select';
import { getJson } from '../../libs/utils';
import { useAsyncMemo } from 'use-async-memo';
import { generateColorPalette } from '../../libs/utilsColors';
import RawInputColorLazy from '../../components/Form/RawInputColorLazy';


export const defaultColorsUrl = '/data/instancesBrands/defaultColorsPalette.json';

export const BasePaletteSelect = ({ value, onChange }) => {
  const [ innerValue, setInnerValue ] = useState(value);
  const baseColors = useAsyncMemo(() => getJson(defaultColorsUrl), []);

  const CustomSingleValue = ({ data }) => (
    <div className="cursor-pointer">
      {data.label}
      <ColorsPaletteShowInline displayedValue={data.value} />
    </div>
  );
  
  const CustomOption = (props) => {
    return (
      <components.Option {...props}>
        <CustomSingleValue {...props} />
      </components.Option>
    );
  };

  const setValue = (option) => {
    setInnerValue(option);
    onChange(option);
  };

  return (
    <ReactSelect
      options={baseColors?.map(color => ({
        label: color.name,
        value: color.colorsPalette
      }))}
      placeholder={`Seleccionar paleta de base`}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: 'lightgray',
          primary: 'darkgray',
        },
      })}
      value={innerValue}
      onChange={setValue}
      components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
    />
  );
};

const ColorsPalettePicker = (props) => {
  let { 
    value,
    onChange
  } = props;
  let valueToUse = _.isObject(value) ? value : {};

  const setColor = (colorName) => {
    return (color) => {
      onChange({
        ...valueToUse,
        ...generateColorPalette({
          [colorName]: color
        })
      });
    };
  };

  const setBaseColor = (colors) => {
    onChange({ ...colors.value });
  };

  return (<>
    <BasePaletteSelect
      value={null}
      onChange={setBaseColor}
    />
    <div className="mt-2 grid grid-flow-row grid-cols-1 gap-2 rounded-md border-gray-200 hover:border-gray-400 border flex-wrap">
      {colorGroups.map((colorGroup, index) => (
        <div className="p-2 text-xs uppercase font-semibold text-gray-600" key={index}>
          {colorGroup}
          <div className="mt-1 flex flex-row gap-1 flex-wrap">
            <RawInputColorLazy
              value={valueToUse[colorGroup]}
              onChange={setColor(colorGroup)}
              showPalette={false}
              showTitle={true}
              title={colorGroup}
            />
          </div>
        </div>
      ))}
    </div>
  </>);
};

const ColorsPaletteShow = ({ displayedValue }) => {
  return (
    <div className="flex flex-row gap-4 flex-wrap">
      {colorGroups.map((colorGroup, index) => (
        <div key={index} className="text-xs uppercase font-semibold text-gray-600">
          {colorGroup}
          <div className="mt-1 flex flex-row gap-1">
            {[colorGroup, ...colorModes].map((colorMode) => {
              let color = colorMode === colorGroup ? displayedValue[colorGroup] : displayedValue[`${colorGroup}-${colorMode}`];
              color = color?.hex || color || '#fff';
              return (
                <CopyToClipboard
                text={color}
                onCopy={() => toast(`Color ${color} copiado al portapapeles`, { icon: 'ℹ️' })}>
                  <div
                    key={`${colorGroup}-${colorMode}`}
                    type="button" 
                    className="w-8 h-8 rounded-full cursor-pointer border border-solid border-gray-300"
                    style={{ backgroundColor: color }}
                    title={`${colorGroup}-${colorMode}`}
                  />
                </CopyToClipboard>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}

const ColorsPaletteShowInline = ({ displayedValue = {} }) => {
  return (
    <div className="-space-x-1.5">
      {colorGroups.map((colorGroup, index) => (
        <div key={index} className="inline-block">
          {[colorGroup, ...colorModes].map((colorMode) => {
            let color = colorMode === colorGroup ? displayedValue[colorGroup] : displayedValue[`${colorGroup}-${colorMode}`];
            color = color?.hex || color || '#fff';
            return (
              colorMode === colorGroup ? (
                <div
                  key={`${colorGroup}-${colorMode}`}
                  type="button" 
                  className="w-5 h-5 rounded-full border border-solid border-gray-300"
                  style={{ backgroundColor: color }}
                />
              ) : null
            );
          })}
        </div>
      ))}
    </div>
  );
}

const dataTypeColorsPalette = {
  RenderForm: ColorsPalettePicker,
  RenderShow: ColorsPaletteShow,
  RenderShowList: ColorsPaletteShowInline
};

export default dataTypeColorsPalette;