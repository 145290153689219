import _ from 'lodash';
import useBreakpoints from "../../../libs/useBreakpoints";
import { useMemo } from 'react';
import config from '../../../config';
import { noneOption, parseScreensStyles, screensField } from './stylesFields';
import { BackgroundStatic, bgFields } from './PartBackground';
import { applyClassRound, roundField } from './FieldRound';


// Campos y opciones de alineación de la tarjeta
export const cardAlignOptions = [{ value: 'left', label: 'Izquierda' }, { value: 'center', label: 'Centro' }, { value: 'right', label: 'Derecha' }];
export const cardAlignField = {
  type: 'select',
  name: 'Alineación',
  options: cardAlignOptions,
  design: 'buttons'
};
export const cardWidthField = {
  type: 'select',
  design: 'buttons',
  name: 'Ancho',
  options: ['auto', { label: 'contenido', value: 'fit' }, 'full', '1/2', '1/3', '1/4', '1/5', '2/3', '3/4', '5/6']
};
export const spaceOptions = [noneOption, 2, 4, 6, 8, 10, 12, 16, 20, 24, 32, 40, 48, 56, 64, 72, 80, 96];

// Campos de estilos de tarjeta
export const cardStylesFields = {
  'align': cardAlignField,
  'width': cardWidthField,
  'bgParams': {
    type: 'object',
    part: 'background',
    name: 'Estilos del fondo',
    fields: bgFields
  },
  'shadow': {
    type: 'select',
    design: 'buttons',
    name: 'Sombra',
    options: [noneOption, 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', 'inner']
  },
  'padding': {
    type: 'select',
    design: 'buttons',
    name: 'Margen interno',
    options: spaceOptions
  },
  'marginY': {
    type: 'select',
    design: 'buttons',
    name: 'Margen externo vertical',
    options: spaceOptions
  },
  'marginX': {
    type: 'select',
    design: 'buttons',
    name: 'Margen externo horizontal',
    options: spaceOptions
  },
  'border': {
    type: 'select',
    design: 'buttons',
    name: 'Tamaño del borde',
    options: [noneOption, '1', '2', '4', '6', '8', '12']
  },
  'borderColor': {
    type: 'color',
    name: 'Color del borde'
  },
  'round': roundField,
};

export default function () {
  return {
    part: 'card',
    name: 'Estilos de tarjeta',
    template: Card,
    type: 'collection',
    fields: {
      'screen': screensField,
      ...cardStylesFields
    }
  };
};

const getColorHex = (val, colorsMap) => {
  return val?.hex || colorsMap[val] || val;
};

// Parser de clases de tarjeta
export const cardClassesParser = (props = {}, colorsMap = {}) => {
  const {
    border = '',
    padding = '',
    borderColor = '',
    round = '',
    shadow = '',
    marginY = '',
    marginX = '',
    width = '',
    align = '',
  } = props;

  const borderClass = border === '1' ? 'border' : border !== 'none' ? `border-${border}` : '';
  const marginYClass = marginY ? `my-${marginY}` : '';
  const marginXClass = marginX ? `mx-${marginX}` : '';
  const widthClass = width ? `w-${width}` : '';
  const shadowClass = shadow ? `shadow-${shadow}` : '';
  const alignClass = align === 'center' ? 'justify-center' :
                     align === 'right' ? 'justify-end' :
                     align === 'left' ? 'justify-start' : '';
  const paddingClass = padding && padding !== 'none' ? `p-${padding}` : '';
  const roundClass = applyClassRound(round);

  const cardStyles = {
    borderColor: getColorHex(borderColor, colorsMap),
  };

  const inCardStyles = {};

  return {
    outCard: `flex ${alignClass}`,
    card: `${widthClass} ${roundClass} ${borderClass} ${marginYClass} ${marginXClass} ${shadowClass}`,
    inCard: `${paddingClass}`,
    cardStyles,
    inCardStyles
  };
};

// Componente Card refactorizado
export function Card (props) {
  const {
    params,
    specDesign,
    classDefault = '',
    className = '',
    classes = {},
    children
  } = props;

  const { brkPnt } = useBreakpoints();

  // Colores y diseño específico
  const colorsMap = props.colors || specDesign.colors || config?.colors || {};

  // Parseamos clases y estilos según los params y breakpoint
  const { classesByScreen, stylesByScreen } = useMemo(() => parseScreensStyles(params, (p) => cardClassesParser(p, colorsMap)), [params]);

  // Clases y estilos resultantes del parser
  const { outCard, card, inCard, cardStyles, inCardStyles } = params ? classesByScreen[brkPnt] : classDefault;

  const bgParams = stylesByScreen[brkPnt]?.bgParams || {};

  // Clases y estilos a usar
  const outCardClasses = `
    ${className || ''}
    ${classes.cardBgContainer || ''}
    ${outCard || ''}
  `;

  const cardClasses = `
    ${classes.card || ''}
    ${classDefault || ''}
    ${card || ''}
  `;

  const inCardClasses = `
    ${classes.cardContainer || ''}
    ${inCard || ''}
  `;

  return (
    <div className={outCardClasses}>
      <BackgroundStatic
        params={bgParams}
        classes={{ bgContainer: cardClasses }}
        styles={{ bgContainer: cardStyles }}
        specDesign={specDesign}
      >
        <div className={inCardClasses} style={inCardStyles}>
          {children}
        </div>
      </BackgroundStatic>
    </div>
  );
}

export const cardClasses = `
  p-2 p-4 p-8 p-12 p-16 p-20
  m-2 m-4 m-8 m-12 m-16 m-20 m-24 m-32 m-40 m-48 m-56 m-64 m-72 m-80 m-96
  mx-2 mx-4 mx-8 mx-12 mx-16 mx-20 mx-24 mx-32 mx-40 mx-48 mx-56 mx-64 mx-72 mx-80 mx-96
  my-2 my-4 my-8 my-12 my-16 my-20 my-24 my-32 my-40 my-48 my-56 my-64 my-72 my-80 my-96
  w-auto w-fit w-full w-1/2 w-1/3 w-1/4 w-1/5 w-2/3 w-3/4 w-1/6 w-5/6
  border-none border-2 border-4 border-8 border-12 border
  shadow-xs shadow-sm shadow-md shadow-lg shadow-xl shadow-2xl shadow-inner
  justify-start justify-center justify-end
`;