import _ from 'lodash';
import config from '../../config';
import { RouteEntityCrudForm } from '../entity/RouteEntityCrudForm';
import { usePanel } from '../panel/usePanel';
import Model, { useStateSingleResult } from '../../libs/ModelClass';
import { useAsyncMemo } from 'use-async-memo';
import { useEffect, useMemo, useRef, useState } from 'react';
import { InstanceInlineSelectorForMain } from './InstanceSelector';
import BadgeLoading from '../../components/ui/BadgeLoading';
import { FormInstanceBrandWizard } from './FormInstanceBrandWizard';
import { useDesign } from '../blockStack/DesignLoader';


const instancesBrandsEntitySlug = config.modules.instances.instancesBrandsEntitySlug;
const InstancesBrandsModel = Model.extend(instancesBrandsEntitySlug);
const instancesEntitySlug = config.modules.instances.instancesEntitySlug;
const InstanceModel = Model.extend(instancesEntitySlug);

export const RouteInstanceBrandEdit = (props) => {
  let {
    parsedParams,
    history,
    showInstanceSelector = true
  } = props;
  const { selectedInstance } = usePanel();
  const [ selectedInner, setSelectedInner ] = useState(selectedInstance);
  const [ newBrandDoc, setNewBrandDoc ] = useState(null);
  const entityDoc = useStateSingleResult({
    Model: Model.extend('entities'), 
    nameSlug: instancesBrandsEntitySlug
  });
  const { applyDesign } = useDesign();

  useEffect(() => {
    setSelectedInner(selectedInstance);
  }, [selectedInstance]);

  // find or create brand doc
  const brandDoc = useAsyncMemo(async () => {
    if (newBrandDoc) {
      return newBrandDoc;
    }
    if (selectedInner?.id) {
      let doc = await InstancesBrandsModel.whereOne('instanceId', '==', selectedInner?.id);
      if (!doc) {
        doc = await InstancesBrandsModel.create({
          instanceId: selectedInner?.id
        });
      }
      return doc;
    }
    return null;
  }, [selectedInner?.id]);

  // for Main instance
  // show instance selector for global entities from main instance
  let TitleToolbarRightToUse;
  if (selectedInstance?.data.hash === 'main' && showInstanceSelector) {
    TitleToolbarRightToUse = (() => (
      entityDoc?.data && !entityDoc.data.isInstance && (
        <InstanceInlineSelectorForMain selectedInstance={selectedInner} setSelectedInstance={setSelectedInner} />
      )
    ));
  }

  const contextRef = useRef({
    entitySlug: instancesBrandsEntitySlug,
    docId: parsedParams.docId,
    breadcrumbs: [
      {
        title: "Branding de Instancia"
      }
    ],
    onAfterSave: async () => {
      // apply brand design
      await applyDesign(selectedInstance.data.hash, true);
    }
  });

  // assign new brand doc to state
  const onWizardUpdateDoc = async ({ doc }) => {
    setNewBrandDoc(doc);
    // apply brand design
    await applyDesign(selectedInstance.data.hash, true);
  };
  
  // show wizard form
  let ContentRender = useMemo(() => (
    brandDoc && brandDoc.id && !( // Check for brandDoc existence
      brandDoc.data.mainStringsId || 
      brandDoc.data.colorPaletteId || 
      brandDoc.data.mainPartsId || 
      brandDoc.data.layoutBlocksId
    ) ? (
      (props) => (
        <FormInstanceBrandWizard
          {...props}
          context={contextRef.current}
          selectedInstance={selectedInner}
          doc={brandDoc}
          onFinish={onWizardUpdateDoc}
        />
      )
    ) : (
      !brandDoc?.id ? (
        () => (
          <div className="pt-32 flex place-content-center content-center items-center font-brand-main">
            <BadgeLoading className="text-brand-dark" />
          </div>
        )
      ) : null
    )
  ), [newBrandDoc, parsedParams, brandDoc?.id, selectedInner?.id]);
  
  if (
    !selectedInner?.id
    || !brandDoc?.id
  ) {
    return (
      <div className="pt-32 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  return (
    <RouteEntityCrudForm
      {...props}
      doc={brandDoc}
      context={contextRef.current}
      selectedInstance={selectedInner}
      TitleToolbarRight={TitleToolbarRightToUse}
      ContentRender={ContentRender}
    />
  );
}
