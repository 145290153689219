import { useState, useEffect } from 'react';
import _ from 'lodash';

/*
current tailwind breakpoints
*/

export const screensSizes = [ 'xxs', 'xs', 'xsm', 'sm', 'md', 'lg', 'xl', '2xl' ];

const getDeviceConfig = (width) => {
  if(width < 400) {
    return 'xxs';
  } else if(width >= 400 && width < 550) {
    return 'xs';
  } else if(width >= 550 && width < 640) {
    return 'xsm';
  } else if(width >= 640 && width < 768 ) {
    return 'sm';
  } else if(width >= 768 && width < 1024) {
    return 'md';
  } else if(width >= 1024 && width < 1280) {
    return 'lg';
  } else if(width >= 1280 && width < 1536) {
    return 'xl';
  } else if(width >= 1536) {
    return '2xl';
  }
};

const useBreakpoints = () => {
  const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(window.innerWidth));
  
  useEffect(() => {
    const calcInnerWidth = _.throttle(function() {
      setBrkPnt(getDeviceConfig(window.innerWidth))
    }, 200); 
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  const isMinBreakpointActive = (brkAsk, points) => { 
    points = points || screensSizes;
    return _.indexOf(points, brkAsk) <= _.indexOf(points, brkPnt);
  }

  return { brkPnt, isMinBreakpointActive };
}
export default useBreakpoints;