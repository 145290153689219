import _ from 'lodash';
import React, { useMemo } from 'react';
import { getImageURL, stackClasses } from '../../../libs/utils';
import config from '../../../config';
import { parseScreensStyles, screensField } from './stylesFields';
import useBreakpoints from '../../../libs/useBreakpoints';


export const bgFields = {
  type: {
    name: 'Tipo de fondo',
    type: 'select',
    design: 'buttons',
    options: [{
      label: 'Color',
      value: 'color'
    }, {
      label: 'Imagen',
      value: 'image'
    }, {
      label: 'Video',
      value: 'video'
    }, {
      label: 'Gradiente',
      value: 'gradient'
    }]
  },
  bgColor: {
    name: 'Color',
    type: 'color'
  },
  bgUrl: {
    name: 'Imagen',
    type: 'gallery'
  },
  bgVideoUrl: {
    name: 'Video: URL',
    type: 'text'
  },
  // isStatic: { // for programmatic use
  //   type: 'boolean'
  // },
  bgOverlayColor: {
    name: 'Video e Imagen: Color de superposición',
    descriptionText: 'Capa de color superpuesta al fondo de video e imagen',
    type: 'color'
  },
  bgOverlayAlpha: {
    name: 'Video e Imagen: Opacidad de superposición',
    descriptionText: 'Opacidad de la capa de color superpuesta',
    helpText: 'Debe ser un número entero entre 1 y 100',
    type: 'number'
  },
  bgColorDirection: {
    name: 'Gradiente: Dirección',
    type: 'select',
    options: [
      { value: 'to top', label: 'Arriba' },
      { value: 'to bottom', label: 'Abajo' },
      { value: 'to left', label: 'Izquierda' },
      { value: 'to right', label: 'Derecha' },
      { value: 'to top left', label: 'Arriba izquierda' },
      { value: 'to top right', label: 'Arriba derecha' },
      { value: 'to bottom left', label: 'Abajo izquierda' },
      { value: 'to bottom right', label: 'Abajo derecha' }
    ]
  },
  bgColorFrom: {
    name: 'Gradiente: Color inicial',
    type: 'color'
  },
  bgColorTo: {
    name: 'Gradiente: Color final',
    type: 'color'
  }
};

export default function () {
  return {
    part: 'background',
    name: 'Estilos del fondo',
    template: Background,
    type: 'collection',
    fields: {
      'screen': screensField,
      ...bgFields
    }
  };
};

export const backgroundClasses = `
  bg-cover 
  bg-center 
  relative 
  absolute 
  inset-0 
  w-full 
  h-full 
  object-cover 
  bg-color 
  from-color 
  to-color 
  z-10
`;


// params={headerBg || { 
//   type: 'video',
//   bgUrl: 'https://cdn.pixabay.com/video/2022/11/11/138588-770315514_large.mp4',
//   bgOverlayColor: '#000',
//   bgOverlayAlpha: 0.4
// }}

// params={{ 
//   type: 'image',
//   bgUrl: 'https://images.unsplash.com/photo-1653832920019-db280240dd41?q=80&w=871&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
//   bgOverlayColor: '#000',
//   bgOverlayAlpha: 0.4
// }}

// params={{ type: 'color', bgColor: '#ccc' }}

// params={{ 
//   type: 'gradient',
//   bgColorFrom: '#211cf4', bgColorTo: '#1ac3ff', bgColorDirection: 'to bottom right'
// }}
export function BackgroundStatic (props) {
  let {
    instance,
    params,
    specDesign = {},
    classDefault,
    children
  } = props;

  let classes = stackClasses(props.classes, params.classes);
  
  if (!params?.type) {
    return (
      <div className={`${classes.bgContainer} ${classes.bgChildren || ''} ${classDefault || ''}`}>
        {children}
      </div>
    );
  }

  let styles = {
    bgContainer: props.styles?.bgContainer || {},
    bgChildren: props.styles?.bgChildren || {}
  };
  let backgroundClasses = '';
  let overlayStyles = {};

  let colorsMap = props.colors || specDesign.colors || config?.colors || {};
  
  const getColorHex = (val) => {
    return val?.hex || colorsMap[val] || val;
  };

  if (params.bgOverlayColor && params.bgOverlayAlpha) {
    overlayStyles.backgroundColor = getColorHex(params.bgOverlayColor);
    overlayStyles.opacity = params.bgOverlayAlpha / 100;
  }
  
  //////////////////////////////
  // Render by type
  //////////////////////////////

  if (params.type === 'image') {
    let bgUrl = _.isArray(params.bgUrl) ? params.bgUrl[0] : params.bgUrl;
    bgUrl = (_.startsWith(bgUrl, 'http') || params.isStatic) ? bgUrl : getImageURL(bgUrl, 'xl', null, instance);
    styles.bgContainer.backgroundImage = `url(${bgUrl})`;
    backgroundClasses += ' bg-cover bg-center';

    return (
      <div className={`relative ${backgroundClasses} ${classes.bgContainer}`} style={styles.bgContainer}>
        {_.size(overlayStyles) ? (
          <div className={`absolute inset-0 ${classes.bgChildren || ''}`} style={overlayStyles}></div>
        ) : null}
        <div className={`relative z-10 h-full ${classes.bgChildren || ''}`} style={styles.bgChildren}>{children || null}</div>
      </div>
    );
  }
  
  else if (params.type === 'video') {
    let bgUrl = _.isArray(params.bgUrl) ? params.bgUrl[0] : params.bgUrl;

    return (
      <div className={`relative ${classes.bgContainer}`} style={styles.bgContainer}>
        <video autoPlay loop muted className="absolute inset-0 w-full h-full object-cover">
          <source src={bgUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {_.size(overlayStyles) ? (
          <div className="absolute inset-0" style={{overlayStyles}}></div>
        ) : null}
        <div className={`relative z-10 h-full ${classes.bgChildren || ''}`} style={styles.bgChildren}>{children || null}</div>
      </div>
    );
  }
  
  else if (params.type === 'color') {
    styles.bgContainer.backgroundColor = getColorHex(params.bgColor);
  }
  
  else if (params.type === 'gradient') {
    const gradientDirection = params.bgColorDirection || 'to bottom right';

    styles.bgContainer.backgroundImage = `linear-gradient(${gradientDirection}, ${getColorHex(params.bgColorFrom || '#ffffff')}, ${getColorHex(params.bgColorTo || '#ffffff')})`;
  }

  return (
    <div className={`${backgroundClasses || ''} ${classes.bgContainer || ''} ${classes.bgChildren || ''}`} style={{ ...styles.bgContainer, ...styles.bgChildren }}>
      {children}
    </div>
  );
}

export function Background (props) {
  let {
    instance,
    params,
    specDesign = {},
    classDefault,
    children
  } = props;
  const { brkPnt } = useBreakpoints();
  const { stylesByScreen } = useMemo(() => params && parseScreensStyles(params), [params]) || {};
  let paramsToUse = stylesByScreen?.[brkPnt] || {};
  
  return (
    <BackgroundStatic {...props} params={paramsToUse} />
  );
}
  
