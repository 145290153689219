import BlockStack from "../../modules/blockStack/BlockStack";
import { useDesign } from "../../modules/blockStack/DesignLoader";
import { LayoutHome } from "../../modules/layoutHome/LayoutHome";


export function RouteLayoutBlock (props) {
  return (
    <LayoutHome specDesign={props.specDesign}>
      <BlockStack specStack={props.specStack} {...props} />
    </LayoutHome>
  );
}

export function PageStack (props) {
  let { instance, pageDoc, specStack } = props;
  const { applyDesign } = useDesign();
  const { specDesign } = applyDesign(instance || 'main');

  let stackToUse = specStack;
  if (pageDoc?.data.withBrandLayout) {
    stackToUse = [
      ...(specDesign.blocks.header || []),
      ...(specStack || []),
      ...(specDesign.blocks.footer || [])
    ]
  }

  return (
    <LayoutHome {...props} specDesign={specDesign} scrollEffect={pageDoc?.data.scrollEffect}>
      <BlockStack {...props} specDesign={specDesign} specStack={stackToUse} />
    </LayoutHome>
  );
}


export function routePageStack ({ pageDoc, specDesign, specStack }) {
  
  let stackToUse = specStack;
  if (pageDoc?.data.withBrandLayout) {
    stackToUse = [
      ...(specDesign.blocks.header || []),
      ...(specStack || []),
      ...(specDesign.blocks.footer || [])
    ]
  }

  return function RouteLayoutBlock (props) {
    return (
      <LayoutHome {...props} specDesign={specDesign} scrollEffect={pageDoc?.data.scrollEffect}>
        <BlockStack {...props} specDesign={specDesign} specStack={stackToUse} />
      </LayoutHome>
    );
  }
}