import _ from 'lodash';
import { useAsyncMemo } from "use-async-memo";
import Model from "../../libs/ModelClass";
import { usePanel } from '../panel/usePanel';
import { IonButton, IonLabel } from '@ionic/react';


export const useCanAddMoreDocs = (entitySlug, moduleSettings, instanceDoc, refresh) => {  
  const { contractModelMetaData } = usePanel();

  const defaultValue = {
    validRecordsCount: 0,
    limitMaxRecords: 0,
    canAddMoreDocs: true,
    contractModelMetaData
  };

  const canAddMoreDocs = useAsyncMemo(async () => {
    if (!entitySlug || !instanceDoc) {
      return defaultValue;
    }
    const { serviceLimits } = contractModelMetaData;
    if (!serviceLimits) {
      return defaultValue;
    }
    const maxCountForEntity = serviceLimits[ moduleSettings.fieldForCountLimit ];
    if (!_.isNumber(maxCountForEntity)) {
      return defaultValue;
    }
    const ExtendedModel = Model.extend(entitySlug);
    let queryToCountForLimits = moduleSettings.queryToCountForLimits || { deleted: 'false' };
    let limitMaxRecords = _.toNumber(maxCountForEntity);
    let validRecordsCount = await ExtendedModel.filterByAttributesCount(queryToCountForLimits);
    let canAddMoreDocs = limitMaxRecords > 0 && validRecordsCount < limitMaxRecords;
    return {
      validRecordsCount,
      limitMaxRecords,
      canAddMoreDocs,
      contractModelMetaData
    };
  }, [entitySlug, refresh, contractModelMetaData]);
  
  if (!entitySlug) { 
    return defaultValue;
  }

  return canAddMoreDocs;
};

export const MaxCountLimitAlert = ({ canAddMoreDocs, actionToDo, showCounters = true }) => {
  const { attachPrefix } = usePanel();

  if (!canAddMoreDocs) return null;

  const { canAddMoreDocs: canAdd, validRecordsCount, limitMaxRecords } = canAddMoreDocs;

  return (
    <>
      {!canAdd && (
        <IonButton
          routerLink={attachPrefix(`/a/instancesBilling/update/#/openSelector/true`)}
          size="small"
          fill="outline"
          color="primary"
        >
          Aumentar Plan
        </IonButton>
      )}

      {showCounters ? (<>
        {canAdd && (
          <IonLabel color="medium" className="ml-2 text-xs md:text-sm">
            {validRecordsCount === 0 
              ? `${limitMaxRecords} restantes` 
              : `${limitMaxRecords - validRecordsCount} de ${limitMaxRecords} restantes`}
          </IonLabel>
        )}

        {!canAdd && validRecordsCount > limitMaxRecords && (
          <IonLabel color="primary" className="ml-2 text-xs md:text-sm">
            {validRecordsCount - limitMaxRecords} a {actionToDo}
          </IonLabel>
        )}

        {!canAdd && limitMaxRecords === validRecordsCount && (
          <IonLabel color="primary" className="ml-2 text-xs md:text-sm">
            Cantidad máxima alcanzada
          </IonLabel>
        )}
      </>) : null}
    </>
  );
};