import _ from 'lodash';
import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import Model from "../../libs/ModelClass";
import { getConfig } from "../../config";
import { parseAttrValParams } from '../../libs/utils';
import useBreakpoints from '../../libs/useBreakpoints';
import { paramsMapLayoutBlocks, stringsMapLayoutBlocks } from "../specSite";
import { animateScroll as scroll } from 'react-scroll';
import { usePartOfModule } from "../../components/Module/PartOfModule";
import { Page404 } from './ErrorPages';
import { useModule } from "../../libs/ModuleContext";
import { PageHeaderHeroStatic } from '../../modules/blockStack/blocks/BlockPageHeaderAHero';
import { PageFooterALinksFixedCta } from '../../modules/blockStack/blocks/BlockPageFooterALinksFixedCta';
import { LayoutHome } from "../../modules/layoutHome/LayoutHome";
import { useHashParams } from '../../libs/useHashParams';
import { DesignLoader } from '../../modules/blockStack/DesignLoader';


export function RoutePublicModulePage(props) {
  let { history, match, location } = props;
  const config = getConfig();
  const { isAllowed } = useModule();
  const { isMinBreakpointActive } = useBreakpoints();
  const hashParams = useHashParams();
  const { instance, entitySlug, action, '*': attrValParams } = useParams();

  const parsedParams = useMemo(() => {
    return {
      ...parseAttrValParams( attrValParams ),
      ...parseAttrValParams( location.hash.replace('#/', '') )
    };
  }, [location.hash]);

  const routeOfModule = usePartOfModule({
    type: 'routePublic',
    entitySlug,
    action
  });

  const params = { 
    instance,
    entitySlug, 
    action,
    parsedParams: { ...parsedParams, ...hashParams },
    config,
    isAllowed, 
    Model,
    isMinBreakpointActive, 
    location, 
    history, 
    match
   };

  const HeaderMenu = () => (<>
    <Link
      to="/"
      title={config.siteName}
      className="ml-1 py-2 px-2 mr-2 md:px-6 text-gray-200 hover:text-gray-400 md:text-lg lg:text-xl text-sm cursor-pointer"
      onClick={() => scroll.scrollToTop()}
    >
      Inicio
    </Link>
  </>);

  if (_.isFunction(routeOfModule)) {
    return (
      routeOfModule({ ...props, ...params })
    );
  }

  if (routeOfModule?.Render) {
    return (
      <routeOfModule.Render {...props} {...params} />
    );
  }

  if (!routeOfModule?.Content) {
    return (
      <DesignLoader
        {...props}
        {...params}
        render={Page404}
      />
    );
  }

  return (
    <DesignLoader {...props} render={(props) => (
      <LayoutHome {...props} mainSectionClass="px-0" HeaderMenu={HeaderMenu}>
        {/* <MetaTags>
          <title>{getPageTitle(config.projectTitle)}</title>
          <meta name={getPageTitle()} content={config.projectTitle} />
        </MetaTags> */}

        <PageHeaderHeroStatic
          data={stringsMapLayoutBlocks.header} 
          history={history}
          {...paramsMapLayoutBlocks.header}
        />

        <routeOfModule.Content {...props} {...params} />

        <PageFooterALinksFixedCta
          data={stringsMapLayoutBlocks.footer}
          {...paramsMapLayoutBlocks.footer}
          history={history}
        />
      </LayoutHome>
    )} />
  );
}
