import _ from 'lodash';
import { LayoutAdmin } from "../panel/LayoutAdmin";
import SectionCrudModel from "../../components/SectionCrudModel";
import Entity, { getEntitiesBlueprint, getEntityBlueprintBySlug, saveEntityFromBlueprint } from './Entity';
import { downloadJSON } from '../../libs/utils';
import Model from '../../libs/ModelClass';
import { IonButton, IonIcon, IonItem, IonPopover } from '@ionic/react';
import { useEffect, useMemo, useState } from 'react';
import ModalAlert from '../../components/ui/ModalAlert';
import toast from 'react-hot-toast';
import { InstanceInlineSelectorForMain } from '../instance/InstanceSelector';
import { usePanel } from '../panel/usePanel';
import {
  downloadOutline,
  ellipsisVerticalOutline,
  constructOutline,
  documentsOutline,
  copyOutline
} from 'ionicons/icons';
import { nanoid } from 'nanoid';


export function Content(props) {
  let {
    selectedInstance,
    attachPrefix
  } = props;
  const [ showExportBlueprintsConfirmation, setExportBlueprintsConfirmation ] = useState(false);
  const [ entitySlugToExport, setEntitySlugToExport ] = useState(null);
  const [ showExportAllRecordsConfirmation, setExportAllRecordsConfirmation ] = useState(false);
  const [ entitySlugToClone, setEntitySlugToClone ] = useState(null);
  const [ entitySlugToBlueprint, setEntitySlugToBlueprint ] = useState(null);

  const cloneEntity = async () => {
    try {
      let entityBlueprintToClone = await getEntityBlueprintBySlug({ entitySlug: entitySlugToClone });
      let newEntityBlueprintCloned = await saveEntityFromBlueprint(entityBlueprintToClone);
      console.log('entityBlueprintToClone', entityBlueprintToClone);
      console.log('newEntityBlueprintCloned', newEntityBlueprintCloned);
      toast.success('Entidad clonada');
      setEntitySlugToClone(null);
    } catch (error) {
      console.log('Error cloning entity:', error);
    }
  };

  const exportBlueprints = async () => {
    try {
      let entitiesBlueprints = await getEntitiesBlueprint();
      downloadJSON(entitiesBlueprints, 'entitiesBlueprints.json');
      setExportBlueprintsConfirmation(false);
    } catch (error) {
      console.log('Error exporting entities blueprints:', error);
    }
  };

  const exportBlueprintBySlug = async () => {
    try {
      console.log('entitySlugToBlueprint', entitySlugToBlueprint)
      let entitiesBlueprints = await getEntityBlueprintBySlug({ entitySlug: entitySlugToBlueprint });
      downloadJSON(entitiesBlueprints, `entitiy${_.capitalize(entitySlugToBlueprint)}Blueprint.json`);
      setExportBlueprintsConfirmation(false);
    } catch (error) {
      console.log('Error exporting entity blueprint:', error);
    }
  };

  const exportAllRecordsOfEntitySlug = async (entitySlug) => {
    try {
      let entitiesDocs = await Model.extend(entitySlug || entitySlugToExport).getAll();
      entitiesDocs = entitiesDocs.map(({ data }) => ({
        ...data
      }));
      downloadJSON(entitiesDocs, 'entitiesDocs.json');
      setEntitySlugToExport(null);
    } catch (error) {
      console.log('Error exporting entity:', error);
    }
  };

  const exportAllRecords = async () => {
    try {
      let recordsOfEntities = {};
      let entitiesDocs = await Model.extend('entities').getAll();
      let entitiesSlugs = entitiesDocs.map(doc => doc.data.nameSlug);
      entitiesSlugs = [ ...entitiesSlugs, 'entities', 'taxonomyTypes', 'filterMenu' ];
      for (const nameSlug of entitiesSlugs) {
        let allDocsOfEntity = await Model.extend(nameSlug).getAll();
        allDocsOfEntity = allDocsOfEntity.map(doc => doc.data);
        recordsOfEntities[nameSlug] = [ ...allDocsOfEntity ];
      }
      downloadJSON(recordsOfEntities, 'recordsOfEntities.json');
      setExportAllRecordsConfirmation(false);
    } catch (error) {
      console.log('Error exporting entity:', error);
    }
  };

  const softFilter = (docs) => {
    let docsFiltered = docs;
    if (selectedInstance) {
      docsFiltered = [
        ...docs.filter((doc) => doc.data.nameSlug?.startsWith(selectedInstance.data.hash)),
        ...docs.filter((doc) => !_.includes(doc.data.nameSlug, '.'))
      ];
    }
    return docsFiltered;
  };
  
  const ListItem = ({ doc }) => (
    <div>
      <div className="">{doc?.data?.name}</div>
      <div className="mb-2 text-sm text-gray-500">{doc.data?.nameSlug}</div>
    </div>
  );

  const ExtraActions = () => {
    const elementId = useMemo(() => nanoid(), []);
    return (<>
      <IonButton
        id={elementId}
        color={"light"}
        fill={"solid"}
        size="small"
      >
        <IonIcon icon={downloadOutline} color={"medium"} size="small"></IonIcon>
      </IonButton>
      <IonPopover
        trigger={elementId}
        side="bottom"
        alignment="end"
      >
        <IonItem button onClick={() => setExportBlueprintsConfirmation(true)} color="light" size="small">
          <IonIcon slot="start" icon={constructOutline} color={"medium"} size="small"></IonIcon>
          Exportar Blueprints
        </IonItem>
        <IonItem button onClick={() => setExportAllRecordsConfirmation(true)} color="light" size="small">
          <IonIcon slot="start" icon={documentsOutline} color={"medium"} size="small"></IonIcon>
          Exportar todos los registros
        </IonItem>
      </IonPopover>
    </>);
  };

  const ItemExtraActions = ({ doc, isAllowed }) => {
    const elementId = useMemo(() => nanoid(), []);

    return (<>
      <IonButton
        id={elementId}
        color={"light"}
        fill={"clear"}
        size="small"
        className="!absolute right-0 bottom-0"
      >
        <IonIcon icon={ellipsisVerticalOutline} color={"medium"} size="small"></IonIcon>
      </IonButton>
      <IonPopover
        trigger={elementId}
        side="bottom"
        alignment="end"
      >
        {(isAllowed('entities', ['export'])) ? (
          <IonItem button color="light" size="small" onClick={() => setEntitySlugToBlueprint(doc.data.nameSlug)}>
            <IonIcon slot="start" icon={constructOutline} color={"medium"} size="small"></IonIcon>
            Exportar blueprint
          </IonItem>
        ) : null}
        {(isAllowed('entities', ['export'])) ? (
          <IonItem button color="light" size="small" onClick={() => setEntitySlugToExport(doc.data.nameSlug)}>
            <IonIcon slot="start" icon={documentsOutline} color={"medium"} size="small"></IonIcon>
            Exportar datos
          </IonItem>
        ) : null}
        {(isAllowed('entities', ['clone'])) ? (
          <IonItem button color="light" size="small" onClick={() => setEntitySlugToClone(doc.data.nameSlug)}>
            <IonIcon slot="start" icon={copyOutline} color={"medium"} size="small"></IonIcon>
            Clonar entidad
          </IonItem>
        ) : null}
      </IonPopover>
    </>);
  };

  return (
    <div className="">
      <SectionCrudModel
        model={Entity}
        entitySlug="entities"
        editStyle="route"
        dataMode="all"
        listStyle="dragable"
        navigateTo={(viewType, doc) => (viewType === 'form' && attachPrefix(`/a/entityCreator/${doc ? doc.id : 'new'}/form`))}
        refresh={selectedInstance}
        // callbacks 
        softFilter={softFilter}
        // add UI
        ExtraActions={ExtraActions}
        ListItem={ListItem}
        ItemExtraActions={ItemExtraActions}
        showBtnMove={true}
      />
      {/* modal for exports */}
      {entitySlugToClone && (
        <ModalAlert
          text={`¿Estás seguro de que deseas CLONAR la entidad ${entitySlugToClone}?`}
          onConfirm={cloneEntity}
          onCancel={() => setEntitySlugToClone(null)}
          confirmClass="bg-brand-primary text-white"
        />
      )}
      {entitySlugToExport && (
        <ModalAlert
          text={`¿Estás seguro de que deseas exportar todos los datos de la entidad ${entitySlugToExport}?`}
          onConfirm={exportAllRecordsOfEntitySlug}
          onCancel={() => setEntitySlugToExport(null)}
          confirmClass="bg-brand-primary text-white"
        />
      )}
      {entitySlugToBlueprint && (
        <ModalAlert
          text={`¿Estás seguro de que deseas el Blueprint de la entidad ${entitySlugToBlueprint}?`}
          onConfirm={exportBlueprintBySlug}
          onCancel={() => setEntitySlugToBlueprint(null)}
          confirmClass="bg-brand-primary text-white"
        />
      )}
      {showExportBlueprintsConfirmation && (
        <ModalAlert
          text="¿Estás seguro de que deseas exportar los Blueprints de todas las entidades?"
          onConfirm={exportBlueprints}
          onCancel={() => setExportBlueprintsConfirmation(false)}
          confirmClass="bg-brand-primary text-white"
        />
      )}
      {showExportAllRecordsConfirmation && (
        <ModalAlert
          text="¿Estás seguro de que deseas exportar todos los datos de todas la entidades?"
          onConfirm={exportAllRecords}
          onCancel={() => setExportAllRecordsConfirmation(false)}
          confirmClass="bg-brand-primary text-white"
        />
      )}
    </div>
  );
}

export function RouteEntityCreator(props) {
  const { selectedInstance, attachPrefix } = usePanel();
  const [ selectedInner, setSelectedInner ] = useState(selectedInstance);

  useEffect(() =>{
    setSelectedInner(selectedInstance);
  }, [selectedInstance]);

  return (
    <LayoutAdmin 
      history={props.history}
      breadcrumbs={[{
        title: "Entidades"
      }]}
      TitleToolbarRight={() => (
        <InstanceInlineSelectorForMain {...props} selectedInstance={selectedInner} setSelectedInstance={setSelectedInner} design="labelInsideBtn" />
      )}
    >
      <Content {...props} selectedInstance={selectedInner} attachPrefix={attachPrefix} />
    </LayoutAdmin>
  );
}
