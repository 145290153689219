import _ from 'lodash';
import { IonAlert, IonButton, IonContent, IonFab, IonFabButton, IonFabList, IonIcon, IonLabel, IonPage } from "@ionic/react";
import Model, { useStateSingleResult } from "../../libs/ModelClass";
import { useModuleSettings } from "../entity/useModuleSettings";
import { useEffect, useMemo, useState } from "react";
import useEntityTaxonomyForm from "../../components/EntityTaxonomyForm";
import { useReadDocById } from "../entity/useReadDocById";
import { urlPrefix, withOutPrefix, withPrefix } from "../instance/utilsInstance";
import { usePanel } from "../panel/usePanel";
import BadgeLoading, { LoadingIcon } from "../../components/ui/BadgeLoading";
import { useDesign } from "./DesignLoader";
import BlockStack, { blocksDefinitions, blocksMap } from "./BlockStack";
import { LayoutHome } from "../layoutHome/LayoutHome";
import toast from 'react-hot-toast';
import ObjectForm, { objectFormToTaxonomyTypesDocList } from '../entity/ObjectForm';
import SectionCrudForm from '../../components/SectionCrudForm';
import { prefixTaxonomyTypesAsCollection } from '../../components/Form/RawInputCollectionForm';
import {
  imageOutline,
  createOutline,
  documentsOutline,
  addCircleOutline,
  chevronUpOutline,
  chevronDownOutline,
  arrowBackCircleOutline,
  eyeOutline,
  trashOutline,
  readerOutline,
  saveOutline,
  sparklesOutline,
  textOutline,
  copyOutline
} from "ionicons/icons";
import { GrTextAlignLeft } from "react-icons/gr";
import { PiCards } from "react-icons/pi";
import { RiImageCircleFill } from "react-icons/ri";
import { CompareValuesWithConflicts } from "object-deep-compare";
import { BlockTypeSelector, getBlockPresetsByType } from './PageBlockStackCRUD';
import { nanoid } from 'nanoid';
import useBreakpoints from '../../libs/useBreakpoints';
import PagesTemplatesHandler from '../pagesTemplates/PagesTemplatesHandler';


const formButtonMap = {
  templates: { ion: documentsOutline, label: 'Templates' },
  strings: { fa: GrTextAlignLeft, label: 'Textos' },
  styles: { ion: sparklesOutline, label: 'Estilos' },
  text: { ion: textOutline, label: 'Formato de Texto' },
  card: { fa: PiCards, label: 'Tarjeta' },
  background: { ion: imageOutline, label: 'Fondo' },
  logo: { fa: RiImageCircleFill, label: 'Logo' }
};

const Icon = ({ name, size, fill, color }) => {
  const iconDef = formButtonMap[name];
  if (iconDef?.ion) {
    return (
      <IonIcon icon={iconDef.ion} size={size} fill={fill} color={color} />
    );
  }
  if (iconDef?.fa) {
    const IconElement = iconDef.fa;
    return (
      <IconElement className={`w-5 h-5 ${color ? `text-${color}` : ''}`} />
    );
  }
  return (
    <IonIcon icon={iconDef} size={size} fill={fill} color={color} />
  );
};

const Label = ({ name }) => {
  return (
    <span className="ml-1.5 !text-[9pt]">{formButtonMap[name]?.label}</span>
  );
};

const WrapperItem = (props) => { 
  let {
    children,
    specStack,
    blockDef,
    blockRef,
    showBlockForm,
    onFirstRender,
    onMoveBlockUp,
    onMoveBlockDown,
    onDeleteBlock,
    onCloneBlock,
    onToggleBlockVisibility,
    index
  } = props;
  let blockIndex = index;
  const blockSpec = specStack[blockIndex];
  let [ groupOpen, setGroupOpen ] = useState();
  let [ isOpen, setIsOpen ] = useState(false);
  let isLayoutBlock = blockSpec?.isLayoutBlock;
  let isFirstVisible = index === 0 || !specStack[index - 1]?.isBlockShow;

  useEffect(() => {
    onFirstRender && onFirstRender({ blockSpec, blockDef, blockRef, blockIndex });
  }, []);

  return (
    <div ref={blockRef} className="relative">
      {children}
      {/* actions */}
      <IonFab className={`left-2 ${isFirstVisible ? 'top-14' : 'top-2'} ${isOpen ? 'z-50' : 'z-10'}`}>
        <IonFabButton
          size="small"
          color={isLayoutBlock ? 'medium' : 'light'}
          fill="clear"
          onClick={() => setIsOpen(!isOpen)}
        >
          <IonIcon size="small" icon={createOutline}></IonIcon>
        </IonFabButton>
        {/* block config */}
        <IonFabList side="end" className="mt-2.5 left-2">
          {/* move up */}
          <IonButton onClick={() => onMoveBlockUp({ blockSpec, blockDef, blockRef, blockIndex })} shape="round" size="small" color="medium" className="self-start">
            <IonIcon slot="icon-only" icon={chevronUpOutline} size="small" />
          </IonButton>
          {/* move down */}
          <IonButton onClick={() => onMoveBlockDown({ blockSpec, blockDef, blockRef, blockIndex })} shape="round" size="small" color="medium" className="self-start">
            <IonIcon slot="icon-only" icon={chevronDownOutline} size="small" />
          </IonButton>
          {/* clone */}
          <IonButton onClick={() => onCloneBlock({ blockSpec, blockDef, blockRef, blockIndex })} shape="round" size="small" color="medium" className="self-start">
            <IonIcon slot="icon-only" icon={copyOutline} size="small" />
          </IonButton>


          {/* text */}
          <IonButton onClick={() => showBlockForm(blockIndex, 'allStrings')} size="small" color="light" className="ml-4 self-start">
            <Icon name={"strings"} />
            <span className="ml-1.5 text-xs">Textos</span>
          </IonButton>
          {/* styles */}
          <IonButton onClick={() => showBlockForm(blockIndex, 'allStyles')} size="small" color="light" className="self-start">
            <Icon name={"styles"} />
            <span className="ml-1.5 text-xs">Estilos</span>
          </IonButton>

          {/* delete */}
          <IonButton onClick={() => onDeleteBlock({ blockSpec, blockDef, blockRef, blockIndex })} size="small" color="danger" className="ml-4 self-start">
            <IonIcon icon={trashOutline} size="small" />
          </IonButton>
          
          {/* hide */}
          {/* TODO agregar botón de "mostrar" cuando está *hidde* */}
          {/* <IonButton onClick={() => onToggleBlockVisibility({ blockSpec, blockDef, blockRef, blockIndex })} size="small" color="warning" className="self-start">
            <IonIcon icon={blockSpec.isBlockShow ? eyeOutline : eyeOffOutline} size="small" />
          </IonButton> */}

        </IonFabList>
        {/* parts group config */}
        {blockDef.partsMap?.length ? (
          <IonFabList side="bottom" className="items-start">
            {blockDef.partsMap.map(({ label, ...partsGroups }, partIndex) => {
              return (
                groupOpen === partIndex ? (
                  <div key={partIndex} className="flex flex-row flex-wrap gap-1 my-4 w-[90vw]">
                    <IonButton onClick={() => setGroupOpen(null)} size="small" color="medium" className="self-start">
                      <span className="!text-[9pt]">{label}</span>
                    </IonButton>
                    {_.map(partsGroups, (fieldsToShow, groupKey) => {
                      return (
                        <IonButton onClick={() => showBlockForm(blockIndex, groupKey, fieldsToShow)} size="small" color="light" className="self-start">
                          <Icon name={groupKey} />
                          <Label name={groupKey} />
                        </IonButton>
                      );
                    })}
                  </div>
                ) : (
                  <IonButton key={partIndex} onClick={() => setGroupOpen(partIndex)} size="small" color="light" className="self-start">
                    <span className="!text-[9pt]">{label}</span>
                  </IonButton>
                )
              );
            })}
          </IonFabList>
        ) : null}
      </IonFab>
    </div>
  );
};

export const PageCreator = (props) => {
  let {
    instance,
    instanceDoc,
    entitySlug,
    docId,
    entityDoc,
    history,
    match,
    defaultValue,
    onFinish,
    asModal,
    classes = {},
    parsedParams
  } = props;
  const isEdit = docId !== 'new';
  const { moduleSettings } = useModuleSettings({ entitySlug });
  const { attachPrefix, setMenuMainShowType } = usePanel();
  const [ fieldsRequired, setFieldsRequired ] = useState([]);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ showCloseConfirm, setShowCloseConfirm ] = useState(false);
  const [ showGoConfirm, setShowToGoConfirm ] = useState(false);
  const [ hasErrors, setHasErrors ] = useState(false);
  const [ formValues, setFormValues ] = useState();
  const [ isShowForm, setShowForm ] = useState(false);
  const [ isLoadingPart, setIsLoadingPart ] = useState();
  const [ blockIndexToShow, setBlockIndexToShow ] = useState();
  const [ fieldsToShow, setFieldsToShow ] = useState();
  const [ isOpenActionsMenu, setIsOpenActionsMenu ] = useState(false);
  const [ scrollToBlockId, setScrollToBlockId ] = useState();
  const [ templateToApply, setTemplateToApply ] = useState();
  const { taxonomyTypesDocList } = useEntityTaxonomyForm({ 
    instance,
    entityId: entityDoc?.id,
    entitySlug,
    fieldsRequired, 
    setFieldsRequired
  });
  const { applyDesign } = useDesign();
  const { specDesign, brandDocs } = applyDesign(instance || 'main');
  // const canAddMoreDocs = useCanAddMoreDocs(entitySlug, moduleSettings, selectedInstance); // TODO use
  const { doc } = useReadDocById({ docId, defaultValue, instance, instanceDoc, entitySlug, entityDoc, taxonomyTypesDocList });
  let { brkPnt } = useBreakpoints();

  // assign doc.data to setFormValues
  useEffect(() => {
    if (doc?.data && !_.size(formValues) && specDesign?.blocks) {
      let stack = addLayoutBlocksToStack(doc.data.stack || []);
      let valuesToSet = assignBlockVisibility({
        ...doc.data,
        stack
      });
      setFormValues(valuesToSet);
    }
  }, [doc, specDesign]);
  
  // force using menuShowType = 'reveal'
  useEffect(() => {
    setMenuMainShowType('hidden');
    return () => {
      setMenuMainShowType('auto');
    }
  }, []);

  const propsToUse = useMemo(() => ({
    instance, history, match, parsedParams
  }), []);

  function addLayoutBlocksToStack(stack) {
    stack = [
      ...(markAsLayoutBlock(specDesign?.blocks.header, 'header')),
      ...(stack || []),
      ...(markAsLayoutBlock(specDesign?.blocks.footer, 'footer'))
    ];
    return stack;
  }

  function markAsLayoutBlock (blocks, slot) {
    if (!blocks) {
      return [];
    }
    return blocks.map((block) => ({
      ...block,
      isLayoutBlock: true,
      slot
    }));
  }

  const scrollBlockIntoView = (blockRef) => {
    setTimeout(() => {
      blockRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }, 300);
  };

  // si es blockId === scrollToBlockId, hacer scroll
  const onFirstRenderBlock = ({ blockSpec, blockDef, blockRef, blockIndex }) => {
    if (blockSpec.id === scrollToBlockId) {
      scrollBlockIntoView(blockRef);
    }
  }

  const onMoveBlockUp = ({ blockSpec, blockDef, blockRef, blockIndex }) => {
    if (blockIndex > 0) {
      let newStack = [
        ...formValues.stack.slice(0, blockIndex - 1),
        formValues.stack[blockIndex],
        formValues.stack[blockIndex - 1],
        ...formValues.stack.slice(blockIndex + 1)
      ];
      setFormValues({
        ...formValues,
        stack: newStack
      });
      // scrollBlockIntoView(blockRef);
    }
  };

  const onMoveBlockDown = ({ blockSpec, blockDef, blockRef, blockIndex }) => {
    if (blockIndex < formValues.stack.length - 1) {
      let newStack = [
        ...formValues.stack.slice(0, blockIndex),
        formValues.stack[blockIndex + 1],
        formValues.stack[blockIndex],
        ...formValues.stack.slice(blockIndex + 2)
      ];
      setFormValues({
        ...formValues,
        stack: newStack
      });
      scrollBlockIntoView(blockRef);
    }
  };

  const onDeleteBlock = ({ blockSpec, blockDef, blockRef, blockIndex }) => {
    let newStack = [
      ...formValues.stack.slice(0, blockIndex),
      ...formValues.stack.slice(blockIndex + 1)
    ];
    setFormValues({
      ...formValues,
      stack: newStack
    });
  };

  const onCloneBlock = ({ blockSpec, blockDef, blockRef, blockIndex }) => {
    let newStack = [
      ...formValues.stack.slice(0, blockIndex),
      {
        ...blockSpec,
        id: nanoid()
      },
      ...formValues.stack.slice(blockIndex)
    ];
    setFormValues({
      ...formValues,
      stack: newStack
    });
    scrollBlockIntoView(blockRef);
  };

  const onToggleBlockVisibility = ({ blockSpec, blockDef, blockRef, blockIndex }) => {
    let newStack = formValues.stack.map((block, index) => {
      if (index === blockIndex) {
        return {
          ...block,
          isBlockShow: !block.isBlockShow
        };
      }
      return block;
    });
    setFormValues({
      ...formValues,
      stack: newStack
    });
  };

  const handleSubmitForm = async (values) => { /* nothing */ };

  const checkValidateForm = (values, errors = {}) => {
    // if (!values?.profileImg) { errors.profileImg = 'Debe seleccionar una imagen'; }
    return errors;
  };

  const saveData = async () => {
    if (
      _.size(checkValidateForm(formValues))
    ) {
      toast.error('Debe completar los datos');
      return;
    }
    try {
      setIsSaving(true);
      await updateBrandLayoutDoc(formValues);
      let valuesToSet = removeLayoutBlocks(formValues);
      doc.data = {
        ..._.omit(doc.data, ['template']),
        ...valuesToSet
      };
      await doc.save();
      toast.success('Datos actualizados');
      onFinish && await onFinish({ doc });
      setIsSaving(false);
      setShowCloseConfirm(false);
    } catch (error) {
      console.error(error);
      toast.error('Error al guardar');
      setIsSaving(false);
      setShowCloseConfirm(false);      
    }
  };

  // comparar con los bloques specDesign y detectar si hay cambios (usa objectDeepCompare) contempla los slots
  // si hay cambios, update brand layout doc
  async function updateBrandLayoutDoc(values) {
    let valuesToSet = { ...values };
    if (valuesToSet.stack) {
      let specStack = specDesign?.blocks;
      let isChanged = false;

      // deep compare slots
      let newHeaderBlocks = valuesToSet.stack.filter((block) => block.slot === 'header');
      let newFooterBlocks = valuesToSet.stack.filter((block) => block.slot === 'footer');
      
      // header
      if (newHeaderBlocks.length !== specStack.header.length) {
        isChanged = true;
      } else {
        // iterar y comparar
        newHeaderBlocks.forEach((block, index) => {
          // determina si cambiaron los datos además de los campos agregados ['isLayoutBlock', 'slot', 'isBlockShow']
          if (CompareValuesWithConflicts(block, specStack.header[index]).length > 3) {
            isChanged = true;
          }
        });
      }
      
      // footer
      if (newFooterBlocks.length !== specStack.footer.length) {
        isChanged = true;
      } else {
        // iterar y comparar
        newFooterBlocks.forEach((block, index) => {
          // determina si cambiaron los datos además de los campos agregados ['isLayoutBlock', 'slot', 'isBlockShow']
          if (CompareValuesWithConflicts(block, specStack.footer[index]).length > 3) {
            isChanged = true;
          }
        });
      }

      // update brand layout doc
      if (isChanged) {
        // clean data
        let newHeaderBlocksClean = newHeaderBlocks.map((block) => _.omit(block, ['isLayoutBlock', 'slot', 'isBlockShow']));
        let newFooterBlocksClean = newFooterBlocks.map((block) => _.omit(block, ['isLayoutBlock', 'slot', 'isBlockShow']));
        // update brand layout doc
        brandDocs.layoutBlocks.data = {
          ...brandDocs.layoutBlocks.data,
          blocksHeader: newHeaderBlocksClean,
          blocksFooter: newFooterBlocksClean
        };
        await brandDocs.layoutBlocks.save();
        toast.success('Se actualizaron los bloques del layout principal');
        await applyDesign(instance || 'main', true);
      }
    }
  }

  function removeLayoutBlocks (values) {
    let valuesToSet = { ...values };
    if (valuesToSet.stack) {
      valuesToSet.stack = valuesToSet.stack.filter((block) => {
        return !block.isLayoutBlock;
      });
    }
    return valuesToSet;
  }

  const validateForm = (values) => {
    const errors = {};
    // all required fields
    fieldsRequired?.forEach(field => {
      if (!values[field]) {
        errors[field] = ' ';
      }
    });
    checkValidateForm(values, errors);
    setHasErrors(Object.keys(errors).length > 0);
    return errors;
  };

  const FormInputFields = (formProps) => {
    let taxonomyTypes, blockDef, blockParamsTaxonomyTypes, blockStringsTaxonomyTypes;
    let stackData = formProps.values.stack;
    let showPartsForm = true;
    
    if (isLoadingPart === 'pageData') {
      taxonomyTypes = taxonomyTypesDocList;
    }
    else if (isLoadingPart === 'allStrings' && blockIndexToShow > -1) {
      let { type, variant } = stackData[blockIndexToShow];
      blockDef = blocksMap[type][variant];
      taxonomyTypes = objectFormToTaxonomyTypesDocList(blocksMap[type][variant]?.strings, { instance });
      taxonomyTypes = prefixTaxonomyTypesAsCollection(taxonomyTypes, 'stack', blockIndexToShow);
    }
    else if (isLoadingPart === 'allStyles' && blockIndexToShow > -1) {
      let { type, variant } = stackData[blockIndexToShow];
      blockDef = blocksMap[type][variant];
      // add preset field
      let presetsList = getBlockPresetsByType(type, variant);
      let fieldsToSet = {};
      if (presetsList) {
        fieldsToSet = {
          'presets': {
            type: 'select',
            label: 'Presets',
            options: presetsList,
            param: {
              onSelect: applyPreset
            }
          },
          ...blocksMap[type][variant]?.params
        };
      }
      else {
        fieldsToSet = { ...blocksMap[type][variant]?.params };
      }
      taxonomyTypes = objectFormToTaxonomyTypesDocList(fieldsToSet, { instance });
      taxonomyTypes = prefixTaxonomyTypesAsCollection(taxonomyTypes, 'stack', blockIndexToShow);
    }
    else if (['strings', 'text', 'card', 'background', 'logo'].includes(isLoadingPart) && fieldsToShow && blockIndexToShow > -1) {
      let { type, variant } = stackData[blockIndexToShow];
      blockDef = blocksMap[type][variant];
      if (blockDef) {
        let fieldsList = {
          ..._.pick(blocksMap[type][variant].strings, fieldsToShow),
          ..._.pick(blocksMap[type][variant].params, fieldsToShow)
        };
        taxonomyTypes = objectFormToTaxonomyTypesDocList(fieldsList, { instance });
        taxonomyTypes = prefixTaxonomyTypesAsCollection(taxonomyTypes, 'stack', blockIndexToShow);
      }
    }
    else if (isLoadingPart === 'templates' && blockIndexToShow > -1) {
      showPartsForm = false;
    }

    function applyPreset (presetSlug) {
      if (!presetSlug) {
        return null;
      }
      let { type, variant } = stackData[blockIndexToShow];
      let blockDef = blocksMap[type][variant];
      const presetDef = blockDef.presets[presetSlug];
      if (presetDef) {
        let newStack = formValues.stack.map((block, index) => {
          if (index === blockIndexToShow) {
            return {
              ...block,
              ...presetDef
            };
          }
          return block;
        });
        setFormValues({ 
          ...formValues,
          stack: newStack
        });
      }
    };

    return (
      <div className="mb-64">
        {/* page form */}
        {isLoadingPart && showPartsForm ? (
          <ObjectForm
            {...propsToUse}
            {...formProps}
            entitySlug={entitySlug}
            taxonomyTypesDocList={taxonomyTypes}
            defaultValue={formValues}
            classes={{
              loadingContainer: "!py-4"
            }}
          />
        ) : null}
        {/* templates form */}
        {isLoadingPart === 'templates' ? (
          <PagesTemplatesHandler
            {...propsToUse}
            {...formProps}
            {...{ templateToApply, setTemplateToApply }}
            values={formValues}
            onClose={() => {
              setIsLoadingPart(null);
              setShowForm(false);
            }}
          />
        ) : null}
      </div>
    );
  };

  const setToClose = () => {
    setShowCloseConfirm(true);
  };

  const onCancelToGoConfirm = () => {
    setShowToGoConfirm(false);
  };

  const handleCloseCreator = () => {
    if (history.length > 2) {
      history.goBack();
    } 
    else {
      history.push(urlPrefix(instance, `/a/entity/${entitySlug}/list/#/`));
    }
  };

  const onCancelCloseConfirm = () => {
    setShowCloseConfirm(false);
  };

  const setToGo = () => {
    setShowToGoConfirm(true);
  };

  const handleToGoVisit = () => {
    history.push(attachPrefix(`/m/p/${doc.data.url}`));
  };

  const onCloseForm = () => {
    setShowForm(false);
    setIsLoadingPart(null);
    setTemplateToApply(null);
  };

  const updateFormValues = async (values) => {
    let valuesToSet;
    if (templateToApply) {
      valuesToSet = assignBlockVisibility({
        ..._.omit(values, ['template']),
        stack: addLayoutBlocksToStack(templateToApply.data.stack),
        withBrandLayout: templateToApply.data.withBrandLayout
      });
      setFormValues(valuesToSet);
      setTemplateToApply(null);
    }
    else {
      valuesToSet = assignBlockVisibility(values);
    }
    setFormValues(valuesToSet);
    setShowForm(false);
    setIsLoadingPart(null);
  };
  
  // si values.withBrandLayout, iterar values.stack y a los isLayoutBlock asignar isBlockShow = false
  function assignBlockVisibility (values) {
    let valuesToSet = { ...values };
    if (valuesToSet.stack) {
      valuesToSet.stack = valuesToSet.stack.map((block, index) => {
        return {
          ...block,
          isBlockShow: block.isLayoutBlock && values.withBrandLayout
        };
      });
    }
    return valuesToSet;
  }

  const onFormChange = (values) => {
    // setFormValues(values);
  };
  
  const openPageForm = () => {
    setShowForm(true);
    setIsLoadingPart('pageData');
    setBlockIndexToShow(-1);
  };

  const openTemplatesModal = () => {
    setShowForm(true);
    setIsLoadingPart('templates');
    setBlockIndexToShow(-1);
  };

  const showBlockForm = (blockIndex, formType, fields) => {
    setShowForm(true);
    setIsLoadingPart(formType);
    setBlockIndexToShow(blockIndex);
    setFieldsToShow(fields || []);
  };

  const getModalName = () => {
    if (isLoadingPart === 'pageData') {
      return 'Datos de página';
    }
    // all strings
    else if (isLoadingPart === 'allStrings' && blockIndexToShow > -1) {
      let { type, variant } = formValues?.stack[blockIndexToShow];
      return `Bloque ${blocksDefinitions[type]?.title} (${blocksMap[type][variant]?.title || variant})`;
    }
    // styles
    else if (isLoadingPart === 'allStyles' && blockIndexToShow > -1) {
      let { type, variant } = formValues?.stack[blockIndexToShow];
      return `Bloque ${blocksDefinitions[type]?.title} (${blocksMap[type][variant]?.title || variant})`;
    }
    // strings
    else if (isLoadingPart === 'strings') { return 'Textos'; }
    // text
    else if (isLoadingPart === 'text') { return 'Formato de Texto'; }
    // card
    else if (isLoadingPart === 'card') { return 'Estilos de Tarjeta'; }
    // background
    else if (isLoadingPart === 'background') { return 'Fondo'; }
    // logo
    else if (isLoadingPart === 'logo') { return 'Logo'; }
    // templates
    else if (isLoadingPart === 'templates') { return 'Templates'; }

    return '';
  };

  const addBlockToStack = async (optionValue) => {
    let variantToAdd = blocksMap[optionValue][_.keys(blocksMap[optionValue])[0]];
    const newBlock = {
      id: nanoid(),
      type: optionValue,
      variant: variantToAdd.variant,
      isBlockShow: true // Asegurar que el bloque sea visible al agregarlo
    };
    // apply base preset
    if (variantToAdd.presets) {
      let presetDef = variantToAdd.presets[_.keys(variantToAdd.presets)[0]]; // base preset
      if (presetDef) {
        _.forEach(presetDef, (paramDef, paramName) => {
          newBlock[paramName] = paramDef;
        });
      }
    }
    // agregar nuevo bloque antes del primer type === 'pageFooter'
    let firstFooter = formValues.stack.findIndex((block) => block.type === 'pageFooter');
    if (firstFooter < 0) {
      firstFooter = formValues.stack.length;
    }
    let newBlockIndex = firstFooter;
    let newStack = [
      ...formValues.stack.slice(0, newBlockIndex),
      newBlock,
      ...formValues.stack.slice(newBlockIndex)
    ];

    setFormValues({
      ...formValues,
      stack: newStack
    });
    showBlockForm(newBlockIndex, 'allStrings');
    setScrollToBlockId(newBlock.id);
  };

  if (!doc || !specDesign || !formValues) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  const stackRendered = (
    <LayoutHome {...propsToUse} specDesign={specDesign} scrollEffect={doc?.data.scrollEffect}>
      <BlockStack
        {...propsToUse}
        specDesign={specDesign}
        specStack={formValues.stack}
        WrapperItem={WrapperItem}
        isLoadingPart={isLoadingPart}
        showBlockForm={showBlockForm}
        onFirstRender={onFirstRenderBlock}
        onMoveBlockUp={onMoveBlockUp}
        onMoveBlockDown={onMoveBlockDown}
        onDeleteBlock={onDeleteBlock}
        onCloneBlock={onCloneBlock}
        onToggleBlockVisibility={onToggleBlockVisibility}
      />
    </LayoutHome>
  );

  return (
    <div className="">
      {/* page render */}
      {stackRendered}
      {/* floating screen viewport */}
      {/* {true ? (
        <div className="flex flex-row content-end w-full h-screen">
          <div className="">
            <IonPage className="p-4 max-w-screen-sm">
              <IonContent>
                {stackRendered}
              </IonContent>
            </IonPage>
          </div>
        </div>
      ) : (
        stackRendered
      )} */}

      {/* floating screen size */}
      <div className={`fixed bottom-20 left-2.5 z-[110] ${isOpenActionsMenu ? 'hidden' : ''}`}>
        <div className="p-1.5 text-md font-bold text-center w-14 bg-brand-dark text-brand-light">
          {brkPnt}
        </div>
      </div>

      {/* actions */}
      <IonFab slot="fixed" vertical="bottom" horizontal="start">
        <IonFabButton color="light" onClick={() => setIsOpenActionsMenu(!isOpenActionsMenu)}>
          <IonIcon icon={createOutline}></IonIcon>
        </IonFabButton>
        <IonFabList side="top">
          {/* visit */}
          <IonButton onClick={setToGo} size="small" color="light" className="self-start mb-2">
            <IonIcon icon={sparklesOutline}></IonIcon>
            <span className="ml-1.5">Visitar</span>
          </IonButton>
          {/* exit */}
          <IonButton onClick={setToClose} size="small" color="light" className="self-start mb-2">
            <IonIcon icon={arrowBackCircleOutline}></IonIcon>
            <span className="ml-1.5">Salir</span>
          </IonButton>
          {/* floating screen size */}
          <div className="self-start mb-2 mt-10">
            <div className="p-1.5 w-14 text-md font-bold text-center bg-brand-dark text-brand-light">
              {brkPnt}
            </div>
          </div>
          {/* template modal */}
          <IonButton onClick={openTemplatesModal} size="small" color="light" className="self-start">
            <Icon name={"templates"} />
            <span className="ml-1.5">Templates</span>
          </IonButton>
          {/* page form */}
          <IonButton onClick={openPageForm} size="small" color="light" className="self-start">
            <IonIcon icon={readerOutline}></IonIcon>
            <span className="ml-1.5">Datos de página</span>
          </IonButton>
          {/* add block */}
          <BlockTypeSelector
            blocksDefinitions={blocksDefinitions}
            assignBlock={addBlockToStack}
            design="button"
            buttonProps={{
              fill: 'solid', color: 'secondary', size: 'small', className: 'self-start', ionIcon: addCircleOutline
            }}
          />
        </IonFabList>
        {/* save */}
        <IonFabList side="end" className="mt-2 left-4">
          <IonButton onClick={saveData} size="small" color="warning" className="self-start">
            {isSaving ? (
              <LoadingIcon className={'h-4 w-4'} />
            ) : (
              <IonIcon icon={saveOutline}></IonIcon>
            )}
            <span className="ml-1.5">Guardar</span>
          </IonButton>
        </IonFabList>
      </IonFab>

      {/* form */}
      {doc && formValues ? (<>
        <SectionCrudForm
          {...propsToUse}
          isModalOpen={isShowForm}
          entitySlug={entitySlug}
          entityDoc={entityDoc}
          doc={formValues}
          onFormChange={onFormChange}
          onValidation={validateForm}
          onSave={updateFormValues}
          onClose={onCloseForm}
          fieldsRequired={fieldsRequired}
          FormInputFields={FormInputFields}
          editStyle={'modal'}
          showTitle={true}
          showToast={false}
          showSaveButton={true}
          saveBtnLabel="Aplicar"
          defaultValue={defaultValue}
          title={getModalName()}
        />
      </>) : null}

      <IonAlert
        isOpen={showCloseConfirm}
        onDidDismiss={onCancelCloseConfirm}
        header="Salir"
        message="Verifique si desea salir sin guardar"
        buttons={[
          {
            text: 'Cerrar',
            role: 'cancel',
            handler: onCancelCloseConfirm,
          },
          {
            text: 'Salir sin guardar',
            role: 'destructive',
            cssClass: 'primary',
            handler: handleCloseCreator,
          },
        ]}
      />

      <IonAlert
        isOpen={showGoConfirm}
        onDidDismiss={onCancelToGoConfirm}
        header="Visitar página"
        message="Verifique si desea salir sin guardar"
        buttons={[
          {
            text: 'Cerrar',
            role: 'cancel',
            handler: onCancelToGoConfirm,
          },
          {
            text: 'Salir sin guardar',
            role: 'destructive',
            cssClass: 'primary',
            handler: handleToGoVisit,
          },
        ]}
      />
    </div>
  );
};

export const RoutePageCreator = (props) => {
  let {
    context,
    parsedParams,
    selectedInstance,
  } = props;
  const instanceDoc = context?.instanceDoc || selectedInstance;
  const instance = props.instance || instanceDoc?.data?.hash || 'main';

  let entitySlug = props.entitySlug || context?.entitySlug || parsedParams.entitySlug;
  entitySlug = withPrefix(instance, withOutPrefix(entitySlug));

  const docId = context?.docId || props.docId || parsedParams.docId;
  const entityDoc = useStateSingleResult({
    Model: Model.extend('entities'), 
    nameSlug: entitySlug
  });
  
  // initial value
  let defaultValue = context?.defaultValue || props.defaultValue;

  return (
    <PageCreator
      {...props}
      instance={instance}
      instanceDoc={instanceDoc}
      entitySlug={entitySlug}
      docId={docId}
      entityDoc={entityDoc}
      defaultValue={defaultValue}
    />
  );
};

export const ListItemExtraActionsPages = (props) => {
  let {
    doc,
    Model,
    isAllowed,
    canAddMoreDocs,
    entitySlug,
    entityDoc,
    location,
    entitySpecs,
    moduleSettings,
    attachPrefix
  } = props;

  return (<>
    {isAllowed(entitySlug, ['read', 'instance:read']) ? (
      <IonButton
        size="small"
        fill="clear"
        color="primary"
        routerLink={attachPrefix(`/m/p/${doc.data.url}`)}
      >
        <IonIcon slot="start" size="small" icon={eyeOutline}></IonIcon>
        Visitar
      </IonButton>
    ) : null}
    {isAllowed(entitySlug, ['create', 'update', 'instance:create', 'instance:update']) ? (
      <IonButton
        size="small"
        fill="clear"
        color="primary"
        routerLink={attachPrefix(`/a/pages/creator/#/docId/${doc.id}`)}
      >
        <IonIcon slot="start" size="small" icon={createOutline}></IonIcon>
        Creador
      </IonButton>
    ) : null}
  </>);
};