import _ from 'lodash';
import { screensSizes } from "../../../libs/useBreakpoints";


export const screensField = { 
  type: 'select',
  name: 'Pantalla',
  descriptionText: 'Tamaño de pantalla mínimo para aplicar los estilos',
  helpText: '*Requerido',
  options: screensSizes,
  design: 'buttons',
  defaultValue: 'xxs'
};

export const cardContainerStylesField = { // Estilos para el contenedor principal
  name: 'Tarjeta del Bloque',
  descriptionText: 'Contendedor principal. Estilos según tamaño de pantalla',
  type: 'part',
  part: 'cardContainer'
};

export const noneOption = { value: 'none', label: 'Ninguno' };

// parse from [screensStyles] a classesByScreen object
export const parseScreensStyles = (screensStyles, classesParser) => {
  let classesByScreen = {};
  let stylesByScreen = {};
  
  // get classes of each screen
  screensSizes.forEach(screen => {
    let accumulatedStyles = {};
    // applicar al stylesSpecs por defecto las clases de los screens más pequeños usando lodash
    // se debe iterar máximo hasta el screen
    screensSizes.forEach((screenPrev, index) => {
      if (index <= screensSizes.indexOf(screen)) {
        let stylesSpecs = _.find(screensStyles, { screen: screenPrev });
        if (stylesSpecs) {
          accumulatedStyles = _.merge(accumulatedStyles, stylesSpecs);
        }
        stylesByScreen[screen] = _.cloneDeep(accumulatedStyles);
      }
    });
    // get classes from stylesSpecs
    let className = classesParser ? classesParser(accumulatedStyles) : '';
    classesByScreen[screen] = className;
  });

  return { classesByScreen, stylesByScreen };
}