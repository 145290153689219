import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  layersOutline
} from 'ionicons/icons';
import { IonButton, IonIcon, IonPopover } from "@ionic/react";
import { nanoid } from "nanoid";
import { useAsyncMemo } from "use-async-memo";
import config from "../../config";
import Model from "../../libs/ModelClass";
import { useAuth } from "../user/AuthContext";
import { usePanel } from "../panel/usePanel";
import BadgeLoading from "../../components/ui/BadgeLoading";


const instancesEntitySlug = config.modules.instances.instancesEntitySlug;
const InstanceModel = Model.extend(instancesEntitySlug);

export const useFetchInstanceDoc = (instanceHash) => {
  const instancesDoc = useAsyncMemo(async () => {
    let docs = await InstanceModel.filterByAttributes({ 
      hash: instanceHash ? instanceHash : 'main',
      deleted: 'false'
    });
    return docs[0];
  }, [instanceHash]);
  return instancesDoc;
}

export const InstanceInlineSelectorForMain = (props) => {
  let { design, selectedInstance, setSelectedInstance } = props;
  const { userAuth } = useAuth();
  const { parsedParams, pushWithParams } = usePanel(props);

  const instancesDocs = useAsyncMemo(async () => {
    let docs;
    if (userAuth.roleOfMainDoc.data.nameSlug === 'super-admin') {
      docs = await InstanceModel.filterByAttributes({ deleted: 'false' });
    }
    else {
      docs = userAuth.instancesDocs;
    }
    return docs;
  }, []);

  useEffect(() => {
    const parsedInstanceHash = parsedParams?.instanceHash || 'main';
    if (instancesDocs && parsedInstanceHash !== selectedInstance?.data.hash) {
      const instanceDoc = instancesDocs.find(doc => doc.data.hash === parsedParams.instanceHash);
      setSelectedInstance(instanceDoc);
    }
  }, [instancesDocs, parsedParams?.instanceHash])

  if (!instancesDocs) {
    return (
      <BadgeLoading label={false} className='px-1 p-1 text-brand-dark-contrast' />
    );
  }

  const doSelectInstance = (instanceDoc) => {
    if (instanceDoc.data.hash !== parsedParams?.instanceHash) {
      pushWithParams({ ...parsedParams, instanceHash: instanceDoc.data.hash });
    }
  };


  return (
    <InstanceInlineSelector
      {...{ 
        design,
        instancesDocs,
        setSelectedInstance: doSelectInstance,
        selectedInstance
      }}
      buttonFill="clear"
    />
  );
};

export const InstanceInlineSelector = (props) => {
  let {
    isLoadingInstance,
    instancesDocs,
    selectedId,
    selectedInstance,
    setSelectedInstance,
    classes = {},
    design = 'labelAndButton', // 'labelInsideBtn', 'labelAndButton'
    buttonColor = null,
    buttonFill = 'solid',
    iconColor = null,
    popoverSide = 'bottom',
    popoverAlignment = 'end'
  } = props;
  const elementId = useMemo(() => nanoid(), []);
  const [ isPopoverOpen, setIsPopoverOpen ] = useState(false);

  const openPopover = (event) => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  const setSelectedInner = (item) => {
    setSelectedInstance(item);
    setIsPopoverOpen(false);
  };

  classes = _.defaults(classes, {
    fieldContainer: '',
    fieldLabel: 'font-semibold text-inherit',
    actionsPopover: ''
  });

  const getName = () => isLoadingInstance ? '...' : selectedInstance?.data.name;

  return (<>
    {design === 'labelAndButton' ? (
      <div id={elementId} className={`flex flex-row gap-3 items-center ${classes.fieldContainer}`}>
        <IonButton
          onClick={openPopover}
          color={buttonColor}
          fill={buttonFill}
          size="small"
        >
          <IonIcon icon={layersOutline} color={iconColor} size="small"></IonIcon>
        </IonButton>
        <div className={classes.fieldLabel} style={{ textTransform: 'initial' }}>{getName()}</div>
      </div>
    ) : null}

    {design === 'labelInsideBtn' ? (
      <div id={elementId} className={classes.fieldContainer}>
        <IonButton
          onClick={openPopover}
          color={buttonColor}
          fill={buttonFill}
          size="small"
        >
          <IonIcon icon={layersOutline} color={iconColor} size="small" className="mr-2"></IonIcon>
          <div className={classes.fieldLabel} style={{ textTransform: 'initial' }}>{getName()}</div>
        </IonButton>
      </div>
    ) : null}

    <IonPopover
      trigger={elementId}
      side={popoverSide}
      alignment={popoverAlignment}
      isOpen={isPopoverOpen}
      onDidDismiss={closePopover}
    >
      <div className="p-2">
        <InstanceSelector 
          title="Seleccionar Instancia"
          {...props}
          {...{
            selectedId,
            instancesDocs,
            selectedInstance,
            setSelectedInstance: setSelectedInner,
            closePopover
          }}
        />
      </div>
    </IonPopover>
  </>
  );
}

export const InstanceSelector = ({
  instancesDocs,
  selectedId,
  selectedInstance,
  setSelectedInstance,
  title = "Selecciona instancia",
  closePopover
}) => {
  const [ instanceName, setInstanceName ] = useState();
  const [ instanceList, setInstanceList ] = useState([]);

  useEffect(() => {
    setInstanceList(instancesDocs || []);
  }, [instancesDocs]);

  const setNameValue = (e) => {
    setInstanceName(e.target.value);
  };

  const filterList = () => {
    if (instanceName) {
      return instanceList.filter(doc => doc.data.name?.toLowerCase().includes( instanceName.toLowerCase() ));
    }
    return instanceList;
  };

  const isSelected = (item) => (selectedInstance?.id === item.id) || (selectedId === item.id);

  const Card = ({ className, selectedInstance, item }) => (
    <div className={`
      flex flex-row items-stretch w-full 
      ${isSelected(item)
          ? 'border border-brand-primary' : 'border-b border-gray-200'
      }
      p-2 cursor-pointer font-semibold
      ${className}`
    }>
      {item?.data.name}
    </div>
  );

  return (
    <div className="relative">
      <button type="button" onClick={() => closePopover()} className="absolute top-0 right-0 w-5 h-5 rounded-full bg-brand-secondary-contrast text-gray-100 font-normal text-center">
        <div className="relative -mt-0.5">x</div>
      </button>
      <h3 className='mb-2 text-sm font-semibold'>
        {title}
      </h3>
      {/* selected */}
      {selectedInstance ? (<>
        <Card className="mb-4 rounded-md overflow-hidden" {...{ selectedInstance, item: selectedInstance }} />
      </>) : null}
      {/* input */}
      <div className="rounded-md border border-gray-300 overflow-hidden">
        <div className="">
          <input
            type="text"
            value={instanceName}
            onChange={setNameValue}
            className="appearance-none text-center border-b w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            placeholder="Buscar por nombre"
          />
        </div>
        {/* list */}
        <div className="h-[40vh] overflow-y-scroll">
          <div className="flex flex-col">
            {filterList().map(item => (
              !isSelected(item) ? (
                <button 
                  key={item.id}
                  onClick={() => setSelectedInstance(item)}
                >
                  <Card {...{ selectedInstance, item }} />
                </button>
              ) : null
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}