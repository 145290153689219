import _ from "lodash";
import { useMemo } from "react";
import { useAsyncMemo } from 'use-async-memo';
import { useCart } from "./useCartData";
import Model from "../../libs/ModelClass";
import { withPrefix } from "../instance/utilsInstance";
import { getConfig } from "../../config";


export const useCategoryFields = (props) => {
  const { entityMap } = useCart();

  const categoryFieldsDocList = useAsyncMemo(async () => {
    if (props.categoryFieldsDocList) {
      return props.categoryFieldsDocList;
    }
    let docs = await Model.extend(entityMap.cartItemFields?.entitySlug || withPrefix(props.instance, getConfig().modules.cart.cartItemFieldsEntitySlug))
      .filterByAttributes({ deleted: 'false' });
    let categoryFieldsById = {};
    docs.forEach((doc) => {
      // data to doc format
      doc.data.fields = doc.data.fields.map((data) => (new Model(data)));
      categoryFieldsById[doc.id] = doc;
    });
    return docs;
  }, []);

  const categoryFieldsById = useMemo(() => {
    if (props.categoryFieldsById) {
      return props.categoryFieldsById;
    }
    if (!categoryFieldsDocList) { return null; }
    let categoryFieldsById = {};
    categoryFieldsDocList.forEach((doc) => {
      categoryFieldsById[doc.id] = doc;
    });
    return categoryFieldsById;
  }, [categoryFieldsDocList]);

  return {
    categoryFieldsDocList,
    categoryFieldsById
  };
}