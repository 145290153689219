import Model from "../../libs/ModelClass";
import { useEffect, useState, useCallback } from "react";
import { useAsyncMemo } from "use-async-memo";
import { useModule } from "../../libs/ModuleContext";


export const useReadDocById = (props) => {
  const { 
    // doc, 
    docId, defaultValue = null, instance, instanceDoc, entitySlug, entityDoc, taxonomyTypesDocList
  } = props;
  const { fireEventReducer } = useModule();
  const [firedAfterRead, setFiredAfterRead] = useState(false);
  const [parsedDoc, setParsedDoc] = useState(null);
  const ExtendedModel = Model.extend(entitySlug);
  const isEdit = docId !== 'new';

  const doc = useAsyncMemo(async () => {
    try {
      if (props.doc) {
        return props.doc;
      }
      const fetchedDoc = isEdit ? await ExtendedModel.findById(docId) : null;
      return fetchedDoc || new ExtendedModel({ ...(defaultValue || {}) });
    } catch (error) {
      console.error("Error fetching document:", error);
      return new ExtendedModel({ ...(defaultValue || {}) });
    }
  }, [docId, defaultValue, entitySlug]);

  useEffect(() => {
    fireAfterRead(doc, true);
  }, [doc, taxonomyTypesDocList]);

  const fireAfterRead = useCallback(async (doc, force = false) => {
    if ((force || !firedAfterRead) && doc && taxonomyTypesDocList?.length) {
      const modifiedDocData = await fireEventReducer('afterRead', { doc, isEdit, instance, entitySlug, taxonomyTypesDocList, instanceDoc, entityDoc }, doc.data);  
      const newDoc = doc.copy();
      newDoc.data = modifiedDocData;
      setFiredAfterRead(true);
      setParsedDoc(newDoc);
    }
  }, [firedAfterRead, taxonomyTypesDocList, fireEventReducer, isEdit, instance, entitySlug, instanceDoc, entityDoc]);

  const setDocData = (newData) => {
    if (parsedDoc) {
      const newDoc = parsedDoc.copy();
      newDoc.data = newData;
      setParsedDoc(newDoc);
    }
  };

  return {
    doc: parsedDoc,
    rawDoc: doc,
    fireAfterRead,
    setDocData
  };
};
