import _ from 'lodash';
import { IonButton, IonIcon } from "@ionic/react";
import { addCircleOutline } from "ionicons/icons";
import { useMemo, useState } from "react";
import { RouteEntityCrudForm } from "../entity/RouteEntityCrudForm";
import toast from "react-hot-toast";
import { useModule } from '../../libs/ModuleContext';
import ObjectForm, { objectFormToTaxonomyTypesDocList } from '../entity/ObjectForm';
import { inputClasses } from '../../components/EntityTaxonomyForm';


export const pagesTemplateEntitySlug = 'pagesTemplates';


const PagesTemplatesHandler = (props) => {
  let {
    instance,
    history,
    match,
    parsedParams,
    values,
    templateToApply, 
    setTemplateToApply,
    onClose
  } = props;
  const { isAllowed } = useModule();
  const [ showCreateModal, setShowCreateModal ] = useState(false);

  const taxonomyTypes = objectFormToTaxonomyTypesDocList({
    'template': {
      type: 'selectOneEntityDocument',
      name: 'Aplicar plantilla',
      design: 'extensed',
      onAfterChange: ({ doc }) => {
        setTemplateToApply(doc);
      },
      onInputClear: () => {
        setTemplateToApply(null);
      },
      param: {
        entitySlug: pagesTemplateEntitySlug,
      }
    }
  }, { instance });
  
  const valuesParsed = useMemo(() => {
    // remove layoutBlocks
    let newValues = {
      name: values.name,
      withBrandLayout: values.withBrandLayout,
      stack: _.filter((values.stack), block => !block.isLayoutBlock)
    };
    return newValues;
  }, [values]);
  
  const onSaveTemplate = async (doc) => {
    toast('Ahora puedes usar este template en próximas páginas', { icon: '👍🏻' });
    setShowCreateModal(false);
    onClose && onClose();
  };

  return (
    <div className={inputClasses.renderListContainer}>
      {/* pick to apply */}
      <ObjectForm
        {...{ instance, history, match, parsedParams }}
        entitySlug={pagesTemplateEntitySlug}
        taxonomyTypesDocList={taxonomyTypes}
        classes={{
          loadingContainer: "!py-4"
        }}
      />

      {/*
        Creation for ADMIN only
      */}
      <div className={inputClasses.fieldContainer}>
        <div className={inputClasses.fieldLabel}>
          Crear Template
        </div>
        <div className={inputClasses.fieldInput}>
          {/* save current stack as template */}
          {isAllowed(pagesTemplateEntitySlug, ['create', 'instance:create']) ? (<>
            <IonButton onClick={() => setShowCreateModal(true)} color="primary" size="small" fill="outline">
              <IonIcon icon={addCircleOutline} className="mr-2" slot="start" size="small"></IonIcon>
              Crear template
            </IonButton>
            <div className="py-4">
              Guarda la versión actual de esta página para reutiliza el diseño y el contenido
            </div>
          </>) : null}

          {/* save as template form */}
          {showCreateModal ? (
            <RouteEntityCrudForm
              context={{
                entitySlug: pagesTemplateEntitySlug,
                docId: 'new',
                asModal: true,
                onAfterSave: onSaveTemplate,
                defaultValue: valuesParsed,
                onClose: () => setShowCreateModal(false)
              }}
              classes={{
                loadingContainer: "!py-4"
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PagesTemplatesHandler;
