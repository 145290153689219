import _ from 'lodash';
import { useMemo } from 'react';
import { RawInputObjectForm } from '../../components/Form';
import { objectFormToTaxonomyTypes } from '../entity/ObjectForm';


const RenderForm = (props) => {
  let { 
    fieldName
  } = props;

  const formTaxonomyTypes = useMemo(() => (
    objectFormToTaxonomyTypes({
      'bgStyles': {
        name: 'Fondo del menú',
        type: 'part',
        part: 'background',
        defaultValue: [{
          screen: 'xxs',
          type: 'color',
          color: 'dark-shade'
        }]
      },
      'logo': {
        name: 'Logo del menú de la tienda',
        type: 'part',
        part: 'logo'
      }
    })
  ), []);

  return (
    <div className="flex flex-row gap-4 flex-wrap">
      <RawInputObjectForm
        {...props}
        name={fieldName}
        fields={formTaxonomyTypes}
      />
    </div>
  );
}

const dataTypeMenuMain = {
  RenderForm
};

export default dataTypeMenuMain;