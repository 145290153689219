import React, { lazy, Suspense } from 'react';


const SliderGallery = lazy(() => import('./SliderGallery'));

const SliderGalleryLazy = ({ render, fallback, ...props }) => {
  return (
    <Suspense fallback={fallback}>
      {render ? render(<SliderGallery {...props} />) : <SliderGallery {...props} />}
    </Suspense>
  );
};

export default SliderGalleryLazy;