import {useMemo } from 'react';
import _ from 'lodash';
import useEntityTaxonomyForm, { EntityTaxonomyForm, inputClasses } from '../../components/EntityTaxonomyForm';
import { nanoid } from 'nanoid';
import { partsMap } from '../blockStack/BlockStack';
import { arrayToOptions } from '../../libs/utils';


export const objectFormToTaxonomyTypesDocList = (fieldsDef, extraDefs) => {
  if (!fieldsDef) {
    return null;
  }
  let fieldsDataList = objectFormToTaxonomyTypes(fieldsDef, extraDefs);
  return fieldsDataList.map((data, index) => ({
    id: index,
    data: {
      ...data,
      nameSlug: data.fieldName
    }
  }));
};

export const objectFormToTaxonomyTypes = (fieldsDef, extraDefs) => {
  if (!fieldsDef) {
    return null;
  }

  const taxonomyTypes = [];

  _.forEach(fieldsDef, (paramDef = {}, paramName) => {
    const paramNameToUse = _.isString(paramName) ? paramName : (paramDef.fieldName || paramDef.nameSlug);
    const commonProps = {
      ...paramDef,
      ...extraDefs,
      id: nanoid(),
      name: paramDef.name || paramName,
      type: paramDef.type || (_.isArray(paramDef) ? 'collection' : paramDef),
      fieldName: paramNameToUse,
      nameSlug: paramNameToUse,
      style: { design: paramDef.design || 'default' },
      param: {
        ...paramDef.param
      }
    };

    if (paramDef?.RenderForm) {
      taxonomyTypes.push({
        ...commonProps,
        RenderForm: paramDef.RenderForm,
        type: 'coded'
      });
    } else if (paramDef.type === 'part') {
      const partDef = _.find(partsMap, (partDef) => partDef.part === paramDef.part);
      taxonomyTypes.push({
        ...commonProps,
        type: partDef?.type === 'collection' ? 'collection' : 'object',
        param: { ...paramDef.param, fields: objectFormToTaxonomyTypes( partDef.fields, extraDefs ) }
      });
    } else if (paramDef.type === 'object') {
      taxonomyTypes.push({
        ...commonProps,
        type: 'object',
        param: { ...paramDef.param, fields: objectFormToTaxonomyTypes( paramDef.fields, extraDefs ) }
      });
    } else if (paramDef === 'boolean' || paramDef.type === 'boolean') {
      taxonomyTypes.push({
        ...commonProps,
        param: paramDef
      });
    } else if (paramDef === 'select' || paramDef.type === 'select' || paramDef === 'multiselect' || paramDef.type === 'multiselect') {
      taxonomyTypes.push({
        ...commonProps,
        param: { ...paramDef.param, options: arrayToOptions(paramDef.options) }
      });
    } else if (paramDef === 'gallery' || paramDef.type === 'gallery') {
      taxonomyTypes.push({
        ...commonProps,
        param: {
          dimensions: paramDef.dimensions ? paramDef.dimensions : null,
          doResize: true,
          instance: paramDef.instance
        }
      });
    } else if (_.isArray(paramDef) || paramDef.type === 'collection') {
      const collectionFields = _.isArray(paramDef) ? paramDef[0] : paramDef.fields;
      taxonomyTypes.push({
        ...commonProps,
        type: 'collection',
        param: { ...paramDef.param, fields: objectFormToTaxonomyTypes(collectionFields, extraDefs) }
      });
    } else {
      taxonomyTypes.push(commonProps);
    }
  });

  return taxonomyTypes;
};

export const EntityObjectForm = (props) => {
  let { instance, entitySlug, values, formApi, exclude, only, classes={}, fieldsRequired, setFieldsRequired } = props;
  const { EntityTaxonomyForm } = useEntityTaxonomyForm({ 
    entityId: 'new', 
    instance,
    entitySlug,
    fieldsRequired,
    setFieldsRequired
  });
  return (
    <EntityTaxonomyForm
      instance={instance}
      exclude={exclude}
      only={only}
      values={values}
      classes={{
        ...inputClasses,
        ...classes
      }}
      overrideParams={{
        showLabel: true,
        showClearBtn: true
      }}
      formApi={formApi}
      entitySlug={entitySlug}
    />
  );
};

export const ObjectForm = (props) => {
  let { instance, entitySlug, fields, taxonomyTypesDocList, taxonomyTypesDataList, values, classes={}, overrideParams={}, paramsByFieldSlug, fieldsRequired } = props;
  const fieldsDataList = useMemo(() => fields && objectFormToTaxonomyTypes(fields), [fields]);
  return (
    <EntityTaxonomyForm
      // {...props} // causa infinit loop
      instance={instance}
      entitySlug={entitySlug}
      taxonomyTypesDataList={taxonomyTypesDataList || fieldsDataList}
      taxonomyTypesDocList={taxonomyTypesDocList}
      values={values}
      fieldsRequired={fieldsRequired}
      classes={{
        ...inputClasses,
        ...classes
      }}
      overrideParams={{
        showLabel: true,
        showClearBtn: true,
        ...overrideParams
      }}
      paramsByFieldSlug={paramsByFieldSlug}
    />
  );
};

export default ObjectForm;