import { Link } from "react-router-dom";
import { FaFacebook, FaWhatsapp, FaInstagram } from 'react-icons/fa';
import { AnimationAddClassWhenVisible } from "../../../components/ui/AnimationAddClassWhenVisible";
import BtnSignUpModal from "../../../components/ui/BtnSignUpModal";
import { lineBreaks } from "../../../libs/utils";
import IonContactBTN from "../../../components/ui/IonContactBTN";
import { BtnList } from "../parts/PartBtnList";
import { sectionCardBtnsFields } from "./BlockSectionCardBtns";

export const phoneObjectFields = {
  'useBrandPhone': {
    type: 'boolean',
    name: 'Utilizar mismo que la marca',
    defaultValue: true
  },
  'phoneNumber': {
    type: 'phone',
    name: 'Número personalizado'
  },
};

export const whatsappObjectFields = {
  ...phoneObjectFields,
  'phoneMessage': {
    type: 'textArea',
    name: 'Mensaje predeterminado'
  }
};

export const emailObjectFields = {
  'emailDirection': {
    type: 'text',
    name: 'Email'
  },
  'emailMessage': {
    type: 'textArea',
    name: 'Mensaje predeterminado'
  }
};
export default function () {
  return {
    type: 'pageFooter',
    variant: 'linksFixedCTA',
    template: PageFooterALinksFixedCta,
    params: {
      showCTA: {
        type: 'boolean',
        name: 'Mostrar CTA',
        defaults: true
      },
      showPanel: {
        type: 'boolean',
        name: 'Mostrar panel',
        defaults: true
      },
      showPoliticsLinks: {
        type: 'boolean',
        name: 'Mostrar links de política de privacidad',
        defaults: false
      },
      showMadeWithLove: {
        type: 'boolean',
        name: 'Mostrar hecho con amor desde',
        defaults: true
      },
    },
    strings: {
      'socialLinks': {
        type: 'object',
        name: 'Enlaces sociales',
        fields: {
          'linkWebsite': {
            type: 'text',
            name: 'Enlace a la página: URL'
          },
          'linkWebsiteLabel': {
            type: 'text',
            name: 'Enlace a la página: Etiqueta a mostrar'
          },
          'whatsapp': {
            type: 'object',
            name: 'Teléfono para Whatsapp',
            fields: whatsappObjectFields
          },
          'linkFacebook': {
            type: 'text',
            name: 'Redes Sociales: Enlace a Facebook'
          },
          'linkInstagram': {
            type: 'text',
            name: 'Redes Sociales: Enlace a Instagram'
          },
        }
      },
      'btnList': {
        type: 'collection',
        name: 'Botones flotantes',
        fields: sectionCardBtnsFields
      }
    },
  };
}

export function PageFooterALinksFixedCta(props) {
  let {
    specDesign,
    strings,
    showCTA,
    showPanel,
    btnList,
    showMadeWithLove,
    showPoliticsLinks,
    instance,
    history,
    match
  } = props;
  const currentInstance = match.params.instance || '';
  let panelUrl = currentInstance ? `/${currentInstance}/login` : '/login';
  
  let classes = {
    ctaBtn: `
      text-brand-primary-contrast text-2xl p-3 lg:p-3 cursor-pointer rounded-full
      border border-brand-primary-contrast 
      hover:border-brand-secondary hover:scale-110 transition-all duration-300
    `
  }
  return (
    <footer className="pt-4 md:pt-8 bg-cover relative bg-brand-primary" style={{ backgroundImage: strings.backgroundImageUrl ? `url(${strings.backgroundImageUrl})` : '' }}>
      {/* overlay */}
      {strings.backgroundImageUrl ? (
        <div className="absolute inset-0 bg-black opacity-30"></div>
      ) : null}

      <div className="py-16 space-y-8">
        {/* cta */}
        {strings.linkWebsite ? (
          <div className="relative z-10 container-width text-center ">
            <AnimationAddClassWhenVisible classToAdd="animate-fadeIn">
              <a href={strings.linkWebsite} className="text-white font-semibold text-3xl md:text-4xl cursor-pointer font-brand-mulish rounded-sm">
                {lineBreaks(strings.linkWebsiteLabel) || strings.linkWebsite}
              </a>
            </AnimationAddClassWhenVisible>
          </div>
        ) : null}
        
        {/* links */}
        <div className="px-4 text-center text-brand-primary-contrast">
          {(strings.socialLinkTitle || strings.linkFacebook || strings.number || strings.linkInstagram) ? (
            <div className="">
              {strings.socialLinkTitle ? (
                <p className="text-lg">{lineBreaks(strings.socialLinkTitle)}</p>
              ) : null}
              <div className="flex justify-center space-x-4 lg:space-x-6 mt-4">
                {strings.linkFacebook ? (
                  <div className={classes.ctaBtn}>
                    <a target='_blank' href={strings.linkFacebook} rel="noreferrer"><FaFacebook/></a>
                  </div>
                ) : null}
                {strings.number && strings.contactMessage ? (
                  <div className={classes.ctaBtn}>
                    <a target='_blank' href={`https://wa.me/${strings.number}?text=${strings.contactMessage}`} rel="noreferrer"><FaWhatsapp/></a>
                  </div>
                ) : null}
                {strings.linkInstagram ? (
                  <div className={classes.ctaBtn}>
                    <a target='_blank' href={strings.linkInstagram} rel="noreferrer"><FaInstagram/></a>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}

          <div className="my-4">
            <p className="text-base ml-2 mt-0">{lineBreaks(strings.locationString)}</p>
            {strings.email ? (
              <a href={`mailto:${strings.email}?subject=${strings.contactMessage}`} target="_blank" rel="noopener noreferrer" className="block text-base ml-2 mt-0">
                {strings.email}
              </a>
            ) : null}
            {strings.number ? (
              <a href={`https://wa.me/${strings.number}?text=${strings.contactMessage}`} target="_blank" rel="noopener noreferrer" className="block text-base ml-2 mt-0">
                {strings.numberString || strings.number}
              </a>
            ) : null}
          </div>
        </div>

        {showMadeWithLove ? (
          <div className="flex justify-center text-brand-primary-contrast w-full px-10 lg:text-lg xl:text-xl">
            Hecho con
            <img src="/icons/heart.svg" alt="amor" className="beating mx-1"/>
            en Paraguay
          </div>
        ) : null}
      </div>

      {(showPanel || showPoliticsLinks) ? (
        <div className="text-center py-4 md:py-8 pb-20 pl-4 md:pl-0 bg-gray-900 text-white">
          {showPoliticsLinks ? (<>
            <Link to="/m/page/politica-de-privacidad" className="underline">Política de privacidad</Link>
            <span className=""> | </span>
            <Link to="/m/page/seguridad-de-datos" className="underline">Seguridad de datos</Link>
          </>) : null}
          {(showPanel && showPoliticsLinks) ? (
            <span className=""> | </span>
          ) : null}
          {showPanel ? (<>
            <Link to={panelUrl} className="underline">Administración</Link>
          </>) : null}
        </div>
      ) : null}

      {/* fixed footer */}
      {showCTA && btnList?.length ? (
        <div className="fixed bottom-4 right-4 md:right-6 min-w-40 pr-2 z-50 flex place-content-end">
          <BtnList
            specDesign={specDesign}
            strings={{ items: btnList }}
            params={{
              type: 'row', 
              gap: '4',
              // align
              // justify
            }}
            classes={{ btnListContainer: 'mt-8 w-fit' }}
            history={history}
            instance={instance}
          />
        </div>
      ) : null}
    </footer>
  );
}