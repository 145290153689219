import React, { lazy, Suspense } from 'react';


const ViewGPS = lazy(() => import('./ViewGPS'));

const ViewGPSLazy = ({ render, fallback, ...props }) => {
  return (
    <Suspense fallback={fallback}>
      {render ? render(<ViewGPS {...props} />) : <ViewGPS {...props} />}
    </Suspense>
  );
};

export default ViewGPSLazy;