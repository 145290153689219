import _ from 'lodash';
import { Link } from 'react-router-dom';
import { AnimationAddClassWhenVisible } from '../../../components/ui/AnimationAddClassWhenVisible';
import { PartLogo } from '../parts/PartLogo';
import { compileStringTemplate, lineBreaks } from '../../../libs/utils';
import { Background } from '../parts/PartBackground';
import { BtnsList, sectionCardBtnsFields } from './BlockSectionCardBtns';
import useBreakpoints from '../../../libs/useBreakpoints';
import { urlPrefix } from '../../instance/utilsInstance';
import { Text } from '../parts/PartText';
import { Card } from '../parts/PartCard';
import { CardContainer } from '../parts/PartCardContainer';
import { cardContainerStylesField } from '../parts/stylesFields';


export default function () {
  return {
    type: 'pageHeader',
    variant: 'heroStatic',
    // description: 'Incluye una imagen de fondo estática, ocupa todo el ancho y casi todo el alto de la pantalla.',
    template: PageHeaderHeroStatic,
    presets,
    // fields of params tab
    params: {
      'design': {
        name: 'Alto del bloque',
        type: 'select',
        options: ['mini', 'full'],
        defaultValue: 'full'
      },
      'bgStyles': {
        name: 'Fondo del bloque',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'background'
      },
      'blockCardStyles': cardContainerStylesField,
      'cardContentStyles': {
        name: 'Tarjeta del contenido: textos y botones',
        descriptionText: 'Incluye al título, subtítulo y botones, estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },
      'cardTextsStyles': {
        name: 'Tarjeta del contenido: sólo textos',
        descriptionText: 'Incluye al título y subtítulo, estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },
      'logoStyles': {
        name: 'Logo',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'logo'
      },
      'logoCardStyles': {
        name: 'Tarjeta del Logo',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },
      'titleStyles': {
        name: 'Título',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      'titleCardStyles': {
        name: 'Tarjeta del Título',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },
      'subtitleStyles': {
        name: 'Subtítulo',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      'subtitleCardStyles': {
        name: 'Tarjeta del Subtítulo',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },
      'btnListCardStyles': {
        name: 'Tarjeta de los botones',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      }
    },
    // fields of text tab
    strings: {
      'title': {
        type: 'textArea',
        name: 'Título'
      },
      'subtitle': {
        type: 'textArea',
        name: 'Subtítulo'
      },
      'btnList': {
        type: 'collection',
        name: 'Botones',
        fields: sectionCardBtnsFields
      },
    },
    partsMap: [{
      'label': 'Bloque',
      'background': ['bgStyles'],
      'card': ['blockCardStyles']
    }, {
      'label': 'Logo',
      'logo': ['logoStyles'],
      'card': ['logoCardStyles']
    }, {
      'label': 'Título',
      'strings': ['title'],
      'text': ['titleStyles'],
      'card': ['titleCardStyles']
    }, {
      'label': 'Subtítulo',
      'strings': ['subtitle'],
      'text': ['subtitleStyles'],
      'card': ['subtitleCardStyles']
    }, {
      'label': 'Botones',
      'strings': ['btnList'],
      'card': ['btnListCardStyles']
    }, {
      'label': 'Textos y botones',
      'card': ['cardContentStyles']
    }, {
      'label': 'Sólo Textos',
      'card': ['cardTextsStyles']
    }]
  };
};

export function PageHeaderHeroStatic(props) {
  let {
    design, logoStyles, title, subtitle, btnList,
    bgStyles, logoCardStyles, titleStyles, titleCardStyles,
    subtitleStyles, subtitleCardStyles, btnListCardStyles,
    blockCardStyles, cardContentStyles, cardTextsStyles,
    specDesign, instance, history, match
  } = props;
  let { isMinBreakpointActive } = useBreakpoints();

  const homePath = urlPrefix(match.params.instance, '/');
  
  return ( 
    <Background params={bgStyles} classes={{ bgContainer: "snap-start" }} classDefault="bg-gradient-to-br from-brand-primary to-brand-primary-contrast" specDesign={specDesign}>
      <CardContainer params={blockCardStyles} specDesign={specDesign}>
        {/* full*/}
        {design === 'full' ? (
          <div className="">
            {/* logo */}
            {logoStyles ? (
              <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[100ms]">
                <Card params={logoCardStyles} specDesign={specDesign} classes={{outCardContainer: "mt-8 xl:mt-8 2xl:mt-14 3xl:mt-16 4xl:mt-20"}}>
                  <Link to={homePath}>
                    <PartLogo
                      params={logoStyles}
                      classes={{ logoContainer: '!inline-block' }}
                      showSiteName={false}
                      specDesign={specDesign}
                      instance={instance}
                    />
                  </Link>
                </Card>
              </AnimationAddClassWhenVisible>
            ) : null}
            {/* content*/}
            <div className="">
              <Card params={cardContentStyles} specDesign={specDesign}>
                <Card params={cardTextsStyles} specDesign={specDesign}>
                  {title ? (
                    <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[150ms]">
                      <Text textParams={titleStyles} cardParams={titleCardStyles} className="text-brand-dark" classDefault="text-4xl xl:text-5xl 2xl:text-5xl font-brand-main font-bold" specDesign={specDesign}>
                        {lineBreaks(compileStringTemplate(title, specDesign.stringsVars))}
                      </Text>
                    </AnimationAddClassWhenVisible>
                  ) : null}
                  {subtitle ? (
                    <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[300ms]">
                      <Text textParams={subtitleStyles} cardParams={subtitleCardStyles} className="text-brand-dark" classDefault="text-2xl xl:text-3xl 2xl:text-3xl text-brand-dark/80 font-medium leading-snug" specDesign={specDesign}>
                        {lineBreaks(compileStringTemplate(subtitle, specDesign.stringsVars))}
                      </Text> 
                    </AnimationAddClassWhenVisible>
                  ) : null}
                </Card>
                {btnList?.length ? (
                  <div className="">
                    <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
                      {/* <div className="w-fit flex flex-col lg:flex-row gap-2 mt-10 md:mt-4 xl:mt-6 2xl:mt-10 mx-auto"></div> */}
                      <Card params={btnListCardStyles} specDesign={specDesign} classes={{cardContainer: "flex flex-col md:flex-row gap-2"}}>
                        <BtnsList
                          {...props}
                          items={btnList}
                        />
                      </Card>
                    </AnimationAddClassWhenVisible>
                  </div>
                ) : null}
              </Card>
            </div>
          </div>
        ) : null}

        {/* mini*/}
        {design === 'mini' ? (
          <div className="">
            <div className="flex flex-row gap-4 md:gap-8">
              {/* logo */}
              {logoStyles ? (
                <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[100ms]">
                  <Card params={logoCardStyles} specDesign={specDesign}>
                    <Link to={homePath}>
                      <PartLogo
                        params={logoStyles}
                        classes={{ logoContainer: '!inline-block' }}
                        showSiteName={false}
                        specDesign={specDesign}
                        instance={instance}
                      />
                    </Link>
                  </Card>
                </AnimationAddClassWhenVisible>
              ) : null}
              {/* content*/}
              <Card params={cardContentStyles} specDesign={specDesign}>
                <Card params={cardTextsStyles} specDesign={specDesign}>
                  {title ? (
                    <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[300ms]">
                      <Text textParams={titleStyles} cardParams={titleCardStyles} className="text-brand-dark" classDefault="text-xl md:text-xl lg:text-3xl xl:text-4xl text-brand-dark font-brand-main font-bold" specDesign={specDesign}>
                        {lineBreaks(compileStringTemplate(title, specDesign.stringsVars))}
                      </Text> 
                    </AnimationAddClassWhenVisible>
                  ) : null}
                  {subtitle ? (
                    <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
                      <Text textParams={subtitleStyles} cardParams={subtitleCardStyles} className="text-brand-dark" classDefault="text-base md:text-lg lg:text-2xl text-brand-dark/80 font-medium leading-snug" specDesign={specDesign}>
                        {lineBreaks(compileStringTemplate(subtitle, specDesign.stringsVars))}
                      </Text> 
                    </AnimationAddClassWhenVisible>
                  ) : null}
                  {btnList?.length && isMinBreakpointActive('lg') ? (
                    <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[700ms]">
                      <div className="w-fit flex flex-col md:flex-row gap-2 mt-4 md:mt-6">
                        <BtnsList
                          {...props}
                          items={btnList}
                        />
                      </div>
                    </AnimationAddClassWhenVisible>
                  ) : null}
                </Card>
              </Card>
            </div>
            {btnList?.length &&!isMinBreakpointActive('lg') ? (
              <div className="">
                <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[700ms]">
                  {/* <div className="w-fit flex flex-row gap-2 mt-4 md:mt-6"></div> */}
                  <Card params={btnListCardStyles} specDesign={specDesign} classes={{cardContainer: "flex flex-row gap-2"}}>
                    <BtnsList
                      {...props}
                      items={btnList}
                    />
                  </Card>
                </AnimationAddClassWhenVisible>
              </div>
            ) : null}
          </div>
        ) : null}
      </CardContainer>
    </Background>
  )
};

const presets = {
  'base-details': {
    design: "full",
    "bgStyles": [
      {
        "bgColor": "primary-shade",
        "screen": "xxs",
        "bgVideoUrl": "",
        "bgOverlayAlpha": 0,
        "type": "color"
      }
    ],
    blockCardStyles: [
      {
        padding: null,
        screen: "xxs",
        cardWidth: "full",
        marginY: 20,
        marginX: 4
      },
      {
        screen: "md",
        marginY: 32,
        marginX: 16
      },
      {
        screen: "lg",
        marginX: 40,
        marginY: 32
      },
      {
        marginX: 64,
        screen: "xl",
        marginY: 20,
        blockHeight: "x1"
      },
      {
        screen: "2xl",
        marginX: 72,
        marginY: 56
      }
    ],
    logoStyles: [
      {
        source: "profileImg",
        shape: "square",
        size: 32,
        round: "full",
        resolution: "md",
        showSiteName: false,
        screen: "xxs"
      },
      {
        size: 36,
        screen: "md",
        showSiteName: false
      },
      {
        showSiteName: false,
        screen: "lg",
        size: 40
      },
      {
        showSiteName: false,
        size: 40,
        screen: "xl"
      }
    ],
    logoCardStyles: [
      {
        screen: "xxs",
        width: "fit",
        align: "center"
      },
      {
        marginX: null,
        align: "left",
        screen: "md",
        marginY: "none"
      }
    ],
    titleStyles: [
      {
        screen: "xxs",
        textSize: "3xl",
        fontVariations: ["bold"],
        font: "main",
        textColor: "primary-contrast",
        textAlign: "center"
      },
      {
        textSize: "5xl",
        textAlign: "left",
        screen: "md"
      },
      {
        fontVariations: ["bold"],
        font: "main",
        textSize: "5xl",
        screen: "lg"
      }
    ],
    titleCardStyles: [
      {
        align: "center",
        bgParams: {
          type: null,
          bgOverlayAlpha: 0,
          bgVideoUrl: "",
          bgColor: null
        },
        screen: "xxs",
        width: "fit",
        marginY: 2
      },
      {
        align: "left",
        width: "fit",
        bgParams: {
          type: null,
          bgVideoUrl: "",
          bgColor: null,
          bgOverlayAlpha: 0
        },
        screen: "md"
      }
    ],
    subtitleCardStyles: [
      {
        width: "full",
        align: null,
        marginX: null,
        screen: "xxs"
      },
      {
        align: "left",
        bgParams: {
          type: null,
          bgVideoUrl: "",
          bgOverlayAlpha: 0,
          bgColor: null
        },
        width: "fit",
        screen: "lg"
      }
    ],
    subtitleStyles: [
      {
        font: "secondary",
        textSize: "base",
        fontVariations: ["normal"],
        textAlign: "center",
        textColor: "primary-contrast",
        screen: "xxs"
      },
      {
        font: "secondary",
        textSize: "xl",
        screen: "md",
        textAlign: "left",
        fontVariations: ["normal"]
      },
      {
        font: "secondary",
        textSize: "xl",
        fontVariations: ["normal"],
        screen: "xl"
      }
    ],
    btnListCardStyles: [
      {
        align: "center",
        marginY: 4,
        width: "fit",
        screen: "xxs"
      },
      {
        screen: "md",
        align: "left"
      }
    ]
  },
  'base-raw': {
    "design": "full",
    "bgStyles": [
      {
        "bgColor": "#ffffff",
        "screen": "xxs",
        "bgVideoUrl": "",
        "bgOverlayAlpha": 0,
        "type": "color"
      }
    ],
    "blockCardStyles": [
      {
        "padding": null,
        "screen": "xxs",
        "cardWidth": "full",
        "marginX": 4,
        "marginY": 20
      },
      {
        "screen": "md",
        "marginX": 16,
        "marginY": 32
      },
      {
        "screen": "lg",
        "marginX": 40,
        "marginY": 40
      },
      {
        "screen": "xl",
        "marginX": 72,
        "marginY": 40
      },
      {
        "screen": "2xl",
        "marginX": 72,
        "marginY": 56
      }
    ],
    "logoStyles": [
      {
        "size": 28,
        "round": "full",
        "shape": "square",
        "screen": "xxs",
        "source": "profileImg",
        "showSiteName": false,
        "resolution": "md"
      },
      {
        "size": 32,
        "screen": "md",
        "showSiteName": false
      },
      {
        "size": 36,
        "screen": "lg",
        "showSiteName": false
      },
      {
        "size": 40,
        "screen": "xl",
        "showSiteName": false
      }
    ],
    "logoCardStyles": [
      {
        "width": "fit",
        "screen": "xxs",
        "align": "center"
      },
      {
        "screen": "md",
        "align": "left"
      }
    ],
    "titleStyles": [
      {
        "fontVariations": [
          "bold"
        ],
        "textSize": "3xl",
        "textAlign": "center",
        "screen": "xxs",
        "textColor": "dark",
        "font": "main"
      },
      {
        "textSize": "5xl",
        "textAlign": "left",
        "screen": "md"
      },
      {
        "textSize": "6xl",
        "screen": "lg"
      }
    ],
    "titleCardStyles": [
      {
        "width": "full",
        "screen": "xxs",
        "align": null
      }
    ],
    "subtitleCardStyles": [
      {
        "width": "full",
        "screen": "xxs",
        "align": null
      }
    ],
    "subtitleStyles": [
      {
        "textSize": "xl",
        "fontVariations": [
          "bold"
        ],
        "textAlign": "center",
        "screen": "xxs",
        "textColor": "dark",
        "font": "secondary"
      },
      {
        "textSize": "2xl",
        "textAlign": "left",
        "screen": "md"
      },
      {
        "textSize": "3xl",
        "screen": "xl"
      }
    ],
    "btnListCardStyles": [
      {
        "width": "fit",
        "screen": "xxs",
        "align": "center",
        "marginY": 8
      },
      {
        "screen": "md",
        "align": "left"
      }
    ]
  },
  'base-mini-raw': {
    "design": "mini",
    "bgStyles": [
      {
        "bgColor": "#ffffff",
        "screen": "xxs",
        "bgVideoUrl": "",
        "bgOverlayAlpha": 0,
        "type": "color"
      }
    ],
    "blockCardStyles": [
      {
        "padding": 4,
        "screen": "xxs",
        "cardWidth": "full",
        "alignX": "center",
        "alignY": "center",
        "marginY": 8
      },
      {
        "padding": 8,
        "screen": "md"
      },
      {
        "padding": "none",
        "screen": "lg",
        "marginX": 32,
        "marginY": 16
      },
      {
        "screen": "xl",
        "marginX": 56,
        "marginY": 20
      },
      {
        "screen": "2xl",
        "marginX": 96,
        "marginY": 20
      }
    ],
    "logoStyles": [
      {
        "size": 28,
        "shape": "square",
        "round": "full",
        "screen": "xxs",
        "source": "profileImg",
        "showSiteName": false,
        "resolution": "xs"
      },
      {
        "size": 32,
        "screen": "md",
        "showSiteName": false
      },
      {
        "size": 36,
        "screen": "lg",
        "showSiteName": false
      }
    ],
    "titleStyles": [
      {
        "textSize": "xl",
        "fontVariations": [
          "bold"
        ],
        "textAlign": "left",
        "screen": "xxs",
        "textColor": "dark",
        "font": "main"
      },
      {
        "textSize": "2xl",
        "screen": "md"
      },
      {
        "textSize": "3xl",
        "screen": "lg"
      }
    ],
    "subtitleStyles": [
      {
        "textSize": "base",
        "textAlign": "left",
        "screen": "xxs",
        "textColor": "dark",
        "font": "secondary"
      },
      {
        "textSize": "md",
        "screen": "md"
      },
      {
        "textSize": "lg",
        "screen": "lg"
      }
    ],
    "btnListCardStyles": [
      {
        "width": "full",
        "screen": "xxs",
        "marginX": null,
        "marginY": 4
      }
    ]
  },
};