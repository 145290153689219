import _ from 'lodash';
import useBreakpoints from "../../../libs/useBreakpoints";
import { useMemo } from 'react';
import { parseScreensStyles, screensField } from './stylesFields';
import { getImageURL } from '../../../libs/utils';
import { Card } from './PartCard';
import { applyClassRound, roundField } from './FieldRound';


export const imageSizesOptions = [2, 4, 6, 8, 10, 12, 14, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 72, 80, 96];
export const imageFitOptions = [{ value: 'cover', label: 'Cubrir' }, { value: 'contain', label: 'Ajustar' }, { value: 'fill', label: 'Rellenar' }, { value: 'scale-down', label: 'Escalar hacia abajo' }];
export const imageQualityOptions = ['xs', 'md', 'xl', 'full'];


export const imageSizeField = {
  type: 'select',
  name: 'Tamaño de visualización',
  descriptionText: 'Asigna el alto de la imagen ',
  helpText: 'Incrementando en números pares (24 equivale a 96 píxeles)',
  options: imageSizesOptions,
  design: 'buttons'
};

export const imageResolutionField = {
  type: 'select',
  name: 'Calidad de la imagen',
  options: imageQualityOptions,
  design: 'buttons'
};

export const imageFields = {
  'size': imageSizeField,
  'resolution': imageResolutionField,
  'round': roundField,
  'fit': {
    type: 'select',
    name: 'Ajuste de la imagen',
    options: imageFitOptions,
    design: 'buttons'
  },
  'src': {
    type: 'gallery',
    name: 'Imagen',
    descriptionText: 'Se mostrará la primera imágen de la lista'
  }
};

export default function () {
  return {
    part: 'image',
    name: 'Imagen',
    template: Image,
    type: 'collection',
    fields: {
      'screen': screensField,
      ...imageFields
    }
  };
};

export const imageClasses = `
  h-2 h-4 h-6 h-8 h-10 h-12 h-14 h-16 h-20 h-24 h-28 h-32 h-36 h-40 h-44 h-48 h-52 h-56 h-60 h-64 h-72 h-80 h-96
  min-h-2 min-h-4 min-h-6 min-h-8 min-h-10 min-h-12 min-h-14 min-h-16 min-h-20 min-h-24 min-h-28 min-h-32 min-h-36 min-h-40 min-h-44 min-h-48 min-h-52 min-h-56 min-h-60 min-h-64 min-h-72 min-h-80 min-h-96
  w-2 w-4 w-6 w-8 w-10 w-12 w-14 w-16 w-20 w-24 w-28 w-32 w-36 w-40 w-44 w-48 w-52 w-56 w-60 w-64 w-72 w-80 w-96
  min-w-2 min-w-4 min-w-6 min-w-8 min-w-10 min-w-12 min-w-14 min-w-16 min-w-20 min-w-24 min-w-28 min-w-32 min-w-36 min-w-40 min-w-44 min-w-48 min-w-52 min-w-56 min-w-60 min-w-64 min-w-72 min-w-80 min-w-96
  object-cover object-contain object-fill object-scale-down
`;

export const imageClassesParser = (props) => {
  if (!props) { return ''; }
  const { size, fit } = props;
  let className = '';
  // size
  if (size) {
    className += ` h-${size}`;
  }
  // fit
  if (fit) {
    className += ` object-${fit}`;
  }
  return className;
}

export function Image (props) {
  let {
    instance,
    imageParams,
    cardParams,
    specDesign,
    classDefault,
    className,
    children
  } = props;
  const { brkPnt } = useBreakpoints();
  const { classesByScreen, stylesByScreen } = useMemo(() => parseScreensStyles(imageParams, imageClassesParser), [imageParams]);
  const classesToUse = imageParams ? classesByScreen[brkPnt] : classDefault;
  const stylesToUse = imageParams ? stylesByScreen[brkPnt] : null;
  const resolution = stylesToUse?.resolution || 'full';

  let bgUrl = _.isArray(stylesToUse.src) ? stylesToUse.src[0] : stylesToUse.src;
  bgUrl = _.startsWith(bgUrl, 'http') ? bgUrl : getImageURL(bgUrl, resolution, null, instance);
  
  let roundClass = applyClassRound(stylesToUse?.round);

  return (
    <Card params={cardParams} specDesign={specDesign}>
      <img className={`${className} ${roundClass} ${classesToUse}`} src={bgUrl} alt={stylesToUse?.imageAlt} />
    </Card>
  );
}