import _ from 'lodash';
import React, { createRef, Fragment, useRef } from "react";


export const blocksMap = {};
export const blocksDefinitions = {};
export const partsMap = {};

export const getBlockObjects = () => {
  return { blocksMap, blocksDefinitions };
};

export const registerBlocks = (blocks) => {
  blocks.forEach((blockGetDef) => {
    const blockDef = blockGetDef();
    if (!blocksMap[blockDef.type]) {
      blocksMap[blockDef.type] = {};
    }
    blocksMap[blockDef.type][blockDef.variant] = blockDef;
  });
};

export const registerBlockDefinitions = (definitions) => {
  _.forEach(definitions, (definitionDef, type) => {
    blocksDefinitions[type] = definitionDef;
  });
};

export const registerParts = (parts) => {
  parts.forEach((partGetDef) => {
    const partDef = partGetDef();
    partsMap[partDef.part] = partDef;
  });
};

const BlockStack = (props) => { 
  let {
    specDesign,
    specStack,
    staticContext,
    WrapperItem,
    ...propsToUse
  } = props;

  // Crear una referencia única para cada elemento del specStack solo una vez
  const blockRefs = useRef([]);
  if (blockRefs.current.length === 0) {
    blockRefs.current = specStack.map(() => React.createRef());
  }

  let rendered = specStack.map((blockDef, index) => {
    let { id, isBlockShow, type, variant, strings = {}, template, params, ...rest } = blockDef;
    let content;
    const blockRef = blockRefs.current[index]; // Asignar la referencia correspondiente

    if (isBlockShow === false) {
      return null;
    }

    // Custom template
    if (template) {
      content = template({
        id,
        strings,
        ...strings,
        ...propsToUse,
        ...params,
        ...rest,
        specDesign,
        blockRef,
        blockDef,
        specStack
      });
    }
    // Registered block
    else if (blocksMap[type] && blocksMap[type][variant]) {
      blockDef = blocksMap[type][variant];

      // Apply default values
      let paramsValues = { ...params, ...rest };
      _.forEach(blockDef.params, ({ type, defaults }, field) => {
        if (!paramsValues.hasOwnProperty(field)) {
          paramsValues[field] = defaults;
        }
      });

      content = (
        <blockDef.template 
          {...props}
          strings={strings}
          {...strings}
          {...propsToUse}
          {...paramsValues}
          specDesign={specDesign}
          id={id}
          ref={blockRef} // Asignamos la referencia directamente al bloque si se necesita
          blockDef={blockDef}
          specStack={specStack}
        />
      );
    }
    // If block is not found, skip rendering
    else {
      return null;
    }

    // Wrap each item in WrapperItem if defined, passing the blockRef
    return (
      <Fragment key={id}>
        {WrapperItem ? (
          <WrapperItem
            index={index}
            specStack={specStack}
            blockRef={blockRef}
            blockDef={blockDef}
            {...propsToUse}
            specDesign={specDesign}
          >
            {content}
          </WrapperItem>
        ) : content}
      </Fragment>
    );
  });

  return rendered;
};

export default BlockStack;