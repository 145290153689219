import _ from 'lodash';
import { assignDeep, stackClasses } from "../../libs/utils";


export const processRenderProps = (props) => {
  let { 
    fieldName,
    taxonomyType,
    taxonomyTypeDoc,
    values,
    onChange,
    fieldsRequired,
    paramsByFieldSlug,
    isFilter
  } = props;

  const taxonomyTypeData = taxonomyType?.data || taxonomyTypeDoc?.data || taxonomyType;
  const title = taxonomyTypeData?.name;
  const _fieldName = fieldName || taxonomyTypeData?.fieldName || taxonomyTypeData?.nameSlug;
  
  let overrideParams = { ...props.overrideParams };
  if (paramsByFieldSlug && paramsByFieldSlug[_fieldName]) {
    overrideParams = _.clone(overrideParams);
    overrideParams = assignDeep(overrideParams, paramsByFieldSlug[_fieldName]);
  }

  const param = {
    ...taxonomyTypeData,
    ...taxonomyTypeData?.param,
    ...taxonomyTypeData?.style,
    ...overrideParams,
    title,
    fieldsRequired,
    disabled: isFilter ? false : (overrideParams?.disabled || taxonomyTypeData?.readOnly),
    show: taxonomyTypeData?.show
  };
  let style = param?.style || {};
  const design = props?.design || style?.design || param?.design || 'default';
  const entitySlug = param?.entityNameSlug || props?.entitySlug; // retrocompatible
  param.entitySlug = entitySlug;
  param.instance = props.instance || taxonomyTypeData?.instance;
  param.classes = stackClasses(props?.classes, style?.classes, taxonomyTypeData?.classes);
  
  return {
    entitySlug,
    taxonomyTypeData,
    title,
    _fieldName,
    param,
    style,
    design
  }
};