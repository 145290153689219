import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { LayoutAdmin } from "../panel/LayoutAdmin";
import { InstanceInlineSelectorForMain } from './InstanceSelector';
import { usePanel } from '../panel/usePanel';
import BadgeLoading from '../../components/ui/BadgeLoading';
import ContractsList from '../sales/ContractsList';
import dayjs from 'dayjs';
import { ViewField } from '../../components/EntityDocView';
import { objectFormToTaxonomyTypesDocList } from '../entity/ObjectForm';
import config, { getConfig, specBundles } from '../../config';
import { useAsyncMemo } from 'use-async-memo';
import Model from '../../libs/ModelClass';
import { withPrefix } from './utilsInstance';
import { ContractPlanSelectorComplex, createContractsByBundle } from './RouteInstanceModules';
import { IonButton, IonCard, IonCardContent, IonContent, IonFooter, IonHeader, IonModal, IonTitle, useIonAlert, useIonLoading } from '@ionic/react';
import { inputClasses } from '../../components/EntityTaxonomyForm';
import toast from 'react-hot-toast';
import ObjectList from '../entity/ObjectList';
import { addDurationArray, paymentFields } from '../sales/PaymentsList';
import IonContactBTN from '../../components/ui/IonContactBTN';
import IonBtnLoading from '../../components/ui/IonBtnLoading';
import { siteIdentity } from '../../customModules/specSite';


export const paymentInstanceFields = {
  'meta_instanceId': {
    type: 'selectOneEntityDocument',
    name: 'Instancia',
    param: {
      entitySlug: config.modules.instances.instancesEntitySlug
    }
  },
  'meta_serviceDuration': {
    type: 'coded',
    name: 'Duración del servicio',
    RenderShow: ({ doc }) => {
      return (
        <div className="">
          {dayjs().from(addDurationArray(dayjs(), doc.data.meta_serviceDuration), true)}
        </div>
      );
    },
  },
  'meta_maxActivationDate': {
    type: 'date',
    name: 'Activado hasta'
  },
  ...paymentFields,
}

export const useMaxActivation = ({ instance, instanceDoc, settings }) => {
  const [showActivationAlert, setShowActivationAlert] = useState(false);

  const refreser = settings.loadScope(instance);

  const isInstanceActive = useMemo(() => {
    const maxActivationByBundle = settings.get(instance, "bundles.maxActivation");
    if (!maxActivationByBundle && instanceDoc?.data.plan?.bundle === 'store' && instanceDoc?.data.plan?.level === 'essential') {
      return true;
    }
    if (!maxActivationByBundle || !refreser) {
      return true;
    }

    // max activation of main bundle 'store'
    // TODO consider other main bundles
    if (maxActivationByBundle['store']) {
      const isActive = dayjs().isBefore( maxActivationByBundle['store'] );
      if (!isActive) {
        setShowActivationAlert(true);
      }
      return isActive;
    }

    return true;
  }, [instance, instanceDoc, refreser]);

  return {
    isInstanceActive,
    showActivationAlert, setShowActivationAlert
  };
};

export function Content(props) {
  let {
    history,
    parsedParams,
    selectedInstance,
    settings
  } = props;
  const mainBundle = specBundles[0]; // store
  const ownerId = selectedInstance?.data.ownerId;
  const instanceId = selectedInstance?.id;
  const [ contractPlan, setContractPlan ] = useState('monthly');
  const [ bundleLevel, setBundleLevel ] = useState('advanced');
  const [ contractsDocs, setContractsDocs ] = useState();
  const instanceHash = selectedInstance?.data.hash;
  const instanceName = selectedInstance ? `${selectedInstance?.data.name} [${instanceHash}]` : `[${instanceHash}]`;
  const [ presentAlert ] = useIonAlert();
  const loadingCtrl = useIonLoading();
  const [ showContractSelector, setShowContractSelector ] = useState(false);
  const { attachPrefix } = usePanel();

  const paymentsDocs = useAsyncMemo(async () => {
    if (!contractsDocs) {
      return [];
    }
    return await Model.extend(withPrefix('main', getConfig().modules.sales.paymentsEntitySlug))
      .filterByAttributes({
        'meta_instanceId': instanceId,
        deleted: 'false'
      });
  }, [contractsDocs]);
  
  const taxonomyTypesDocList = useMemo(() => {
    return objectFormToTaxonomyTypesDocList({
      'meta_servicePrice': {
        type: 'amount',
        name: 'Cuota'
      },
      'meta_maxActivationDate': {
        type: 'date',
        name: 'Activado hasta'
      }
    });
  }, []);

  useEffect(() => {
    if (parsedParams.openSelector) {
      setShowContractSelector(true);
    }
  }, [parsedParams.openSelector]);

  const queryByInstance = (query) => {
    query['meta_instanceId'] = instanceId;
    return query;
  };

  const getContracts = (docs) => {
    setContractsDocs(docs);
    return docs;
  };

  // create contracts
  const handleBundleContratation = async () => {
    loadingCtrl[0]({
      message: 'Contratando'
    }); // show loading
    try {
      // mark previous contract as done
      for (const contractDoc of contractsDocs) {
        if (contractDoc.data.status === 'active') {
          contractDoc.data.status = 'done';
          await contractDoc.save();
          // // mark pending payments as nulled
          // for (const paymentDoc of paymentsDocs) {
          //   if (paymentDoc.data.contractId === contractDoc.id) {
          //     paymentDoc.data.status = 'nulled';
          //     await paymentDoc.save();
          //   }
          // }
        }
      }
      // create new contract
      let planSpec = {
        bundle: 'store',
        level: bundleLevel,
        plan: contractPlan
      };
      await createContractsByBundle(selectedInstance, planSpec, settings);
      toast.success(`Paquete ${mainBundle.name} contratado en la instancia: ${instanceName}`);
    } catch (error) {
      console.error(error);
      toast.error(`Error al instalar el paquete ${mainBundle.name} en la instancia ${instanceName}`);
    }
    loadingCtrl[1](); // hide loading
    // refresh
    if (parsedParams.openSelector) {
      history.push(attachPrefix(`/a/instancesBilling/update`));
    }
    window.location.reload();
  };

  const openConfirmAlert = () => {
    presentAlert({
      header: 'Confirmar cambio de plan',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'bg-gray-300 text-gray-700'
        },
        {
          text: 'Contratar',
          role: 'confirm',
          handler: () => {
            handleBundleContratation();
          }
        }
      ]
    });
  }

  return (<>
    <div className="m-2 md:m-4 grid grid-flow-row md:grid-flow-col gap-4">
      <div className="basis-1/2 space-y-4">
        {selectedInstance?.data.plan?.plan !== 'free' ? (
          <div className="">
            <IonTitle className={`px-0 ${inputClasses.fieldLabel}`}>
              Activado hasta
            </IonTitle>
            <IonCard className="m-0">
              <IonCardContent className="text-brand-dark text-base font-semibold">
                {dayjs(_.get(settings.get(instanceHash, 'bundles.maxActivation'), 'store'))?.format('dddd, DD MMMM YYYY')}
              </IonCardContent>
            </IonCard>
          </div>
        ) : null}

        {/* mostrar lista de contrataciones de la instancia sin demo */}
        <div>
          <IonTitle className={`px-0 ${inputClasses.fieldLabel}`}>
            Contrataciones
          </IonTitle>
          <ContractsList
            {...props}
            title={false}
            forceAllowed={true}
            instance="main"
            userId={ownerId}
            hardFilter={queryByInstance}
            onAfterRead={getContracts}
            only={['modelId', 'status', 'issuedDate']}
            showEmptyMessage={false}
            ExtraFields={({ doc, classes }) => (
              <>
                <ViewField
                  field="meta_maxActivationDate"
                  doc={doc}
                  classes={classes}
                  taxonomyTypesDocList={taxonomyTypesDocList}
                />
              </>
            )}
          />

          {/* change contract */}
          <IonButton onClick={() => {setShowContractSelector(true); console.log('este', showContractSelector)}} className="mt-4" color="primary" fill="solid" size="small">
            Cambiar plan
          </IonButton>

          <IonModal
            isOpen={showContractSelector}
            onDidDismiss={() => setShowContractSelector(false)}
            backdropDismiss={false}
            swipeToClose={false}
            keyboardClose={false}
            className="wide"
          >
            <IonHeader className="p-2">
              Seleccione el nuevo plan de la instancia
            </IonHeader>
            <IonContent className="ion-padding">
              <ContractPlanSelectorComplex
                bundleSlug={'store'}
                bundleLevel={bundleLevel}
                setBundleLevel={setBundleLevel}
                contractPlan={contractPlan}
                setContractPlan={setContractPlan}
              />
              {/* Logs */}
              {/* <AlertLogger logs={logs} isOpen={isModalOpen} /> */}
            </IonContent>
            <IonFooter className="grid grid-cols-2 gap-4 p-2">
              <IonBtnLoading
                label="Cerrar"
                size="small"
                fill="outline"
                color="light"
                onClick={() => setShowContractSelector(false) }
              />
              <IonBtnLoading
                label="Confirmar"
                size="small"
                fill="solid"
                color="primary"
                onClickAsync={openConfirmAlert}
              />
            </IonFooter>
          </IonModal>
        </div>
      </div>
      <div className="basis-1/2">
        {/* mostrar lista de pagos pendientes */}
        {/* mostrar lista de pagos previos */}
        <IonTitle className={`px-0 ${inputClasses.fieldLabel}`}>
          Lista de Pagos
        </IonTitle>
        <div className="space-y-2">
          <ObjectList {...props}
            design="grid"
            entitySlug={getConfig().modules.sales.paymentsEntitySlug}
            values={paymentsDocs}
            fields={_.pick(paymentFields, ['amount', 'meta_maxActivationDate', 'dueDate', 'issuedDate',  'status'])}
            ExtraFields={({ doc }) => (<>
              {doc?.data.status === 'pending' ? (
                <IonContactBTN
                  number={siteIdentity.whatsappNumber}
                  message={`Hola ${getConfig().siteName}, ajunto el comprobante por este pago. ${getConfig().getURLprefix()}/a/payments/verify/#/id/${doc.id}`}
                  label="Enviar comprobante"
                  color="primary"
                />
              ) : null}
            </>)}      
          />
        </div>
      </div>
    </div>
  </>);
}

export function RouteInstanceBilling(props) {
  const { selectedInstance, settings } = usePanel();
  const [ selectedInner, setSelectedInner ] = useState(selectedInstance);
  const isSettingsLoaded = settings.loadScope(selectedInstance?.data.hash);

  useEffect(() => {
    setSelectedInner(selectedInstance);
  }, [selectedInstance]);

  // show instance selector for Main instance only
  let TitleToolbarRightToUse;
  if (selectedInstance?.data.hash === 'main') {
    TitleToolbarRightToUse = (() => (
      <InstanceInlineSelectorForMain selectedInstance={selectedInner} setSelectedInstance={setSelectedInner} />
    ));
  }

  if (!isSettingsLoaded) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  return (
    <LayoutAdmin 
      history={props.history} 
      breadcrumbs={[{
        title: "Pagos del servicio"
      }]}
      TitleToolbarRight={TitleToolbarRightToUse}
    >
      {!selectedInner ? (
        <div className="m-2 md:m-4">Seleccione una instancia</div>
      ) : (
        <Content {...props} selectedInstance={selectedInner} settings={settings} />
      )}
    </LayoutAdmin>
  );
}
