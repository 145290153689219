import { Link } from 'react-router-dom';
import { stringsMapLayoutBlocks } from '../specSite';
import { PageHeaderHeroStatic } from '../../modules/blockStack/blocks/BlockPageHeaderAHero';
import { PageFooterALinksFixedCta } from '../../modules/blockStack/blocks/BlockPageFooterALinksFixedCta';
import { LayoutHome } from '../../modules/layoutHome/LayoutHome';
import { usePanel } from '../../modules/panel/usePanel';
import { urlPrefix } from '../../modules/instance/utilsInstance';


export function Page401(props) {
  let { match } = props;
  const homePath = urlPrefix(match.params.instance, '/');
  return (
    <LayoutHome metaTitle="401" {...props} >
      {/* <PageHeaderHeroStatic data={stringsMapLayoutBlocks.header} history={history} {...props} /> */}

      <div className="my-24 max-w-lg mx-auto px-10">
        {/* path */}
        <div className="flex mb-5">
          <Link to={'/'}><h2 className="text font-thin mb-4 mr-3">Panel</h2></Link>
          <h2 className="text font-semibold mb-4">404</h2>
        </div>

        <div className="mb-5 text-2xl text-bold">
          La página no existe
        </div>

        <Link to={homePath} className="bg-zinc-200 hover:bg-zinc-300 rounded py-1.5 px-2 text-black text-xs xsm:text-sm">
          Volver
        </Link>
      </div>

      {/* <PageFooterALinksFixedCta data={stringsMapLayoutBlocks.footer} showCTA={true} showMadeWithLove={true} {...props} /> */}
    </LayoutHome>
  );
}

export function Page404Content({ match }) {
  const homePath = urlPrefix(match.params.instance, '/');
  return (
    <div className="my-24 max-w-lg mx-auto px-10">
      {/* path */}
      <div className="flex mb-5">
        <h2 className="text font-thin mb-4 mr-3">Error</h2>
        <h2 className="text font-semibold mb-4">404</h2>
      </div>

      <div className="mb-5 text-2xl text-bold">
        La página no existe
      </div>

      <Link to={homePath} className="bg-zinc-200 hover:bg-zinc-300 rounded py-1.5 px-2 text-black text-xs xsm:text-sm">
        Volver
      </Link>
    </div>
  );
}

export function Page404(props) {
  let { history } = props;
  return (
    <LayoutHome metaTitle="404" {...props} >
      {/* <PageHeaderHeroStatic data={stringsMapLayoutBlocks.header} history={history} {...props} /> */}
      <Page404Content {...props} />
      {/* <PageFooterALinksFixedCta data={stringsMapLayoutBlocks.footer} showCTA={true} showMadeWithLove={true} {...props} /> */}
    </LayoutHome>
  );
}
