import _ from 'lodash';
import config, { getConfig } from "../../../config";
import useBreakpoints from "../../../libs/useBreakpoints";
import { getImageURL, lineBreaks } from "../../../libs/utils";
import { parseScreensStyles, screensField } from './stylesFields';
import { useMemo } from 'react';
import { imageResolutionField, imageSizeField, imageSizesOptions } from './PartImage';
import { applyClassRound, roundField } from './FieldRound';


export const logoFields = {
  'source': { 
    type: 'select',
    name: 'Fuente de imagen',
    options: [{
      value: 'profileImg', label: 'Imagen de perfil'
    }, {
      value: 'logoDark', label: 'Logo de color oscuro'
    }, {
      value: 'logoLight', label: 'Logo de color claro'
    }]
  },
  'size': imageSizeField,
  'resolution': imageResolutionField,
  'shape': {
    type: 'select',
    name: 'Forma del logo',
    options: [{
      value: 'transparent', label: 'Transparente'
    }, {
      value: 'square', label: 'Cuadrado'
    }]
  },
  'round': roundField,
  'showSiteName': {
    type: 'boolean',
    name: 'Mostrar nombre del sitio',
    defaultValue: false
  },
  'siteNameColor': {
    type: 'color',
    name: 'Color del nombre del sitio'
  }
};

// use requires usePartLogo
export default function () {
  return {
    part: 'logo',
    name: 'Estilos del logo',
    template: PartLogo,
    type: 'collection',
    fields: {
      'screen': screensField,
      ...logoFields
    }
  };
};

export const usePartLogo = (props) => {
  let {
    params = {},
    specDesign,
    instance
  } = props;
  let {
    source,
    size,
    resolution
  } = params || {};

  let path = specDesign?.logo?.[source] || '';
  path = _.isArray(path) ? path[0] : path;
  path = getImageURL(path, resolution, 'profilesImages', instance);

  return {
    source,
    path,
    size
  }
};

const getContainerClasses = ({ shape, size, round }) => {
  let className = '';
  // style
  
  // size
  if (size) {
    className += ` h-${size} min-h-${size}`;
  }

  // shape square sizes
  if (shape === 'square') {
    className += ` w-${size} min-w-${size}`;
  }
  
  // shape transparent

  // rounded
  className = applyClassRound(round, className);
  
  return className;
};

export function PartLogo (props) {
  let {
    params,
    classes = {},
    specDesign,
    instance
  } = props;
  
  const { brkPnt } = useBreakpoints();
  const { stylesByScreen } = useMemo(() => params && parseScreensStyles(params), [params]) || {};
  let paramsToUse = stylesByScreen?.[brkPnt] || {};
  let { showSiteName, siteNameColor, source, resolution } = paramsToUse;

  let siteName = specDesign?.stringsVars?.brand?.siteName || getConfig().siteName;
  
  let path = specDesign?.logo?.[source] || '';
  path = _.isArray(path) ? path[0] : path;
  if (_.startsWith(path, '/')) {
    path = `${getConfig().getURLprefix()}/${path}`;
  }
  else if (source === 'profileImg') {
    path = getImageURL(path, resolution, 'profilesImages', instance);
  }
  else if (source === 'logoDark' || source === 'logoLight') {
    path = getImageURL(path, null, 'profilesImages', instance);
  }

  const colorsMap = props.colors || specDesign?.colors || config?.colors || {};

  const getColorHex = (val) => {
    return val?.hex || colorsMap[val] || val;
  };

  let stylesToUse = {
    color: getColorHex(siteNameColor)
  };
  
  return (
    <div className="">
      <div className={`mx-auto flex flex-row items-center ${classes.logoContainer || ''}`}>
        <img src={path} className={`object-cover ${classes.logoIcon || ''} ${getContainerClasses(paramsToUse)}`} />
        {showSiteName ? (
          <h2 className="ml-4 font-bold font-brand-main text-lg xs:text-xl sm:text-2xl" style={stylesToUse}>{lineBreaks(siteName, false)}</h2>
        ) : null}
      </div>
    </div>
  );
}

export function LayoutLogo (props) {
  return (
    <PartLogo {...props} />
  );
}