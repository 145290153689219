import { useState } from "react";
import { AnimationAddClassWhenVisible } from "../../../components/ui/AnimationAddClassWhenVisible";
import { 
  FaQuoteLeft,
  FaArrowLeft,
  FaArrowRight
} from "react-icons/fa";
import { lineBreaks } from "../../../libs/utils";
import { Background } from "../parts/PartBackground"; // Importa el componente Background
import { Text } from "../parts/PartText";
import { Card } from "../parts/PartCard";
import { CardContainer } from "../parts/PartCardContainer";
import { cardContainerStylesField } from "../parts/stylesFields";
import { getConfig } from '../../../config';


export default function () {
  return {
    type: 'reviews',
    variant: 'grid',
    template: BlockReviewsAGrid,
    presets,
    params: {
      'bgStyles': { // Añade el parámetro bgStyles
        name: 'Fondo',
        type: 'part',
        part: 'background'
      },
      'cardContainerStyles': cardContainerStylesField,
      // colores de elementos quote y arrow
      'quoteColor': {
        type: 'color',
        name: 'Color de las comillas',
      },
      'arrowColor': {
        type: 'color',
        name: 'Color de las flechas habilitadas',
      },
      'arrowColorDisabled': {
        type: 'color',
        name: 'Color de las flechas deshabilitadas',
      },
      // intro
      'introCardStyles': {
        name: 'Tarjeta de introducción',
        type: 'part',
        part: 'card'
      },
      'introTitleStyles': {
        name: 'Intro: Título del bloque',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      'introTitleCardStyles': {
        name: 'Intro: Tarjeta del título del bloque',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },
      'introSubtitleStyles': {
        name: 'Intro: Subtítulo del bloque',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      'introSubtitleCardStyles': {
        name: 'Intro: Tarjeta del subtítulo del bloque',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },
      'introContentStyles': {
        name: 'Intro: Estilos del contenido del bloque',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      'introContentCardStyles': {
        name: 'Intro: Tarjeta del contenido del bloque',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },

      // items
      'reviewCardStyles': { // Estilos para la tarjeta de cada reseña
        name: 'Tarjeta individual de cada reseña',
        type: 'part',
        part: 'card'
      },
      'titleStyles': { // Estilos para el título de cada reseña
        name: 'Reseña: Título de cada reseña',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      'titleCardStyles': { // Estilos para la tarjeta del título de cada reseña
        name: 'Reseña: Tarjeta del título de cada reseña',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },
      'contentStyles': { // Estilos para el contenido
        name: 'Contenido de cada reseña',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      'contentCardStyles': { // Estilos para la tarjeta del contenido de cada reseña
        name: 'Reseña: Tarjeta del contenido de cada reseña',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      }
    },
    // fields of text tab
    strings: {
      title: {
        type: 'textArea',
        name: 'Título'
      },
      subtitle: {
        type: 'textArea',
        name: 'Subtítulo'
      },
      introContent: {
        type: 'textArea',
        name: 'Contenido de la introducción',
      },
      
      // {items: [{title,content}]}
      'items': {
        type: 'collection',
        name: 'Reseñas',
        fields: {
          'title': {
            type: 'textArea',
            name: 'Título'
          },
          'content': {
            type: 'textArea',
            name: 'Testimonio'
          },
        }
      },
    },
    partsMap: [
      {
        'label': 'Bloque',
        'background': ['bgStyles'],
        'card': ['cardContainerStyles']
      },
      {
        'label': 'Introducción',
        'card': ['introCardStyles']
      },
      {
        'label': 'Título de introducción',
        'strings': ['title'],
        'text': ['introTitleStyles'],
        'card': ['introTitleCardStyles']
      },
      {
        'label': 'Subtítulo de introducción',
        'strings': ['subtitle'],
        'text': ['introSubtitleStyles'],
        'card': ['introSubtitleCardStyles']
      },
      {
        'label': 'Contenido de introducción',
        'strings': ['introContent'],
        'text': ['introContentStyles'],
        'card': ['introContentCardStyles']
      },
      {
        'label': 'Reseñas',
        'strings': ['items'],
        'card': ['reviewCardStyles']
      },
      {
        'label': 'Título de reseña',
        'text': ['titleStyles'],
        'card': ['titleCardStyles']
      },
      {
        'label': 'Contenido de reseña',
        'text': ['contentStyles'],
        'card': ['contentCardStyles']
      },
      {
        'label': 'Color de comillas y Flechas',
        'strings': ['quoteColor', 'arrowColor', 'arrowColorDisabled'],
      },
    ]
  };
};

export function BlockReviewsAGrid(props) {
  const { 
    title,
    subtitle,
    introContent,
    items = [],
    bgStyles,
    arrowColor,
    arrowColorDisabled,
    quoteColor,
    cardContainerStyles,
    introCardStyles,
    introTitleStyles,
    introTitleCardStyles,
    introSubtitleStyles,
    introSubtitleCardStyles,
    introContentCardStyles,
    introContentStyles,
    specDesign,
    reviewCardStyles,
    titleStyles,
    titleCardStyles,
    contentStyles,
    contentCardStyles
  } = props;
  const [startIndex, setStartIndex] = useState(0);
  const testimonialsPerPage = 2;
  const totalPages = Math.ceil(items.length / testimonialsPerPage);

  const handleNext = () => {
    const newIndex = startIndex + testimonialsPerPage;
    setStartIndex(newIndex >= items.length ? 0 : newIndex);
  };

  const handlePrevious = () => {
    const newIndex = startIndex - testimonialsPerPage;
    setStartIndex(newIndex < 0 ? items.length - testimonialsPerPage : newIndex);
  };
  
  let colorsMap = props.colors || specDesign.colors || getConfig().colors || {};
  const getColorHex = (val) => {
    return val?.hex || colorsMap[val] || val;
  };
  let colorText = getColorHex(quoteColor) || getColorHex('primary');
  let quoteTextColor = getColorHex(quoteColor) || colorText;

  let colorArrow = getColorHex(arrowColor) || getColorHex('primary');
  let arrowEnabledColor = getColorHex(arrowColor) || colorArrow;

  let colorArrowDisabled = getColorHex(arrowColorDisabled) || getColorHex('secondary');
  let disabledArrowColor = getColorHex(arrowColorDisabled) || colorArrowDisabled;

  return (
    <Background params={bgStyles} classes={{ bgContainer: "snap-start" }} specDesign={specDesign}> {/* Envuelve el contenido con Background */}
      {/* <div className="container-width space-y-24"></div> */}
      <CardContainer params={cardContainerStyles} specDesign={specDesign}>
        {/* Titles */}
        {title || subtitle || introContent ? (
          <AnimationAddClassWhenVisible classToAdd="animate-fadeIn" classDefault="duration-1000 delay-[100ms]">
            <Card params={introCardStyles} specDesign={specDesign} classes={{ cardContainer: "space-y-2" }}>
              {title ? (
                <Card params={introTitleCardStyles} specDesign={specDesign}>
                  <Text textParams={introTitleStyles} classDefault="text-4xl md:text-4xl font-brand-main font-bold" specDesign={specDesign}>
                    {lineBreaks(title)}
                  </Text>
                </Card>
              ) : null}
              {subtitle ? (
                <Card params={introSubtitleCardStyles} specDesign={specDesign}> 
                  <Text textParams={introSubtitleStyles} classDefault="text-lg lg:text-xl font-brand-main text-gray-500 md:mb-2" specDesign={specDesign}>
                    {lineBreaks(subtitle)}
                  </Text>
                </Card>
              ) : null}
              {introContent ? (
                <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
                  <Card params={introContentCardStyles} specDesign={specDesign}>
                    <Text textParams={introContentStyles} specDesign={specDesign}>
                      {lineBreaks(introContent)}
                    </Text>
                  </Card>
                </AnimationAddClassWhenVisible>
              ) : null}
            </Card>
          </AnimationAddClassWhenVisible>
        ) : null}

        {/* Testimonials */}
        <AnimationAddClassWhenVisible classToAdd="animate-fadeIn" classDefault="duration-1000 delay-[300ms]">
          <div className="mt-8 md:mt-6">
            <div className={`grid gap-6 ${totalPages > 1 ? 'lg:grid-cols-2' : ''}`}>
              {items?.length > 0 && items.slice(startIndex, startIndex + testimonialsPerPage).map((item, index) => (
                <Card key={index} params={reviewCardStyles} specDesign={specDesign}>
                  <div className="flex items-center leading-tight mb-2">
                    <FaQuoteLeft className="text-xl mr-2.5 w-7" style={{ color: quoteTextColor }} />
                    {item.title && (
                      <Card params={titleCardStyles} specDesign={specDesign}>
                        <Text textParams={titleStyles} classDefault="text-lg 2xl:text-xl font-semibold font-brand-main text-brand-primary" specDesign={specDesign}>
                          {lineBreaks(item.title)}
                        </Text>
                      </Card>
                    )}
                  </div>
                  {item.content && (
                    <Card params={contentCardStyles} specDesign={specDesign} className="font-brand-secondary">
                      <Text textParams={contentStyles} classDefault="text-base" specDesign={specDesign}>
                        {lineBreaks(item.content)}
                      </Text>
                    </Card>
                  )}
                </Card>
              ))}
            </div>
            {totalPages > 1 && (
              <div className="flex justify-center gap-4 text-xl mt-8">
                <button
                  onClick={handlePrevious}
                  disabled={startIndex === 0}
                  style={{ color: startIndex === 0 ? disabledArrowColor : arrowEnabledColor }}
                >
                  <FaArrowLeft />
                </button>
                <button
                  onClick={handleNext}
                  disabled={startIndex >= items.length - testimonialsPerPage}
                  style={{ color: startIndex >= items.length - testimonialsPerPage ? disabledArrowColor : arrowEnabledColor }}
                >
                  <FaArrowRight />
                </button>
              </div>
            )}
          </div>
        </AnimationAddClassWhenVisible>
      </CardContainer>
    </Background>
  );
}
const presets = {
  'base': {
    "bgStyles": [
      {
        "bgColor": "#ffffff",
        "screen": "xxs",
        "bgVideoUrl": "",
        "bgOverlayAlpha": 0,
        "type": "color"
      }
    ],
    "cardContainerStyles": [
      {
        "screen": "xxs",
        "marginX": 4,
        "marginY": 24,
        "cardWidth": 'full'
      },
      {
        "screen": "md",
        "marginX": 16,
        "marginY": 32,
      },
      {
        "padding": 40,
        "screen": "lg",
        "marginY": 40,
      },
      {
        "screen": "xl",
        "marginX": 72,
        "marginY": 40,
      },
      {
        "screen": "2xl",
        "marginX": 72,
        "marginY": 56,
      }
    ],
    "introTitleStyles": [
      {
        "screen": "xxs",
        "font": "main",
        "fontVariations": [
          "bold"
        ],
        "textAlign": "left",
        "textSize": "3xl"
      },
      {
        "screen": "md",
      },
      {
        "screen": "lg",
        "textSize": "4xl"
      },
      {
        "textSize": "5xl",
        "screen": "2xl"
      }
    ],
    "titleStyles": [
      {
        "textSize": "base",
        "fontVariations": [
          "bold"
        ],
        "textAlign": "left",
        "screen": "xxs",
        "textColor": "#000000",
        "font": "secondary"
      },
      {
        "textSize": "lg",
        "screen": "md"
      },
      {
        "textSize": "xl",
        "screen": "lg"
      },
      {
        "textSize": "2xl",
        "screen": "2xl"
      }
    ],
    "contentStyles": [
      {
        "textAlign": "left",
        "textColor": "#000000",
        "font": "secondary",
        "textSize": "base",
        "screen": "xxs",
        "fontVariations": [
          "italic"
        ],
      }
    ],
    "arrowColor": "primary",
    "arrowColorDisabled": "#94a3b8",
  },
  'base-details': {
    "bgStyles": [
      {
        "bgColor": "#ffffff",
        "screen": "xxs",
        "bgVideoUrl": "",
        "bgOverlayAlpha": 0,
        "type": "color"
      }
    ],
    "cardContainerStyles": [
      {
        "screen": "xxs",
        "marginX": 12,
        "marginY": 24,
        "cardWidth": 'full'
      },
      {
        "screen": "md",
        "marginX": 16,
        "marginY": 32,
      },
      {
        "screen": "lg",
        "padding": 40,
        "marginY": 40,
      },
      {
        "screen": "xl",
        "marginX": 72,
        "marginY": 40,
      },
      {
        "screen": "2xl",
        "marginX": 72,
        "marginY": 56,
      }
    ],
    "introSubtitleStyles": [
      {
        "font": "secondary",
        "screen": "xxs",
        "textSize": "base",
        "fontVariations": [
          "normal",
          "underline"
        ],
        "textAlign": "left"
      },
      {
        "textAlign": "left",
        "screen": "md"
      }
    ],
    "introTitleStyles": [
      {
        "screen": "xxs",
        "textColor": "primary",
        "font": "main",
        "fontVariations": [
          "bold"
        ],
        "textAlign": "left",
        "textSize": "3xl"
      },
      {
        "screen": "md",
        "textAlign": "left"
      },
      {
        "screen": "lg",
        "textSize": "4xl"
      },
      {
        "textSize": "5xl",
        "screen": "2xl"
      }
    ],
    "titleStyles": [
      {
        "fontVariations": [
          "bold"
        ],
        "textSize": "base",
        "textColor": "#000000",
        "font": "secondary",
        "textAlign": "left",
        "screen": "xxs"
      },
      {
        "textSize": "lg",
        "screen": "md"
      },
      {
        "textSize": "xl",
        "screen": "lg"
      },
      {
        "textSize": "2xl",
        "screen": "2xl"
      }
    ],
    "contentStyles": [
      {
        "textAlign": "left",
        "textColor": "#000000",
        "font": "secondary",
        "textSize": "base",
        "screen": "xxs",
        "fontVariations": [
          "italic"
        ],
      }
    ],
    "arrowColor": "primary",
    "arrowColorDisabled": "secondary",
  },
};


