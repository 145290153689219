import { useEffect } from "react";
import { useLocation, Route, Switch } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Capacitor } from "@capacitor/core";

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import duration from "dayjs/plugin/duration";
import relativeTime from 'dayjs/plugin/relativeTime';
import "dayjs/locale/es";

import { addLogEvent } from "./firebase";
import { ModuleProvider } from "./libs/ModuleContext";
import { AuthProvider } from "./modules/user/AuthContext";
import { RouteHomeRender } from "./customModules/pages/RouteHomeRender";
import { RoutePublicModulePage } from "./customModules/layout/RoutePublicModulePage";
import { ScrollUtilsProvider } from "./modules/panel/useScrollUtils";
import { DesignProvider, DesignLoader } from "./modules/blockStack/DesignLoader";
// import { Register } from "./routes/Register";
import { Login } from "./modules/user/Login";

import { AppPanelWrappedAsync } from "./AppPanelWrappedAsync";
import { LayoutHomeProvider } from "./modules/layoutHome/useLayoutHome";
import { PartsClasses } from "./modules/blockStack/definitionsParts";
import { SettingsLoader, SettingsProvider } from "./modules/panel/useSettings";
import { useHashParams } from "./libs/useHashParams";

////////////////////////////////////////////////////////////////
// FOR ONE USE ONLY
////////////////////////////////////////////////////////////////
import { Install } from "./modules/panel/Install";
////////////////////////////////////////////////////////////////


setupIonicReact();

dayjs.extend(isSameOrBefore)
dayjs.extend(utc);
dayjs.extend(localizedFormat)
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.locale('es');

function LogLocation ({ children }) {
  let location = useLocation();

  // log page
  useEffect(() => {
    setTimeout(() => {
      addLogEvent('page_view', {
        // page_location: location.href,
        page_path: location.pathname,
        page_title: document.title,
      }); // analytics
    }, 1000);
  }, [location]);

  return children;
}

const HomeRender = props => {
  const parsedParams = useHashParams();

  if (Capacitor.isNativePlatform()) {
    return <Login {...props} parsedParams={parsedParams} />
  }
  else {
    return (
      <RouteHomeRender {...props} parsedParams={parsedParams} />
    );
  }
};

// Instances
function AppPublicInstance () {
  return (<>
    <Route path={`/:instance`} exact component={HomeRender} />
    <Route path={[`/:instance/m/:entitySlug/:action`, `/:instance/m/:entitySlug/:action/*`]} component={RoutePublicModulePage} />
  </>);
}

function App() {
  return (
    <div className="text-black h-screen flex">
      <IonApp>
        <AuthProvider>
        <ModuleProvider>
        <LayoutHomeProvider>
        <ScrollUtilsProvider>
        <LogLocation>
        <SettingsProvider>
        <DesignProvider>
        <IonReactRouter>
          <IonRouterOutlet>
            <Switch>

              {/* Install */}
              <Route path="/install" exact={true} render={props => (<>
                <SettingsLoader {...props}>
                  <DesignLoader {...props}>
                    <Route path={`/install`} exact={true} component={Install} />
                  </DesignLoader>
                </SettingsLoader>
              </>)}/>

              {/* Login */}
              <Route path={["/login", "/:instance/login"]} exact={true} component={Login} />

              {/* Panel */}
              <Route path={["/a", "/:instance/a"]} component={AppPanelWrappedAsync} />

              {/* Public Main module pages */}
              <Route path={[`/m/:entitySlug/:action`, `/m/:entitySlug/:action/*`]} render={props => (<>
                <SettingsLoader {...props}>
                <DesignLoader {...props}>
                  <Route path={`/m/:entitySlug/:action`} component={RoutePublicModulePage} />
                  <Route path={`/m/:entitySlug/:action/*`} component={RoutePublicModulePage} />
                </DesignLoader>
                </SettingsLoader>
              </>)}/>

              {/* Public Instance */}
              <Route path="/:instance" render={props => (<>
                <SettingsLoader {...props}>
                <DesignLoader {...props}>
                  <AppPublicInstance />
                </DesignLoader>
                </SettingsLoader>
              </>)}/>

              {/* Home */}
              <Route path="/" exact={true} render={props => (<>
                <SettingsLoader {...props}>
                <DesignLoader {...props}>
                  <Route path={`/`} exact={true} component={HomeRender} />
                </DesignLoader>
                </SettingsLoader>
              </>)}/>

            </Switch>
          </IonRouterOutlet>
        </IonReactRouter>
        </DesignProvider>
        </SettingsProvider>
        </LogLocation>
        </ScrollUtilsProvider>
        </LayoutHomeProvider>
        </ModuleProvider>
        </AuthProvider>
      </IonApp>
      <Toaster
        toastOptions={{
          duration: 8000,
          position: "bottom-center",
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
            fontFamily: 'Lato'
          }
        }}
      />
      <div className="hidden">
        <div className="
          bg-gray-300 text-gray-300 bg-blue-700 ring-blue-700 border-blue-700 text-blue-700 bg-amber-600 ring-amber-600 border-amber-600 text-amber-600 bg-stone-400 text-stone-400
          opacity-80 cursor-not-allowed
          
          !basis-full !basis-1 !basis-2 !basis-3 !basis-4 !basis-5 !basis-6 !basis-7 !basis-8 !basis-9 !basis-10 !basis-11 !basis-12 !basis-0 !basis-1/12 
          !basis-2/12 !basis-3/12 !basis-4/12 !basis-5/12 !basis-6/12 !basis-7/12 !basis-8/12 !basis-9/12 !basis-10/12 !basis-11/12 !basis-1/4 !basis-1/3 !basis-1/2 !basis-2/3 !basis-3/4
          !flex-grow-0 
          
          basis-full basis-1 basis-2 basis-3 basis-4 basis-5 basis-6 basis-7 basis-8 basis-9 basis-10 basis-11 basis-12
          basis-0 basis-1/12 basis-2/12 basis-3/12 basis-4/12 basis-5/12 basis-6/12 basis-7/12 basis-8/12 basis-9/12 basis-10/12 basis-11/12 basis-1/4 basis-1/3 basis-1/2 basis-2/3 basis-3/4
          flex-grow-0

          grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 grid-cols-5 grid-cols-6 grid-cols-7 grid-cols-8 grid-cols-9 grid-cols-10 grid-cols-11 grid-cols-12
          md:grid-cols-1 md:grid-cols-2 md:grid-cols-3 md:grid-cols-4 md:grid-cols-5 md:grid-cols-6
          lg:grid-cols-1 lg:grid-cols-2 lg:grid-cols-3 lg:grid-cols-4 lg:grid-cols-5 lg:grid-cols-6

          row-span-1 row-span-2 row-span-3 row-span-4 row-span-5 row-span-6 row-span-7 row-span-8 row-span-9 row-span-10 row-span-11 row-span-12
          md:row-span-1 md:row-span-2 md:row-span-3 md:row-span-4 md:row-span-5 md:row-span-6 md:row-span-7 md:row-span-8 md:row-span-9 md:row-span-10 md:row-span-11 md:row-span-12
          lg:row-span-1 lg:row-span-2 lg:row-span-3 lg:row-span-4 lg:row-span-5 lg:row-span-6 lg:row-span-7 lg:row-span-8 lg:row-span-9 lg:row-span-10 lg:row-span-11 lg:row-span-12

          col-span-1 col-span-2 col-span-3 col-span-4 col-span-5 col-span-6 col-span-7 col-span-8 col-span-9 col-span-10 col-span-11 col-span-12
          md:col-span-1 md:col-span-2 md:col-span-3 md:col-span-4 md:col-span-5 md:col-span-6 md:col-span-7 md:col-span-8 md:col-span-9 md:col-span-10 md:col-span-11 md:col-span-12
          lg:col-span-1 lg:col-span-2 lg:col-span-3 lg:col-span-4 lg:col-span-5 lg:col-span-6 lg:col-span-7 lg:col-span-8 lg:col-span-9 lg:col-span-10 lg:col-span-11 lg:col-span-12
        "></div>
        <PartsClasses />
      </div>
    </div>
  );
}

export default App;
