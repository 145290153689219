import _ from 'lodash';
import useBreakpoints from "../../../libs/useBreakpoints";
import { useMemo } from 'react';
import config from '../../../config';
import { parseScreensStyles, screensField } from './stylesFields';
import { BackgroundStatic } from './PartBackground';
import { cardStylesFields, cardWidthField } from './PartCard';
import { applyClassRound } from './FieldRound';
import { applyClassHeight, blockHeightField } from './FieldHeight';

export const cardAlignXOptions = [{ value: 'left', label: 'Izquierda' }, { value: 'center', label: 'Centro' }, { value: 'right', label: 'Derecha' }];
export const cardAlignYOptions = [{ value: 'top', label: 'Arriba' }, { value: 'center', label: 'Centro' }, { value: 'bottom', label: 'Abajo' }];
export const cardAlignXField = {
  type: 'select',
  name: 'Alineación horizontal',
  options: cardAlignXOptions,
  design: 'buttons'
};
export const cardAlignYField = {
  type: 'select',
  name: 'Alineación vertical',
  options: cardAlignYOptions,
  design: 'buttons'
};


export const cardContainerStylesFields = {
  ..._.omit(cardStylesFields, ['width', 'align']),
  'cardWidth': {
    ...cardWidthField,
    name: 'Ancho de la tarjeta'
  },
  'blockHeight': blockHeightField,
  'alignX': cardAlignXField,
  'alignY': cardAlignYField
};

export default function () {
  return {
    part: 'cardContainer',
    name: 'Estilos de tarjeta',
    template: CardContainer,
    type: 'collection',
    fields: {
      'screen': screensField,
      ...cardContainerStylesFields
    }
  };
};

// Función para obtener los colores
const getColorHex = (val, colorsMap) => val?.hex || colorsMap[val] || val;

// Función para obtener el padding
const getPaddingClass = (val) => val && val !== 'none' ? `p-${val}` : '';

export const cardContainerClassesParser = (props = {}, colorsMap = {}) => {
  const {
    border = '',
    padding = '',
    borderColor = '',
    round = '',
    shadow = '',
    marginY = '',
    marginX = '',
    cardWidth = '',
    blockHeight = '',
    alignX = 'center', // Alineación horizontal
    alignY = 'center', // Alineación vertical
  } = props;

  // Clases
  const borderClass = border === '1' ? 'border' : border !== 'none' ? `border-${border}` : '';
  const marginYClass = marginY ? `my-${marginY}` : '';
  const marginXClass = marginX ? `mx-${marginX}` : '';
  const widthClass = cardWidth ? `w-${cardWidth}` : '';
  const shadowClass = shadow ? `shadow-${shadow}` : '';
  const alignXClass = alignX === 'center' ? 'justify-center' :
                      alignX === 'right' ? 'justify-end' :
                      alignX === 'left' ? 'justify-start' : '';
  const alignYClass = alignY === 'center' ? 'items-center' :
                      alignY === 'top' ? 'items-start' :
                      alignY === 'bottom' ? 'items-end' : '';
  const alignClass = `flex ${alignXClass} ${alignYClass}`;
  const paddingClass = getPaddingClass(padding);
  const roundClass = applyClassRound(round);
  const heightClass = applyClassHeight(blockHeight);

  // Estilos
  const cardStyles = {
    borderColor: getColorHex(borderColor, colorsMap),
  };

  const inCardStyles = {};

  return {
    outCard: `${alignClass} ${heightClass}`,
    card: `${widthClass} ${roundClass} ${borderClass} ${marginYClass} ${marginXClass} ${shadowClass}`,
    inCard: `${paddingClass}`,
    cardStyles,
    inCardStyles
  };
};

export function CardContainer(props) {
  const {
    params,
    specDesign,
    classDefault = '',
    className = '',
    classes = {},
    children
  } = props;

  const { brkPnt } = useBreakpoints();

  // Colores y diseño específico
  const colorsMap = props.colors || specDesign.colors || config?.colors || {};

  // Parseamos clases y estilos según los params y breakpoint
  const { classesByScreen, stylesByScreen } = useMemo(() => parseScreensStyles(params, (p) => cardContainerClassesParser(p, colorsMap)), [params]);

  // Clases y estilos resultantes del parser
  const { outCard, card, inCard, cardStyles, inCardStyles } = params ? classesByScreen[brkPnt] : classDefault;

  const bgParams = stylesByScreen[brkPnt]?.bgParams || {};

  // Clases y estilos a usar
  const outCardClasses = `
    ${className || ''}
    ${classes.cardContainer || ''}
    ${outCard || ''}
  `;

  const cardClasses = `
    ${classes.card || ''}
    ${classDefault || ''}
    ${card || ''}
  `;

  const inCardClasses = `
    ${classes.inCard || ''}
    ${inCard || ''}
  `;

  return (
    <div className={outCardClasses}>
      <BackgroundStatic
        params={bgParams}
        classes={{ bgContainer: cardClasses }}
        styles={{ bgContainer: cardStyles }}
        specDesign={specDesign}
      >
        <div className={inCardClasses} style={inCardStyles}>
          {children}
        </div>
      </BackgroundStatic>
    </div>
  );
};

export const cardContainerClasses = `
`;