import { createContext, useContext, useState } from "react";


const layoutHomeContext = createContext();

export const useLayoutHome = () => {
  const context = useContext(layoutHomeContext);
  if (!context) throw new Error("There is no LayoutHome provider");
  return context;
};

export function LayoutHomeProvider({ children }) {
  const [ offsetBottom, setOffsetBottom ] = useState(false);

  return (
    <layoutHomeContext.Provider
      value={{
        offsetBottom, setOffsetBottom
      }}
    >
      {children}
    </layoutHomeContext.Provider>
  );
}
