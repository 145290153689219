import _ from 'lodash';
import { ContractPlanSelectorComplex, useContractDetails } from './RouteInstanceModules';
import { specBundles } from '../../config';
import { useEffect, useState } from 'react';


const SubscribedPlan = (props) => {
  let { 
    instance,
    entitySlug,
    fieldName,
    value,
    onChange,
    stackType,
  } = props;
  const mainBundle = specBundles[0]; // store

  const [ bundleLevel, setBundleLevel ] = useState();
  const [ contractPlan, setContractPlan ] = useState();
  const contractsDetailsByBundle = useContractDetails({ bundleSlug: mainBundle.slug });

  useEffect(() => {
    if (contractsDetailsByBundle && bundleLevel && contractPlan) {
      let contractModelId;
      if (bundleLevel === 'essential') {
        contractModelId = contractsDetailsByBundle[bundleLevel]['free'].id;
      } else {
        contractModelId = contractsDetailsByBundle[bundleLevel][contractPlan].id;
      }
      onChange({ bundle: 'store', level: bundleLevel, plan: contractPlan, contractModelId });
    }
  }, [contractsDetailsByBundle, bundleLevel, contractPlan]);

  useEffect(() => {
    if (value && value.level && value.plan) {
      setBundleLevel(value.level);
      setContractPlan(value.plan);
    }
    if (!value) {
      setBundleLevel('essential');
      setContractPlan('monthly');      
    }
  }, [value]);

  return (
    <div className="rounded-md p-2 md:p-4 shadow-lg border border-gray-300 bg-white">
      <ContractPlanSelectorComplex
        bundleSlug="store"
        bundleLevel={bundleLevel}
        setBundleLevel={setBundleLevel}
        contractPlan={contractPlan}
        setContractPlan={setContractPlan}
        showDemo={true}
        showEssential={true}
      />
    </div>
  );
};

const dataTypeSubscribedPlan = {
  RenderForm: SubscribedPlan
};

export default dataTypeSubscribedPlan;