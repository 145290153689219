import {
  IonPage,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonBackButton
} from "@ionic/react";
import { 
  arrowBack
} from 'ionicons/icons';


export function LayoutPublic({
  Title,
  mainSectionClass,
  ToolbarLeft,
  defaultHref,
  forceBack = false,
  showGoBack = true,
  children,
  history
}) {
  return (
    <IonPage>
      {/* header */}
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {showGoBack ? (
              forceBack ? (
                <IonButton onClick={()=> { history.push(defaultHref); }} >
                  <IonIcon slot="icon-only" icon={arrowBack} />
                </IonButton>
              ) : (
                <IonBackButton defaultHref={defaultHref}></IonBackButton>
              )
            ) : null}
            {ToolbarLeft ? (
              <ToolbarLeft />
            ) : null}
          </IonButtons>

          <div className="header-title-logo absolute !top-0 !left-0 w-full items-center">
            <Title />
          </div>

        </IonToolbar>
      </IonHeader>

      {/* content */}
      <IonContent className="">
        {children}
      </IonContent>
    </IonPage>
  );
}