import { IonButton, IonIcon } from "@ionic/react";
import { useState } from "react";


export default function IonBtnLoading({ 
  label,
  icon,
  ionIcon,
  onClick,
  onClickAsync,
  className,
  classNameDisabled,
  type = 'button',
  color,
  size,
  iconSize,
  fill,
  disabled = false,
  onClickDisabled
 }) {
  const [loading, setLoading] = useState(false);

  const onClickWrapper = async () => {
    if (disabled) {
      onClickDisabled && onClickDisabled();
      return;
    }
    if (onClick) {
      onClick();
    } 
    else if (!loading && onClickAsync) {
       setLoading(true);
       await onClickAsync();
       setLoading(false);
     }
  };

  let iconToUse = icon;
  if (ionIcon) {
    iconToUse = <IonIcon slot="start" icon={ionIcon} size={iconSize || size} />;
  }
  if (loading) {
    iconToUse = (
      <svg className={`animate-spin ${size === 'small' ? 'h-5 w-5' : ''} ${size === 'medium' ? 'h-5 w-5' : ''} ${size === 'large' ? 'h-7 w-7' : ''}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    );
  }

  return (
    <IonButton
    {...{
      type, color, size, fill
    }}
    className={`
      ${ disabled ? (classNameDisabled || className) : className || ''}
    `}
    disabled={disabled || loading}
    onClick={onClickWrapper}>
      {iconToUse ? (
        <div slot="start" className="min-w-6 text-center mr-1">
          {iconToUse}
        </div>
      ) : null}
      {label || null}
    </IonButton>
  );
}