import React, { useEffect, useState } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import config from '../../config';
import toast from 'react-hot-toast';


export const getCurrentPosition = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) { 
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          resolve(currentPosition);
        },
        (error) => {
          console.error(error);
          reject(error);
        }
      );
    } else {
      const error = new Error('La geolocalización no es compatible en este navegador.');
      console.error(error);
      reject(error);
    }
  });
};

export const RawInputGPSWithPlaces = (props) => {
  const { value, onChange, Render, ...rest } = props;
  const [place, setPlace] = useState(null);
  const [storedValue, setStoredValue] = useState();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.googleMapApiKey,
    libraries: ['places'],
  });

  useEffect(() => {
    if (value && !storedValue) {
      setStoredValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (storedValue && isLoaded) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: storedValue }, (results, status) => {
        if (status === 'OK' && results[0]) {
          setPlace(results[0]);
          if (storedValue) {
            let { address_components, formatted_address, place_id } = results[0];
            onChange({ ...storedValue, place: { address_components, formatted_address, place_id } });
          }
        } else {
          setPlace(null);
        }
      });
    } else {
      setPlace(null);
    }
  }, [storedValue, isLoaded]);

  if (!isLoaded) {
    return null;
  }

  if (Render) {
    return (
      <Render
        value={storedValue}
        onChange={setStoredValue}
        {...rest}
      />
    );
  }

  return (
    <RawInputGPS
      value={storedValue}
      onChange={setStoredValue}
      {...rest}
    />
  );
};

const RawInputGPS = (props) => {
  let {
    value,
    onChange,
    autolocation,
    mapZoom = 11,
    MapElements
  } = props;

  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(mapZoom);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.googleMapApiKey,
    libraries: ['places'],
  });

  useEffect(() => {
    if (isLoaded && map) {
      const input = document.createElement('input');
      input.type = 'text';
      input.placeholder = 'Buscar lugar';
      input.style.marginTop = '10px';
      input.style.marginLeft = '10px';
      input.style.padding = '8px 20px';
      input.style.fontSize = '15px';
      input.style.width = '80%';
      input.style.borderRadius = '0.25rem';
      input.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';

      map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

      const searchBox = new window.google.maps.places.SearchBox(input);
            
      const handleSearchResult = () => {
        const places = searchBox.getPlaces();
        if (places && places.length > 0) {
          const position = {
            lat: places[0].geometry.location.lat(),
            lng: places[0].geometry.location.lng(),
          };
          onChange(position);
        }
      };

      searchBox.addListener('places_changed', handleSearchResult);

      if (autolocation) {
        handleCurrentLocation();
      }

      map.addListener('dragend', () => {
        onChange(map.getCenter().toJSON());
      });
      map.addListener('zoom_changed', () => {
        setZoom(map.getZoom());
      });
    }
  }, [isLoaded, map, autolocation]);

  const initializeMap = (map) => {
    setMap(map);
  };

  const handleCurrentLocation = async () => {
    try {
      const position = await getCurrentPosition();
      onChange(position);
    } catch (error) {
      console.error(error);
      onChange({ ...config.defaultLocationGPS });
      toast.error('Debe permitir el uso del GPS');
      setTimeout(() => {
        toast('Ubicación por defecto asignada', { icon: 'ℹ️' });
      }, 1500);
    }
  };

  return (
    <div className="relative">
      {isLoaded && (
        <GoogleMap
          center={value}
          mapContainerStyle={{ height: '400px', width: '100%' }}
          options={{
            zoom: zoom,
            zoomControl: true,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            rotateControl: false,
            scaleControl: false
          }}
          onLoad={initializeMap}
        >
          <Marker position={value} draggable onDragEnd={(event) => onChange(event.latLng.toJSON())} />
          {value && map && MapElements ? (<MapElements map={map} value={value} />) : null}
        </GoogleMap>
      )}

      <button
        type="button"
        onClick={handleCurrentLocation}
        className="absolute bottom-6 left-2 bg-white rounded border border-gray-300 shadow-md px-4 py-2 flex flex-row gap-2 items-center"
      >
        <FaMapMarkerAlt />
        Ubicación
      </button>
    </div>
  );
};

export default RawInputGPS;