import _ from 'lodash';
import { blocksDefinitions, registerBlocks } from "./BlockStack";
import BlockPageHeaderAHero from "./blocks/BlockPageHeaderAHero";
import BlockPageFooterALinksFixedCta from "./blocks/BlockPageFooterALinksFixedCta";
import BlockFrecuentAnswersAColumn from "./blocks/BlockFrecuentAnswersAColumn";
import BlockTextGridAExtended from "./blocks/BlockTextGridAExtended";
import BlockTextGridBCompact from "./blocks/BlockTextGridBCompact";
import BlockCtaMenuAGrid from "./blocks/BlockCtaMenuAGrid";
import BlockReviewsAGrid from "./blocks/BlockReviewsAGrid";
import BlockContactCta from "./blocks/BlockContactCtaACentered";
import BlockCtaAHero from "./blocks/BlockCtaAHero";
import BlockCtaMenuBAccordionHero from "./blocks/BlockCtaMenuBAccordionHero";
import BlockPageFooterBContactsLinksFixedCta from './blocks/BlockPageFooterBContactsLinksFixedCta';
import BlockCarouselWithContent from './blocks/BlockCarouselWithContent';
// import BlockSection from './blocks/BlockSection';
// import BlockSectionCardTitle from './blocks/BlockSectionCardTitle';
// import BlockSectionCardBtns from './blocks/BlockSectionCardBtns';


registerBlocks([
  // new blocks
  // BlockSection,
  // BlockSectionCardTitle,
  // BlockSectionCardBtns,

  // legacy blocks
  BlockPageHeaderAHero,
  BlockPageFooterBContactsLinksFixedCta,
  BlockPageFooterALinksFixedCta,
  BlockFrecuentAnswersAColumn,
  BlockTextGridAExtended,
  BlockTextGridBCompact,
  BlockCarouselWithContent,
  BlockCtaMenuAGrid,
  BlockCtaMenuBAccordionHero,
  BlockReviewsAGrid,
  BlockContactCta,
  BlockCtaAHero
]);

_.assign(blocksDefinitions, {
  //// future blocks
  // section: {
  //   title: 'Sección de contenido',
  //   description: `
  //     Ocupa todo el largo de la pantalla, puede contener diversas Tarjetas de contenido, cada una con su orden en la sección y configuraciones
  //   `
  // },
  // sectionCardTitles: {
  //   title: 'Tarjeta: Títulos',
  //   description: `
  //     Contiene título, subtítulo y un parrafo
  //   `
  // },
  // sectionCardBtns: {
  //   title: 'Tarjeta: Lista de botones',
  //   description: `
  //     Contiene una lista de botones con enlaces de diferentes tipos
  //   `
  // },


  //// default blocks
  pageHeader: {
    title: 'Encabezado de la Página',
    description: `
      Incluye los elementos principales de navegación y branding. 
      Suele contener el logo de la empresa, el menú de navegación principal y enlaces a las secciones importantes del sitio web. 
      Su función principal es proporcionar acceso rápido y fácil a las diferentes áreas del sitio y representar la identidad visual de la marca.
    `
  },
  pageFooter: {
    title: 'Pie de Página',
    description: `
      Se encuentra al final del sitio web y ofrece información adicional y enlaces útiles. 
      Puede incluir enlaces a políticas de privacidad, términos y condiciones, información de contacto, enlaces a redes sociales y cualquier otra información relevante. 
      Su función es proporcionar recursos adicionales a los usuarios y mejorar la navegación en el sitio.
    `
  },
  textGrid: {
    title: 'Cuadrícula de Texto',
    description: `
      Organiza la información en un formato de cuadrícula, haciendo que el contenido sea más fácil de leer y escanear. 
      Puede incluir varias secciones de texto con títulos y descripciones. 
      Su función es presentar información de manera clara y estructurada, mejorando la legibilidad y la experiencia del usuario.
    `
  },
  cta: {
    title: 'Llamada a la Acción',
    description: `
      Está diseñado para atraer la atención del usuario y dirigirlo a realizar una acción específica, como registrarse, comprar un producto o suscribirse a un boletín. 
      Generalmente incluye un botón o un enlace que destaca visualmente para captar la atención. 
      Su función es aumentar la conversión y guiar a los usuarios a través del embudo de ventas o interacción.
    `
  },
    frecuentAnswers: {
    title: 'Preguntas Frecuentes',
    description: `
      Proporciona respuestas a las preguntas más comunes que los usuarios pueden tener sobre los productos, servicios o la empresa. 
      Se organiza en un formato de preguntas y respuestas para facilitar la búsqueda de información. 
      Su función es reducir las dudas de los usuarios, mejorar la experiencia del cliente y reducir la carga en el soporte al cliente.
    `
  },
  reviews: {
    title: 'Reseñas',
    description: `
      Muestra testimonios y opiniones de clientes anteriores sobre los productos o servicios de la empresa. 
      Generalmente incluye citas de clientes, puntuaciones y, a veces, fotos o videos. 
      Su función es construir confianza y credibilidad con nuevos visitantes al mostrar experiencias positivas de otros usuarios.
    `
  },
  image: {
    title: 'Imagen',
    description: `
    Este bloque permite la inclusión de imágenes estáticas o dinámicas en diferentes formatos. 
    Puede presentarse como una galería o un carrusel que muestre múltiples imágenes de manera secuencial o como una sola imagen destacada. 
    Las imágenes pueden estar acompañadas de texto descriptivo o leyendas, dependiendo del diseño y la funcionalidad deseada. 
    Su función es mejorar el aspecto visual del sitio web, transmitir información de manera efectiva y complementar el contenido textual.
    `
  }



  // contactCta: {
  //   title: 'Llamada a la Acción de Contacto',
  //   description: `
  //     Está diseñado para animar a los visitantes a ponerse en contacto con la empresa. 
  //     Generalmente incluye un botón prominente o un enlace que dirige a los usuarios a un formulario de contacto o una página con información de contacto detallada. 
  //     Su función principal es fomentar la interacción y facilitar la comunicación entre los usuarios y la empresa.
  //   `
  // },
  // textGrid: {
  //   title: 'Cuadrícula de Texto',
  //   description: `
  //     Organiza la información en un formato de cuadrícula, haciendo que el contenido sea más fácil de leer y escanear. 
  //     Puede incluir varias secciones de texto con títulos y descripciones. 
  //     Su función es presentar información de manera clara y estructurada, mejorando la legibilidad y la experiencia del usuario.
  //   `
  // },
  // cta: {
  //   title: 'Llamada a la Acción',
  //   description: `
  //     Está diseñado para atraer la atención del usuario y dirigirlo a realizar una acción específica, como registrarse, comprar un producto o suscribirse a un boletín. 
  //     Generalmente incluye un botón o un enlace que destaca visualmente para captar la atención. 
  //     Su función es aumentar la conversión y guiar a los usuarios a través del embudo de ventas o interacción.
  //   `
  // },
  // ctaMenu: {
  //   title: 'Menú de Llamada a la Acción',
  //   description: `
  //     Combina múltiples llamadas a la acción en un solo lugar, proporcionando varias opciones para que los usuarios interactúen. 
  //     Puede incluir enlaces a diferentes secciones del sitio web o acciones específicas como descargar un recurso, ver un video o contactar a la empresa. 
  //     Su función es ofrecer múltiples caminos para la interacción del usuario, aumentando las posibilidades de conversión.
  //   `
  // },
  // frecuentAnswers: {
  //   title: 'Preguntas Frecuentes',
  //   description: `
  //     Proporciona respuestas a las preguntas más comunes que los usuarios pueden tener sobre los productos, servicios o la empresa. 
  //     Se organiza en un formato de preguntas y respuestas para facilitar la búsqueda de información. 
  //     Su función es reducir las dudas de los usuarios, mejorar la experiencia del cliente y reducir la carga en el soporte al cliente.
  //   `
  // },
  // reviews: {
  //   title: 'Reseñas',
  //   description: `
  //     Muestra testimonios y opiniones de clientes anteriores sobre los productos o servicios de la empresa. 
  //     Generalmente incluye citas de clientes, puntuaciones y, a veces, fotos o videos. 
  //     Su función es construir confianza y credibilidad con nuevos visitantes al mostrar experiencias positivas de otros usuarios.
  //   `
  // }
});