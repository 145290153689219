import { Field, useForm } from 'react-final-form';
import _ from 'lodash';
import { FieldLabel, ShowFieldHelp } from './formParts';


const FormFieldCustom = (props) => {
  let {
    name,
    title,
    fieldsRequired = null,
    validate,
    // disabled = false,
    showLabel = true,
    showClearBtn = true,
    onChange,
    Render,
    onAfterChange
  } = props;
  const formApi = useForm();

  return (
    <div className={`${props?.classes?.fieldContainer || ''}`}>
      <Field name={name} validate={(args) => validate && validate(args)}>
        {({ input, meta }) => (
          <>
            <FieldLabel {...props} input={input} meta={meta} />
            {Render ? (
              <Render
                {...props}
                {...input}
                onChange={async (value) => {
                  input.onChange(value); 
                  onChange && onChange(value);
                  onAfterChange && await onAfterChange({ value, formApi });
                }}
                className={props?.classes?.fieldInput || ''}
              />
            ) : null}
            {/* error */}
            <ShowFieldHelp {...props} name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} {...props} />
          </>
        )}
      </Field>
    </div>
  );
};

export default FormFieldCustom;