import _ from 'lodash';
import config from "../../config";
import Model from "../../libs/ModelClass";
import { createContext, useContext, useEffect, useState } from 'react';
import BadgeLoading from '../../components/ui/BadgeLoading';


const settingsEntitySlug = config.modules.panel.settingsEntitySlug;
const SettingsModel = Model.extend(settingsEntitySlug);

const settingsContext = createContext();

export const useSettings = () => {
  const context = useContext(settingsContext);
  if (!context) throw new Error("There is no Panel provider");
  return context;
};

export function SettingsProvider(props) {
  let { children } = props;

  const settings = useWithSettings();

  return (
    <settingsContext.Provider
      value={{
        ...settings
      }}
    >
      {children}
    </settingsContext.Provider>
  );
}

export const useWithSettings = (props = {}) => {
  let { currentScope } = props;
  const [ settingsStore, setSettingsStore ] = useState({
    'main': 'pending'
  });

  useEffect(() => {
    // detecta si hay un nuevo pending, si hay entonces hace el fetch
    _.find(settingsStore, (value, scope) => {
      if (value === 'pending') {
        setSettingsStore({ ...settingsStore, [scope]: 'loading' });
        fetchForScope(scope);
      }
    })
  }, [settingsStore]);

  useEffect(() => {
    if (currentScope) {
      loadScope(currentScope);
    }
  }, [currentScope]);

  const loadScope = (scope) => {
    if (!settingsStore[scope]) {
      setSettingsStore({ ...settingsStore, [scope]: 'pending' });
      return false;
    }
    else if (settingsStore[scope] === 'pending' || settingsStore[scope] === 'loading') {
      return false;
    }
    else if (_.isObject(settingsStore[scope])) {
      return true;
    }
  };

  const fetchForScope = async (scope, saveState = true) => {
    const instanceSettings = await SettingsModel.where('scope', '==', scope);
    if (saveState) {
      setSettingsStore({ ...settingsStore, [scope]: instanceSettings });
    }
    return instanceSettings;
  };

  const get = (scope, key, defaultValue) => {
    const settingsDocs = settingsStore[scope] || [];
    if (settingsDocs === 'pending' || settingsDocs === 'loading') {
      return defaultValue;
    }
    const doc = settingsDocs.find( doc => doc.data.key === key )
    return doc ? doc.data.value : defaultValue;
  };

  const set = async (scope, key, value) => {
    const settingsDocs = settingsStore[scope];
    if (!settingsDocs) {
      throw new Error('Scope not loaded');
    }
    let doc = settingsDocs.find( doc => doc.data.key === key )
    if (doc) {
      doc.data.value = _.isObjectLike(value) 
        ? {
          ...(doc.data.value || {}),
          ...(value || {})
        } 
        : value;
      return doc.save();
    } else {
      let newDoc = await SettingsModel.create({ scope, key, value });
      settingsDocs.push(newDoc);
      return newDoc;
    }
  };

  const getRemote = async (scope, key, defaultValue) => {
    const doc = await SettingsModel.filterByAttributes({ scope, key });
    return doc.length ? doc[0].data.value : defaultValue;
  };

  const setRemote = async (scope, key, value) => {
    try {
      return await set(scope, key, value);
    } catch (error) {
      if (error.message === 'Scope not loaded') {
        const doc = await SettingsModel.filterByAttributes({ scope, key });
        if (doc.length) {
          doc[0].data.value = _.isObjectLike(value) 
            ? {
              ...(doc[0].data.value || {}),
              ...(value || {})
            } 
            : value;
          return doc[0].save();
        } else {
          return SettingsModel.create({ scope, key, value });
        }
      }
      throw error;
    }
  };

  return {
    loadScope,
    fetchForScope,
    settingsStore,
    get,
    set,
    getRemote,
    setRemote
  };
};

export const SettingsLoader = (props) => {
  const { children, match } = props;
  const { instance } = match.params;

  const settings = useSettings();
  const isSettingsLoaded = settings.loadScope(instance || 'main');

  if (!isSettingsLoaded) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  };

  return children;
};