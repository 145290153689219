import _ from 'lodash';
import { typeConfigs, typeOptions } from '../../components/DataTypes';
import { IonCard, IonCardContent, IonFooter, IonItem, IonSelect, IonSelectOption, IonTitle } from '@ionic/react';
import SectionCrudObject from '../../components/SectionCrudObject';
import SectionCrudForm from '../../components/SectionCrudForm';
import { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { FormField, FormFieldCheckboxToggle, FormFieldSelect, FormFieldTextArea } from '../../components/Form';
import { inputClasses } from '../../components/EntityTaxonomyForm';
import { RenderFilterParam, RenderFilterPreview, RenderInputParams, RenderInputPreview, RenderShowParams, RenderShowPreview } from './RouteEntityCreatorTaxonomy';


const fieldsOptions = typeOptions.map(option => ({
  label: option.name,
  value: option.key
}));

export const FormInputFields = (props) => {
  const { fieldName, index, instance, entitySlug, form, values, handleSubmit, submitting, fieldsRequired } = props;

  return (
    <div className={inputClasses.renderListContainer}>
      <FormField name="name" title="Nombre" placeholder="Nombre" fieldsRequired={fieldsRequired} showLabel={true} classes={inputClasses} />
      <FormField name="nameSlug" title="ID" fieldsRequired={fieldsRequired} classes={inputClasses}
        onChange={(value) => {
          form.change('nameSlug', _.camelCase(value || ''));
        }}
        showLabel={true}
      />
      <FormFieldSelect name="type" title="Tipo de dato" options={fieldsOptions} showLabel={true} classes={inputClasses} />
      <FormFieldCheckboxToggle
        name="isFormToCart"
        title="Formulario de interacción al pedir"
        descriptionText="Determina si el usuario podrá introducir o sólo ver los datos"
        textTrue="Si, mostrar como formulario para agregar al pedido"
        textFalse="No, es información estática"
        fieldsRequired={fieldsRequired}
        classes={inputClasses}
      />

      {values?.nameSlug ? (<>
        {/* Form */}
        <div className="w-full rounded bg-gray-200 p-2 mt-5 space-y-6">
          <h3 className="mb-2 pb-2 border-b border-gray-300 text-black !font-bold uppercase">Formulario</h3>
          <FormFieldCheckboxToggle name="required" title="Requerido en formularios" fieldsRequired={fieldsRequired} classes={inputClasses} />
          <FormFieldTextArea
            name="param.descriptionText"
            title="Texto de descripción"
            placeholder="Texto de descripción"
            showLabel={true}
            classes={inputClasses}
          />
          <FormFieldTextArea
            name="param.helpText"
            title="Texto de ayuda"
            placeholder="Texto de ayuda"
            showLabel={true}
            classes={inputClasses}
          />
          <div className="my-2 space-y-6">
            <RenderInputParams values={values} taxonomyTypeDoc={values} entitySlug={entitySlug} formApi={form} />
          </div>
          {/* Example */}
          {typeConfigs[values.type]?.RenderInputPreview ? (
            <div className="mt-4 p-2 bg-white rounded">
              <div className="pb-2 border-b border-gray-200">
                <h3 className="font-semibold">Vista en formulario</h3>
              </div>
              <div className="my-10">
                <RenderInputPreview values={values} taxonomyTypeDoc={values} entitySlug={entitySlug} formApi={form} />
              </div>
            </div>
          ) : null}
        </div>
        
        {/* Show */}
        <div className="w-full rounded bg-gray-200 p-2 mt-5 space-y-6">
          <h3 className="mb-2 pb-2 border-b border-gray-300 text-black !font-bold uppercase">Vista</h3>
          {/* Params */}
          <FormFieldCheckboxToggle name="outstanding" title="Destacado en listas" fieldsRequired={fieldsRequired} classes={inputClasses} />

          {typeConfigs[values.type]?.RenderShowParams ? (
            <RenderShowParams values={values} taxonomyTypeDoc={values} entitySlug={entitySlug} />
          ) : null}
          {/* Example */}
          {typeConfigs[values.type]?.RenderShowPreview ? (
            <div className="mt-4 p-2 bg-white rounded">
              <div className="pb-2 border-b border-gray-200">
                <h3 className="font-semibold">Vista en listas y views</h3>
              </div>
              <RenderShowPreview values={values} taxonomyTypeDoc={values} entitySlug={entitySlug} />
            </div>
          ) : null}
        </div>

        {/* Filter */}
        <div className="w-full rounded bg-gray-200 p-2 mt-5 space-y-6">
          <h3 className="mb-2 pb-2 border-b border-gray-300 text-black !font-bold uppercase">Filtro</h3>
          <FormFieldCheckboxToggle name="isFilter" title="Utilizar como filtro" fieldsRequired={fieldsRequired} classes={inputClasses} />
          {/* Params */}
          <RenderFilterParam {...{form, values, taxonomyType: values}} />
          {/* Example */}
          {typeConfigs[values.type]?.RenderFilter ? (
            <div className="mt-4 p-3 bg-white rounded">
              <div className="pb-2 border-b border-gray-200">
                <h3 className="font-semibold">Vista en filtros</h3>
              </div>
              <div className="my-10">
                <RenderFilterPreview {...{
                  form,
                  values,
                  entitySlug
                }} />
              </div>
            </div>
          ) : null}
        </div>
      </>) : null}
    </div>
  );
};

const ListItem = ({ doc }) => (<>
  <div className="space-x-1.5">
    <span className="inline font-semibold text-gray-800">{doc.name}</span>
    <span className="text-xs text-brand-medium-contrast px-2 py-0.5 rounded-full bg-brand-medium">{doc.nameSlug}</span>
  </div>
  <div className="space-x-1.5">
    {doc.isFormToCart ? (
      <span className="text-xs text-brand-light-contrast px-2 py-0.5 rounded-full bg-brand-light">Formulario de pedido</span>
    ) : null}
    {doc.isFilter ? (
      <span className="text-xs text-brand-light-contrast px-2 py-0.5 rounded-full bg-brand-light">Filtro</span>
    ) : null}
    {doc.outstanding ? (
      <span className="text-xs text-brand-light-contrast px-2 py-0.5 rounded-full bg-brand-light">Destacado</span>
    ) : null}
    {doc.required ? (
      <span className="text-xs text-brand-light-contrast px-2 py-0.5 rounded-full bg-brand-light">Requerido</span>
    ) : null}
  </div>
  <div className="">
    <span className="block text-sm text-gray-700">
      {fieldsOptions.find((option) => option.value === doc?.type)?.label}
    </span>
  </div>
</>);

export const ObjectFormCreator = (props) => {
  let {
    instance,
    entitySlug,
    fieldsType,
    fieldName,
    fields = [],
    isSaving,
    save,
    showBtnSave,
    onChange
  } = props;
  const [ fieldsList, setFieldsList ] = useState(_.isArray(fields) ? fields : []);
  const [ selectedField, setSelectedField ] = useState(null);
  const fieldsRequired = ['name', 'type'];

  useEffect(() => {
    onChange && onChange(fieldsList);
  }, [fieldsList]);

  const assignField = (optionValue) => {
    const newField = {
      id: nanoid(),
      type: optionValue
    };
    setFieldsList([...fieldsList, newField]);
    setSelectedField(null);
  };

  const handleSave = (formValues) => {
    setFieldsList((prevItems) => {
      const updatedItems = [...prevItems];
      const index = updatedItems.findIndex((doc) => doc.id === formValues.id);
      if (index !== -1) {
        updatedItems[index] = { ...formValues };
      }
      return updatedItems;
    });
  };

  const handleDelete = (docToDelete) => {
    setFieldsList((prevItems) => {
      return prevItems.filter((doc) => doc.id !== docToDelete.id);
    });
  };

  const handleReorder = (reorderedMap) => {
    setFieldsList(reorderedMap);
  };

  const onFormChange = (values, previousValues, form, doc) => {
    handleSave(values);
  };

  return (
    <IonCard className="m-0">
      <IonCardContent className={`p-0`}>
        {/* Fields list */}
        {fieldsList?.length ? (
          <SectionCrudObject
            instance={instance}
            entitySlug={entitySlug}
            editStyle="onsite"
            // title="Bloques seleccionados"
            docs={fieldsList}
            reorder={true}
            // show
            showBtnAdd={false}
            showToast={false}

            // validations
            fieldsRequired={fieldsRequired}
            // onValidation={onValidationMenuFilter}

            // callbacks
            onSave={handleSave}
            onDelete={handleDelete}
            onReorder={handleReorder}
            
            // UI
            ListItem={ListItem}
            FormInputFields={FormInputFields}
            FormSection={SectionCrudForm}
            formSectionProps={{
              showTitle: true,
              showSaveButton: false,
              saveBtnLabel: 'Aplicar',
              saveBtnColor: 'secondary',
              fieldName,
              onFormChange
            }}

            // classes
            classes={{
              sectionCrudContainer: "",
              formSectionContainer: "!mb-0",
              formSectionContainerOpen: "border-2 border-brand-secondary",
              formTitle: "!text-xs !font-semibold !text-inherit",
              cardContainer: "!m-0",
              listItemContainer: ""
            }}
          />
        ) : ''}
      </IonCardContent>
    
      {/* Actions */}
      <IonFooter className="grid grid-cols-2 gap-4">
        <IonItem lines="none">
          <IonSelect
            interface="action-sheet"
            size="small"
            placeholder="Agregar tipo de dato"
            interfaceOptions={{
              header: 'Tipos de datos',
              subHeader: 'Seleccione el tipo de dato para agregarlo',
            }}
            onIonChange={(e) => assignField(e.detail.value)}
            value={null}
          >
            {fieldsOptions.map((option) => (
              <IonSelectOption key={option.value} value={option.value}>{option.label}</IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      </IonFooter>
      
    </IonCard>
  );
};

const RenderForm = (props) => {
  let { 
    instance,
    entitySlug,
    fieldName,
    value,
    onChange,
    fieldsType,
  } = props;

  return (
    <ObjectFormCreator {...{
      instance,
      entitySlug,
      fieldName,
      fields: value,
      onChange
    }} />
  );
};

const RenderShow = ({ displayedValue }) => {
  return (
    null
  );
}

const RenderShowList = ({ displayedValue = {} }) => {
  return (
    <div className="">
      Show list
    </div>
  );
}

const dataTypeFields = {
  RenderForm,
  RenderShow,
  RenderShowList
};

export default dataTypeFields;