import _ from 'lodash';
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import { useAuth } from '../user/AuthContext';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import config from '../../config';
import { IonAlert, IonButton, IonCard, IonCardContent, IonIcon } from '@ionic/react';
import { Form } from 'react-final-form';
import { usePanel } from '../panel/usePanel';
import {
  peopleOutline,
  colorPaletteOutline,
  shareSocialOutline
} from 'ionicons/icons';
import Model from '../../libs/ModelClass';
import dataTypeFonts from './dataTypeFonts';
import specTheme from '../../customModules/specTheme';
import { generateColorPalette } from '../../libs/utilsColors';
import { BasePaletteSelect, defaultColorsUrl } from './dataTypeColorsPalette';
import { getJson } from '../../libs/utils';
import ObjectForm, { EntityObjectForm } from '../entity/ObjectForm';
import { CustomOptionCountry } from '../../components/Form/FormFieldSelect';
import { getFromPlace } from '../../libs/utilsGeo';
import { AlertLogger, useAlertLogger } from '../panel/AlertLogger';


const colorsPalettesEntitySlug = config.modules.instances.instancesColorsPalettesEntitySlug;
const layoutBlocksEntitySlug = config.modules.instances.instancesLayoutBlocksEntitySlug;
const mainPartsEntitySlug = config.modules.instances.instancesMainPartsEntitySlug;
const mainStringsEntitySlug = config.modules.instances.instancesMainStringsEntitySlug;

const WizardStepForm = (props) => {
  let { instance, entitySlug, objectForm, key, title, icon, classes } = props;

  const content = useMemo(() => {
    return objectForm ? <ObjectForm {...props} fields={objectForm} /> : <EntityObjectForm {...props} />;
  }, []);

  return (
    <FormWizard.TabContent
      key={key}
      title= {title}
      icon={icon}
      // isValid={}
      // validationError={mode === 'update' && errorNumber}
    >
      <IonCard className="m-0">
        <IonCardContent className={classes?.cardContent}>
          {content}
        </IonCardContent>
      </IonCard>
    </FormWizard.TabContent>
  );
}

const InnerForm = (props) => {
  let {
    form, values, handleSubmit, submitting, hasErrors, isSaving, handleCompleteWizard, tabChanged, fieldsRequired
  } = props;

  const { user } = useAuth();
  const [ countryIso2, setCountryIso2 ] = useState();
  const { instance } = usePanel();
  const [ exampleText, setExampleText ] = useState();
  const [ exampleTextMoto, setExampleTextMoto ] = useState();
  const [ countries, setCountries ] = useState([]);

  useEffect(() => {
    if (values?.defaultGps && countryIso2) {
      let countryOption = _.find(countries, { iso2: countryIso2 });
      form?.change('countryId', countryOption?.value);
    }
  }, [countryIso2, values.defaultGps]);

  const stepsContent = [
    {
      title: 'Nombre',
      icon: <IonIcon icon={peopleOutline} className="text-2xl" />,
      entitySlug: 'instancesBrands',
      objectForm: {
        profileImg: {
          type: 'gallery',
          name: 'Imagen de perfil',
          doResize: true,
          folder: 'profilesImages',
          onAfterChange: ({ updatedFilesNames, files, formApi }) => {
            // console.log('gallery value', updatedFilesNames, files, formApi);
            // extraer colores principal y secundario para asignar al doc en estilos
          },
        },
        siteName: {
          type: 'text',
          name: 'Título del sitio web',
          onAfterChange: ({ value, formApi }) => {
            // asignar al doc en estilos
            setExampleText(value);
          },
        },
        siteMoto: {
          type: 'text',
          name: 'Lema del sitio web',
          onAfterChange: ({ value, formApi }) => {
            // asignar al doc en estilos
            setExampleTextMoto(value);
          },
        },
        sector: {
          type: 'text',
          name: 'Sector, rubro o área'
        }
      }
    },
    {
      title: 'Estilo',
      icon: <IonIcon icon={colorPaletteOutline} className="text-2xl" />,
      entitySlug: 'instancesBrands',
      objectForm: {
        mainFont: {
          type: 'coded',
          Render: dataTypeFonts.RenderForm,
          name: 'Tipografía principal',
          descriptionText: 'Utilizado en títulos, subtitulos y encabezados',
          exampleText
        },
        secondaryFont: {
          type: 'coded',
          Render: dataTypeFonts.RenderForm,
          name: 'Tipografía secundaria',
          descriptionText: 'Utilizado en párrafos',
          exampleText: exampleTextMoto
        },
        basePalette: {
          type: 'coded',
          Render: BasePaletteSelect,
          onAfterChange: ({ value, formApi }) => {
            // asignar al doc en estilos
            formApi.change('primaryColor', value?.value['primary']);
            formApi.change('secondaryColor', value?.value['secondary']);
            formApi.change('tertiaryColor', value?.value['tertiary']);
            formApi.change('successColor', value?.value['success']);
            formApi.change('warningColor', value?.value['warning']);
            formApi.change('dangerColor', value?.value['danger']);
            formApi.change('darkColor', value?.value['dark']);
            formApi.change('mediumColor', value?.value['medium']);
            formApi.change('lightColor', value?.value['light']);
          },
        },
        primaryColor: {
          type: 'color',
          name: 'Color primario',
          defaultValue: specTheme.colors['primary'],
          showPalette: false
        },
        secondaryColor: {
          type: 'color',
          name: 'Color secundario',
          defaultValue: specTheme.colors['secondary'],
          showPalette: false
        },
        tertiaryColor: {
          type: 'color',
          name: 'Color terciario',
          defaultValue: specTheme.colors['tertiary'],
          showPalette: false
        },
        successColor: {
          type: 'color',
          name: 'Color de éxito',
          defaultValue: specTheme.colors['success'],
          showPalette: false
        },
        warningColor: {
          type: 'color',
          name: 'Color de advertencia',
          defaultValue: specTheme.colors['warning'],
          showPalette: false
        },
        dangerColor: {
          type: 'color',
          name: 'Color de peligro',
          defaultValue: specTheme.colors['danger'],
          showPalette: false
        },
        darkColor: {
          type: 'color',
          name: 'Color oscuro',
          defaultValue: specTheme.colors['dark'],
          showPalette: false
        },
        mediumColor: {
          type: 'color',
          name: 'Color medio',
          defaultValue: specTheme.colors['medium'],
          showPalette: false
        },
        lightColor: {
          type: 'color',
          name: 'Color claro',
          defaultValue: specTheme.colors['light'],
          showPalette: false
        }
      },
      classes: {
        cardContent: 'pb-48'
      }
    },
    {
      title: 'Contacto',
      icon: <IonIcon icon={shareSocialOutline} className="text-2xl" />,
      entitySlug: 'instancesBrands',
      objectForm: {
        email: {
          type: 'text',
          name: 'Correo Electrónico',
          helpText: 'Introduce tu dirección de correo electrónico para recibir notificaciones importantes.'
        },
        phoneNumber: {
          type: 'phone',
          name: 'Teléfono Celular',
          descriptionText: 'Este número se usará para que los clientes te contacten fácilmente por WhatsApp.',
          helpText: 'Introduce tu número de celular con código de país.'
        },
        addressString: {
          type: 'textArea',
          name: 'Dirección',
          helpText: 'Especifica tu dirección completa incluyendo referencias que faciliten la localización.'
        },
        defaultGps: {
          type: 'gps',
          name: 'Ubicación GPS',
          showClearBtn: true,
          includePlace: true,
          descriptionText: 'Estas coordenadas se utilizarán para mostrar tu ubicación en el mapa.',
          helpText: 'Se mostrará en la portada y en las secciones de contacto para ubicarte mejor.',
          onAfterChange: ({ value, formApi }) => {
            let shortName = _.get(getFromPlace(value?.place, 'country'), 'short_name', '');
            setCountryIso2(shortName);
          }
        },
        countryId: {
          type: 'selectOneEntityDocument',
          name: 'Moneda y país',
          descriptionText: 'Especifica la moneda a utilizarse',
          param: {
            fromJson: '/data/i18n/data/countries.json',
            fieldValue: 'id',
            fieldLabel: 'name',
            normalizedFields: ['iso2', 'iso3', 'currency_symbol', 'currency'],
            optionRender: CustomOptionCountry,
            optionsGetter: (data) => { setCountries(data) }
          }
        },
        instagram: {
          type: 'text',
          name: 'Instagram',
          helpText: 'Introduce la URL de tu perfil en Instagram para que los usuarios te sigan.'
        },
        facebook: {
          type: 'text',
          name: 'Facebook',
          helpText: 'Introduce la URL de tu página de Facebook para facilitar el acceso a tus redes sociales.'
        }
      }    
    }
  ];

  return (
    <FormWizard
      stepSize="xs"
      shape="circle"
      onComplete={handleCompleteWizard(values)}
      onTabChange={tabChanged}
      backButtonTemplate={(handleNext) => (
        <IonButton color="light" size="small" onClick={handleNext}>
          Anterior
        </IonButton>
      )}
      nextButtonTemplate={(handleNext) => (
        <IonButton color="secondary" size="small" onClick={handleNext} className="float-right">
          Siguiente
        </IonButton>
      )}
      finishButtonTemplate={(handleComplete) => (
        <IonButton color="primary" size="small" onClick={handleComplete} className="float-right" disabled={submitting || isSaving || hasErrors}>
          Guardar
        </IonButton>
      )}
    >
      {stepsContent.map((props, index) => (
        <WizardStepForm
          {...props}
          key={index}
          instance={instance}
          values={values}
          fieldsRequired={fieldsRequired}
          formApi={form}
        />
      ))}
    </FormWizard>
  );
}

export function FormInstanceBrandWizard(props) {
  let {
    instance,
    context,
    doc,
    onFinish,
    entityDoc,
    history,
    match,
    attachPrefix,
    defaultValue,
    asModal,
    classes = {}
  } = props;

  const [ docData, setDocData ] = useState({...(doc?.data || {})});
  const [ hasErrors, setHasErrors ] = useState(false);
  const [ isSaving, setIsSaving ] = useState(false);
  const [ showAlert, setShowAlert ] = useState(true);
  const { pushLog, logs, present, dismiss, isModalOpen } = useAlertLogger();

  const fieldsRequired = [
    'profileImg',
    'siteName',
    
    'basePalette',
    'primaryColor',
    'secondaryColor',
    'tertiaryColor',
    'successColor',
    'warningColor',
    'dangerColor',
    'darkColor',
    'mediumColor',
    'lightColor',

    'mainFont',
    'secondaryFont',
  ];
  
  const saveData = async (values) => {
    let {
      profileImg,
      siteName,
      siteMoto,
      sector,
      basePalette,
      primaryColor,
      secondaryColor,
      tertiaryColor,
      successColor,
      warningColor,
      dangerColor,
      darkColor,
      mediumColor,
      lightColor,
      mainFont,
      secondaryFont,
      email,
      phoneNumber,
      addressString,
      defaultGps,
      countryId,
      instagram,
      facebook,
    } = values;
    // create 
    // mainStringsId
    await pushLog('Registrando datos principales');
    const mainStringsDoc = await Model.extend(mainStringsEntitySlug).create({
      instanceId: doc.data.instanceId,
      name: 'Datos 1',
      siteName,
      siteMoto,
      sector,
      email,
      whatsappNumber: phoneNumber,
      callNumber: phoneNumber,
      addressString,
      defaultGps,
      countryId,
      instagram,
      facebook
    });
    // colorPaletteId
    await pushLog('Registrando colores');
    let colorsPalette = {};
    if (!basePalette) {
      let defaultColors = await getJson(defaultColorsUrl);
      if (defaultColors) {
        basePalette = defaultColors[0]?.colorsPalette;
      }
    }
    if (basePalette) {
      colorsPalette = {
        ...generateColorPalette({
          primary: primaryColor || basePalette['primary'],
          secondary: secondaryColor || basePalette['secondary'],
          tertiary: tertiaryColor || basePalette['tertiary'],
          success: successColor || basePalette['success'],
          warning: warningColor || basePalette['warning'],
          danger: dangerColor || basePalette['danger'],
          dark: darkColor || basePalette['dark'],
          medium: mediumColor || basePalette['medium'],
          light: lightColor || basePalette['light']
        })
      };
    }
    const colorPaletteDoc = await Model.extend(colorsPalettesEntitySlug).create({
      instanceId: doc.data.instanceId,
      name: 'Colores 1',
      // TODO crear paleta para ionic desde estos colores (variantes de cada color: color, contrast, shade, tint)
      colorsPalette
    });
    // mainPartsId
    await pushLog('Registrando fuentes y partes principales');
    const mainPartsDoc = await Model.extend(mainPartsEntitySlug).create({
      instanceId: doc.data.instanceId,
      name: 'Fuentes 1',
      mainFont,
      secondaryFont
    });
    // layoutBlocksId
    await pushLog('Registrando bloques reutilizables');
    const defaultDataLayoutBlocks = await getJson('/data/instancesBrands/defaultDataLayoutBlocks.json');
    const layoutBlocksDoc = await Model.extend(layoutBlocksEntitySlug).create({
      instanceId: doc.data.instanceId,
      name: 'Bloques 1',
      ...defaultDataLayoutBlocks
    });

    doc.data = {
      ...doc.data,
      profileImg: profileImg,
      mainStringsId: mainStringsDoc.id,
      colorPaletteId: colorPaletteDoc.id,
      mainPartsId: mainPartsDoc.id,
      layoutBlocksId: layoutBlocksDoc.id
    };

    // save data
    await pushLog('Guardando datos');
    await doc.save();
    toast.success('Datos actualizados');
  };

  const handleSubmitForm = async (values) => { /* nothing */ };

  const handleCompleteWizard = (values) => async () => {
    if (
      _.size(checkValidateForm(values))
    ) {
      toast.error('Debe completar los datos');
      return;
    }
    setIsSaving(true);
    present();
    const doc = await saveData(values);
    onFinish && await onFinish({ doc });
    dismiss();
    setIsSaving(false);
  };

  const checkValidateForm = (values, errors = {}) => {
    if (!values?.profileImg) { errors.profileImg = 'Debe seleccionar una imagen'; }
    if (!values?.siteName) { errors.siteName = 'Debe ingresar un título'; }

    if (!values?.basePalette) { errors.basePalette = 'Debe seleccionar una paleta de colores'; }
    if (!values?.primaryColor) { errors.primaryColor = 'Debe seleccionar un color'; }
    if (!values?.secondaryColor) { errors.secondaryColor = 'Debe seleccionar un color'; }
    if (!values?.tertiaryColor) { errors.tertiaryColor = 'Debe seleccionar un color'; }
    if (!values?.successColor) { errors.successColor = 'Debe seleccionar un color'; }
    if (!values?.warningColor) { errors.warningColor = 'Debe seleccionar un color'; }
    if (!values?.dangerColor) { errors.dangerColor = 'Debe seleccionar un color'; }
    if (!values?.darkColor) { errors.darkColor = 'Debe seleccionar un color'; }
    if (!values?.mediumColor) { errors.mediumColor = 'Debe seleccionar un color'; }
    if (!values?.lightColor) { errors.lightColor = 'Debe seleccionar un color'; }

    if (!values?.mainFont) { errors.mainFont = 'Debe seleccionar una tipografía'; }
    if (!values?.secondaryFont) { errors.secondaryFont = 'Debe seleccionar una tipografía'; }

    // if (!values?.email) { errors.email = 'Debe ingresar un correo electrónico'; }
    // if (!values?.phoneNumber) { errors.phoneNumber = 'Debe ingresar un número de teléfono'; }
    // if (!values?.addressString) { errors.addressString = 'Debe ingresar una dirección'; }
    // if (!values?.defaultGps) { errors.defaultGps = 'Debe ingresar una ubicación GPS'; }
    // if (!values?.instagram) { errors.instagram = 'Debe ingresar una URL de Instagram'; }
    // if (!values?.facebook) { errors.facebook = 'Debe ingresar una URL de Facebook'; }
    return errors;
  };

  const validateForm = (values) => {
    const errors = {};
    // all required fields
    fieldsRequired?.forEach(field => {
      if (!values[field]) {
        errors[field] = ' ';
      }
    });
    checkValidateForm(values, errors);
    setHasErrors(Object.keys(errors).length > 0);
    return errors;
  };
  
  const tabChanged = ({ prevIndex, nextIndex }) => {};
  
  const renderContent = ({ form, values, handleSubmit, submitting }) => {
    return (
      <form onSubmit={(event) => {
        // TODO: prevent submit on pressing enter on a field
        event.preventDefault(); // No funciona
      }}>
        <InnerForm {...props} {...{ form, values, handleSubmit, submitting, hasErrors, isSaving, handleCompleteWizard, tabChanged, fieldsRequired }} />
      </form>
    );
  };

  return (<>
    <Form
      onSubmit={handleSubmitForm}
      initialValues={docData}
      validate={validateForm}
      render={renderContent}
    />
    <IonAlert
      isOpen={showAlert}
      onDidDismiss={() => setShowAlert(false)}
      backdropDismiss={false}
      header="Personalizá tu instancia"
      message={`
        Subí una imagen, elegí colores,
        completá los datos de la marca y de contacto
      `}
      buttons={[
        {
          text: 'Entendido',
          role: 'confirm'
        }
      ]}
    />
    {/* Logs */}
    <AlertLogger logs={logs} isOpen={isModalOpen} />
  </>);
}