// import { IonIcon } from "@ionic/react";
// import { 
//   logoGoogle
// } from 'ionicons/icons';
// import { useAuth } from "../../user/AuthContext";
// import { useLoginUtils } from "../../user/Login";
// import BtnLoading from "../../../components/ui/BtnLoading";

import ContactBTN from "../../../components/ContactBTN";
import { AnimationAddClassWhenVisible } from "../../../components/ui/AnimationAddClassWhenVisible";
import BtnSignUpModal from "../../../components/ui/BtnSignUpModal";
import { compileStringTemplate, lineBreaks } from "../../../libs/utils";
import { Background } from "../parts/PartBackground";
import { BtnsList, sectionCardBtnsFields } from "./BlockSectionCardBtns";
import { Card } from "../parts/PartCard";
import { Text } from "../parts/PartText";
import NotionShowLazy from "../../../components/ui/NotionShowLazy";
import { CardContainer } from "../parts/PartCardContainer";
import { cardContainerStylesField } from "../parts/stylesFields";


export default function () {
  return {
    type: 'cta',
    variant: 'hero',
    template: CtaAHero,
    presets,
    params: {
      'bgStyles': {
        name: 'Fondo',
        type: 'part',
        part: 'background'
      },
      'cardContainerStyles': cardContainerStylesField,
      'titleStyles': { // Estilos para el título
        name: 'Título',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      'titleCardStyles': { // Estilos para la tarjeta del título
        name: 'Tarjeta del Título',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },
      'subtitleStyles': { // Estilos para el subtítulo
        name: 'Subtítulo',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      'subtitleCardStyles': { // Estilos para la tarjeta del subtítulo
        name: 'Tarjeta del Subtítulo',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },
      'contentStyles': { // Estilos para el contenido
        name: 'Contenido',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      'contentCardStyles': { // Estilos para la tarjeta del contenido
        name: 'Tarjeta del Contenido',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },
      'btnListStyles': {
        name: 'Botones',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      }
    },
    strings: {
      // title, subtitle, content, btnList
      'title': {
        type: 'textArea',
        name: 'Título'
      },
      'subtitle': {
        type: 'textArea',
        name: 'Subtítulo'
      },
      'content': {
        type: 'textArea',
        design: 'notion',
        name: 'Contenido'
      },
      'btnList': {
        type: 'collection',
        name: 'Botones',
        fields: sectionCardBtnsFields
      },
    },
    partsMap: [{
      'label': 'Bloque',
      'background': ['bgStyles'],
      'card': ['cardContainerStyles']
    }, 
    {
      'label': 'Título',
      'strings': ['title'],
      'text': ['titleStyles'],
      'card': ['titleCardStyles']
    }, 
    {
      'label': 'Subtítulo',
      'strings': ['subtitle'],
      'text': ['subtitleStyles'],
      'card': ['subtitleCardStyles']
    },
    {
      'label': 'Contenido',
      'strings': ['content'],
      'text': ['contentStyles'],
      'card': ['contentCardStyles']
    },
    {
      'label': 'Botones',
      'strings': ['btnList'],
      'card': ['btnListStyles']
    },]
  };
};

export function CtaAHero(props) {
  let {
    strings, specDesign, bgStyles, history, title, subtitle, content, btnList,
    cardContainerStyles, titleStyles, titleCardStyles, contentStyles,
    subtitleStyles, subtitleCardStyles,
    contentCardStyles, btnListStyles
  } = props;

  return (
    <Background params={bgStyles} classes={{ bgContainer: "snap-start" }} specDesign={specDesign}>
      {/* <div className="py-32 xs:py-40 lg:py-44 px-4 xs:px-10 md:px-20"></div> */}
      <CardContainer params={cardContainerStyles} specDesign={specDesign} classes={{inCard: 'space-y-2'}}>
        {title ? (
          <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[100ms]">
            <Card params={titleCardStyles} specDesign={specDesign}> {/* Tarjeta del título */}
              <Text textParams={titleStyles} classDefault="text-4xl md:text-4xl font-bold font-brand-main text-black" specDesign={specDesign}>
                {lineBreaks(compileStringTemplate(title, specDesign.stringsVars))}
              </Text>
            </Card>
          </AnimationAddClassWhenVisible>
        ) : null}
        {subtitle ? (
          <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[300ms]">
            <Card params={subtitleCardStyles} specDesign={specDesign}>
              <Text textParams={subtitleStyles} classDefault="text-lg lg:text-xl font-brand-main text-black md:my-2" specDesign={specDesign}>
                {lineBreaks(compileStringTemplate(subtitle, specDesign.stringsVars))}
              </Text>
            </Card>
          </AnimationAddClassWhenVisible>
        ) : null}
        {content ? (
          <NotionShowLazy value={content} render={(children) => (
            <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[500ms]">
              <Card params={contentCardStyles} specDesign={specDesign}>
                <Text textParams={contentStyles} specDesign={specDesign}>
                  {children}
                </Text>
              </Card>
            </AnimationAddClassWhenVisible>
          )} />
        ) : null}
        {strings?.ctaType === "whatsapp" ? (
          <Card params={btnListStyles} specDesign={specDesign}>
            <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[300ms]">
              <ContactBTN className={'mx-auto'} label={lineBreaks(strings.ctaLabel)} design="large" />
            </AnimationAddClassWhenVisible>
          </Card>
        ) : null}
        {strings?.ctaType === "signup" ? (
          <Card params={btnListStyles} specDesign={specDesign}>
            <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[300ms]">
              <div>
                <BtnSignUpModal strings={strings} history={history} design="large" classes={{btnButton: 'mx-auto'}} />
              </div>
            </AnimationAddClassWhenVisible>
          </Card>
        ) : null}
        {btnList?.length ? (
          <Card params={btnListStyles} specDesign={specDesign}>
            <AnimationAddClassWhenVisible classToAdd="transition-opacity" classDefault="duration-1000 delay-[700ms]">
              <div>
                <BtnsList
                  {...props}
                  items={btnList}
                />
              </div>
            </AnimationAddClassWhenVisible>
          </Card>
        ) : null}
      </CardContainer> {/* Cierre del contenedor principal */}
    </Background>
  );
}

const presets = {
  'base': {
    "bgStyles": [
      {
        "bgColor": "#ffffff",
        "screen": "xxs",
        "bgVideoUrl": "",
        "bgOverlayAlpha": 0,
        "type": "color"
      }
    ],
    "cardContainerStyles": [
      {
        "screen": "xxs",
        "marginX": 4,
        "marginY": 24
      },
      {
        "screen": "md",
        "marginX": 12,
        "marginY": 32
      },
      {
        "padding": 40,
        "screen": "lg",
        "marginY": 40
      },
      {
        "screen": "xl",
        "marginX": 72,
        "marginY": 40
      },
      {
        "screen": "2xl",
        "marginX": 72,
        "marginY": 56
      }
    ],
    "titleStyles": [
      {
        "textSize": "3xl",
        "fontVariations": [
          "bold"
        ],
        "textAlign": "left",
        "screen": "xxs",
        "textColor": "#000000",
        "font": "main"
      },
      {
        "textSize": "4xl",
        "screen": "md"
      },
      {
        "textSize": "5xl",
        "screen": "2xl"
      }
    ],
    "subtitleStyles": [
      {
        "textSize": "2xl",
        "fontVariations": [
          "bold"
        ],
        "textAlign": "left",
        "screen": "xxs",
        "textColor": "#808080",
        "font": "secondary"
      },
      {
        "textSize": "3xl",
        "screen": "md"
      },
      {
        "textSize": "4xl",
        "screen": "lg"
      },
    ],
    "contentStyles": [
      {
        "textSize": "base",
        "textAlign": "left",
        "screen": "xxs",
        "textColor": "#000000",
      },
    ],
  },

  'base-simple': {
    "cardContainerStyles": [
      {
        "marginY": 24,
        "marginX": 4,
        "screen": "xxs"
      },
      {
        "screen": "md",
        "marginX": 12,
        "marginY": 32
      },
      {
        "padding": 40,
        "screen": "lg",
        "marginY": 40
      },
      {
        "marginX": 72,
        "marginY": 40,
        "screen": "xl"
      },
      {
        "marginY": 56,
        "marginX": 72,
        "screen": "2xl"
      }
    ],
    "containerStyles": [
      {
        "alignY": "center",
        "border": "none",
        "round": "none",
        "alignX": "center",
        "screen": "xxs",
        "cardWidth": "full",
        "blockHeight": "x1",
        "padding": 8
      },
      {
        "round": null,
        "screen": "lg",
        "blockHeight": "x1",
        "cardWidth": "1/2"
      }
    ],
    "subtitleStyles": [
      {
        "textColor": "#808080",
        "screen": "xxs",
        "fontVariations": [
          "bold"
        ],
        "font": "secondary",
        "textSize": "2xl",
        "textAlign": "left"
      },
      {
        "screen": "md",
        "textSize": "3xl"
      },
      {
        "screen": "lg",
        "textSize": "4xl"
      }
    ],
    "bgStyles": [
      {
        "screen": "xxs",
        "bgColor": "secondary",
        "type": "color",
        "bgVideoUrl": "",
        "bgOverlayAlpha": 0
      }
    ],
    "titleStyles": [
      {
        "font": "main",
        "textAlign": "center",
        "fontVariations": [
          "bold"
        ],
        "screen": "xxs",
        "textSize": "3xl",
        "textColor": "primary-contrast"
      },
      {
        "textSize": "4xl",
        "screen": "md"
      },
      {
        "textSize": "5xl",
        "screen": "2xl"
      }
    ],
    "contentStyles": [
      {
        "textColor": "primary-contrast",
        "font": "secondary",
        "textSize": "base",
        "textAlign": "left",
        "fontVariations": [
          "normal"
        ],
        "screen": "xxs"
      }
    ],
    "contentCardStyles": [
      {
        "align": "center",
        "screen": "xxs"
      }
    ],
    "btnListStyles": [
      {
        "align": "center",
        "screen": "xxs"
      }
    ]
  },

  'base-details': {
    "bgStyles": [
      {
        "bgColor": "primary",
        "screen": "xxs",
        "bgVideoUrl": "",
        "bgOverlayAlpha": 0,
        "type": "color"
      }
    ],
    "cardContainerStyles": [
      {
        "round": "2xl",
        "padding": 4,
        "screen": "xxs",
        "marginY": 16,
        "marginX": 10,
        "cardWidth": "fit",
        "shadow": "lg",
        "bgParams": {
          "type": "color",
          "bgVideoUrl": "",
          "bgOverlayAlpha": 0,
          "bgColor": "#ffffff"
        }
      },
      {
        "marginX": 12,
        "marginY": 32,
        "screen": "md"
      },
      {
        "cardWidth": "1/2",
        "padding": 6,
        "marginY": 40,
        "shadow": "lg",
        "screen": "lg"
      },
      {
        "padding": 6,
        "screen": "xl",
        "marginY": 40,
        "marginX": 72
      },
      {
        "marginY": 56,
        "screen": "2xl",
        "marginX": 72
      }
    ],
    "titleStyles": [
      {
        "textAlign": "center",
        "textSize": "3xl",
        "font": "main",
        "screen": "xxs",
        "textColor": "primary",
        "fontVariations": [
          "bold"
        ]
      },
      {
        "textSize": "4xl",
        "screen": "md"
      },
      {
        "screen": "2xl",
        "textSize": "5xl"
      }
    ],
    "subtitleStyles": [
      {
        "textColor": "#000000",
        "font": "secondary",
        "textAlign": "center",
        "screen": "xxs",
        "textSize": "xl"
      },
      {
        "screen": "md",
        "textSize": "2xl"
      },
      {
        "textSize": "3xl",
        "screen": "lg"
      }
    ],
    "contentStyles": [
      {
        "screen": "xxs",
        "fontVariations": [
          "normal"
        ],
        "textAlign": "left",
        "font": "secondary",
        "textColor": "#000000",
        "textSize": "base"
      }
    ],
    "contentCardStyles": [
      {
        "align": "center",
        "screen": "xxs"
      }
    ],
    "btnListStyles": [
      {
        "align": "center",
        "screen": "xxs"
      }
    ]
  },

  'base-img-extended':  {
    "bgStyles": [
      {
        "bgVideoUrl": "",
        "bgUrl": [
          ""
        ],
        "type": "image",
        "screen": "xxs",
        "bgColor": "secondary",
        "bgOverlayAlpha": 0
      }
    ],
    "cardContainerStyles": [
      {
        "padding": "8",
        "borderColor": "secondary",
        "alignY": "bottom",
        "bgParams": {
          "bgColor": "secondary",
          "type": "color",
          "bgVideoUrl": "",
          "bgOverlayAlpha": 0
        },
        "alignX": "center",
        "cardWidth": "fit",
        "align": "center",
        "blockHeight": "x1",
        "screen": "xxs",
        "round": "2xl",
        "shadow": "xl",
        "marginX": 8,
        "width": "fit"
      },
      {
        "screen": "md",
        "blockHeight": "x2"
      }
    ],
    "titleStyles": [
      {
        "textAlign": "center",
        "fontVariations": [
          "bold"
        ],
        "screen": "xxs",
        "textColor": "primary-contrast",
        "font": "main",
        "textSize": "4xl"
      }
    ],
    "subtitleStyles": [
      {
        "fontVariations": [
          "normal"
        ],
        "font": "secondary",
        "textAlign": "center",
        "textColor": "primary-contrast",
        "textSize": "2xl",
        "screen": "xxs"
      }
    ],
    "titleCardStyles": [
      {
        "align": "center",
        "screen": "xxs"
      }
    ],
    "subtitleCardStyles": [
      {
        "screen": "xxs",
        "align": "center"
      }
    ],
    "contentStyles": [
      {
        "textColor": "primary-contrast",
        "font": "secondary",
        "textSize": "base",
        "textAlign": "left",
        "fontVariations": [
          "normal"
        ],
        "screen": "xxs"
      }
    ],
    "contentCardStyles": [
      {
        "align": "center",
        "screen": "xxs"
      }
    ],
    "btnListStyles": [
      {
        "align": "center",
        "screen": "xxs"
      }
    ]
  },


};