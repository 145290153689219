import _ from 'lodash';
import { RouteEntityModules } from './RouteEntityModules';
import { RouteEntityUpdates } from './RouteEntityUpdates';


export default function ({}) {
  return {
    name: 'Entity',
    slug: 'entity',
    scope: 'global',
    bundles: ['core'],
    entities: [
      'entities',
      'taxonomyTypes',
      'filterMenu'
    ],
    permissions: [
      { slug: 'entities', label: 'Entities', actionsToAdd: ['export'] },
      { slug: 'taxonomyTypes', label: 'TaxonomyTypes' },
      { slug: 'filterMenu', label: 'FilterMenu' }
    ],
    routesAdmin: {
      'entities': {
        'modules': {
          permission: { resource: 'entities', action: 'list' },
          Content: RouteEntityModules
        },
        'updates': {
          permission: { resource: 'entities', action: 'update' },
          Content: RouteEntityUpdates
        }
      }
    }
  };
};