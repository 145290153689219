import React, { lazy, Suspense } from 'react';


const Markdown = lazy(() => import('react-markdown'));

const MarkdownLazy = ({ render, fallback, ...props }) => {
  return (
    <Suspense fallback={fallback}>
      {render ? render(<Markdown {...props} />) : <Markdown {...props} />}
    </Suspense>
  );
};

export default MarkdownLazy;