export const circleColor = '#1ac3ff';

export const getLocalityOfPlace = (place) => {
  return place.address_components.find(component =>
    component.types.includes('locality')
  );
};

export const getFromPlace = (place, type) => {
  return place?.address_components?.find(component =>
    component.types.includes(type)
  );
};