import _ from 'lodash';
import Model from "../../libs/ModelClass";
import config from "../../config";
import specTheme from "../../customModules/specTheme";
import { specDesign } from '../../customModules/specSite';


export const fetchBrand = async ({ instance }) => {
  if (instance === 'main') {
    return {
      brandSpecs: specDesign,
      brandDocs: {}
    };
  }
  const InstanceModel = Model.extend(config.modules.instances.instancesEntitySlug);
  const BrandModel = Model.extend(config.modules.instances.instancesBrandsEntitySlug);
  const ColorsPaletteModel = Model.extend(config.modules.instances.instancesColorsPalettesEntitySlug);
  const LayoutBlocksModel = Model.extend(config.modules.instances.instancesLayoutBlocksEntitySlug);
  const MainPartsModel = Model.extend(config.modules.instances.instancesMainPartsEntitySlug);
  const MainStringsModel = Model.extend(config.modules.instances.instancesMainStringsEntitySlug);
  const instanceDoc = await InstanceModel.findOneBy('hash', instance);
  if (instanceDoc) {
    const brandDoc = await BrandModel.findOneBy('instanceId', instanceDoc.id);
    if (brandDoc) {
      if (!brandDoc.data.colorPaletteId || !brandDoc.data.layoutBlocksId || !brandDoc.data.mainPartsId || !brandDoc.data.mainStringsId) return;
      // fetch in parallel and spread values
      const [colorPalette, layoutBlocksDoc, mainPartsDoc, mainStringsDoc] = await Promise.all([
        ColorsPaletteModel.findById(brandDoc.data.colorPaletteId),
        LayoutBlocksModel.findById(brandDoc.data.layoutBlocksId),
        MainPartsModel.findById(brandDoc.data.mainPartsId),
        MainStringsModel.findById(brandDoc.data.mainStringsId)
      ]);
      let countryDetail = {
        countryId: mainStringsDoc.data['countryId'],
        iso2: mainStringsDoc.data['country_iso2'], 
        iso3: mainStringsDoc.data['country_iso3'],
        symbol: mainStringsDoc.data['country_currency_symbol'],
        currency: mainStringsDoc.data['country_currency']
      };
      mainStringsDoc.data['countryFields'] = countryDetail; // normalized data

      let brandDocs = {
        instance: instanceDoc,
        brand: brandDoc,
        colorPalette: colorPalette,
        layoutBlocks: layoutBlocksDoc,
        mainParts: mainPartsDoc,
        mainStrings: mainStringsDoc
      };

      const brandSpecs = {
        colors: _.defaults(colorPalette.data.colorsPalette, specTheme.colors),
        stringsVars: {
          instance: instanceDoc.data,
          brand: mainStringsDoc.data,
        },
        logo: {
          profileImg: brandDoc.data.profileImg?.length ? brandDoc.data.profileImg[0] : null,
          logoDark: brandDoc.data.logoDark?.length ? brandDoc.data.logoDark[0] : null,
          logoLight: brandDoc.data.logoLight?.length ? brandDoc.data.logoLight[0] : null
        },
        fonts: {
          main: mainPartsDoc.data.mainFont,
          secondary: mainPartsDoc.data.secondaryFont
        },
        blocks: {
          header: layoutBlocksDoc.data.blocksHeader,
          footer: layoutBlocksDoc.data.blocksFooter,
          menuMain: {
            ...layoutBlocksDoc.data.menuMain
          }
        }
      };
      
      return {
        brandSpecs,
        brandDocs
      };
    }
  }
  return null;
};