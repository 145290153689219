import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import Model from "../../libs/ModelClass";
import { useModule } from "../../libs/ModuleContext";
import { usePartOfModule } from "../../components/Module/PartOfModule";
import { parseAttrValParams, stringifyAttrValParams, trimSlash } from '../../libs/utils';
import useBreakpoints from '../../libs/useBreakpoints';
import config from "../../config";
import { useAuth } from "../user/AuthContext";
import { useModuleModels } from "../../libs/useModuleModels";
import { Page401, Page404 } from "./ErrorPages";
import { usePanel } from "./usePanel";
import { withOutPrefix, withPrefix } from "../instance/utilsInstance";


export function useParsedParams(props) {
  const { match, history } = props;
  const location = useLocation();
  let { instance, entitySlug, action, 0: attrValParams } = match.params;
  instance = instance || 'main';

  const parsedParams = useMemo(() => {
    return {
      ...parseAttrValParams( attrValParams ),
      ...parseAttrValParams( location.hash.replace('#/', '') )
    };
  }, [location.hash, match.url]);

  const pushWithParams = ({ path, ...params}) => {
    let route = `${trimSlash(path || location.pathname, true)}/#/${stringifyAttrValParams(params)}`;
    history.push(route);
  };

  return {
    parsedParams,
    instance, entitySlug, action, attrValParams,
    pushWithParams
  };
}

export function AdminModulePage(props) {
  const { match, history } = props;
  const { isAllowed } = useModule();
  const { isMinBreakpointActive } = useBreakpoints();
  const location = useLocation();
  const { userAuth } = useAuth();
  const {
    versionCurrent,
    settings,
    isUserMenuPopoverOpen, setIsUserMenuPopoverOpen,
    selectedInstance, setSelectedInstance
  } = usePanel();
  const { ModuleModel } = useModuleModels();
  
  const { parsedParams, instance, entitySlug, action } = useParsedParams(props);
  
  const routeOfModule = usePartOfModule({
    type: 'routeAdmin',
    entitySlug,
    action
  });

  if (!routeOfModule?.Content) {
    return <Page404 {...props} />;
  }

  let hasAccess = false;
  if (routeOfModule.permission.resource.includes('.')) {
    hasAccess = isAllowed(
      withPrefix(instance, withOutPrefix(routeOfModule.permission.resource)),
      routeOfModule.permission.action
    )
  }
  else {
    hasAccess = isAllowed(
      routeOfModule.permission.resource,
      routeOfModule.permission.action
    )
  }

  return (
    hasAccess ? (
      <routeOfModule.Content {...{
        instance,
        parsedParams,
        entitySlug,
        action,
        config,
        user: userAuth,
        userAuth,
        isAllowed,
        Model,
        ModuleModel,
        isMinBreakpointActive,
        location,
        history,
        match,
        // panel
        versionCurrent,
        settings,
        isUserMenuPopoverOpen, setIsUserMenuPopoverOpen,
        selectedInstance, setSelectedInstance
       }} />
    ) : (
      <Page401 {...props} />
    )
  );
}
