import { useEffect, useMemo, useState } from "react";
import Model from "../../libs/ModelClass";
import { Page404 } from "../../customModules/layout/ErrorPages";
import BadgeLoading from "../../components/ui/BadgeLoading";
import { PageStack } from "../../customModules/layout/RouteLayoutBlock";
import { parseAttrValParams } from "../../libs/utils";
import { DesignLoader } from "./DesignLoader";


export const RouteRender = (props) => {
  let { instance, pageSlug, match, location } = props;
  const [ page, setPage ] = useState();
  const [ stack, setStack ] = useState();
  pageSlug = pageSlug || match.params.action;
  
  useEffect(() => {
    fetchPage();
  }, [location.pathame, location.hash]);

  const parsedParams = useMemo(() => {
    return parseAttrValParams( location.hash.replace('#/', '') );
  }, [location.hash]);

  const fetchPage = async () => {
    const PageModel = Model.extend(instance ? instance+'.pages' : 'main.pages');
    const docs = await PageModel.filterByAttributes({
      'url': pageSlug || 'home',
      'deleted': 'false'
    });
    setPage(docs?.length ? docs[0] : '404');
    setStack(docs[0]?.data?.stack || []);
  };
  
  if (!page) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  if (page === '404') {
    return (
      <DesignLoader
        {...props}
        render={Page404}
      />
    );
  }

  return (
    <PageStack
      {...props}
      parsedParams={parsedParams}
      pageDoc={page}
      specStack={stack}
    />
  );
}