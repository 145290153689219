import { routePageStack } from "../layout/RouteLayoutBlock";
import { specDesign, stringsMapLayoutBlocks } from "../specSite";
import { CustomHeader } from "./CustomBlocks/BlockPageHeaderAHero";
import { trendingUpOutline, starOutline } from "ionicons/icons";
import { TbPigMoney } from "react-icons/tb";


export const blocks = {
  headerHero: {
    title: "Presenta\ntus productos\ncon nuestro\ncatálogo virtual",
    subtitle: "La herramienta ideal\npara gestionar y\nmostrar tu inventario",
    btnList: [
      {
        ctaLabel: "Acceder",
        ctaType: "signup",
        ctaSignUp: {
          ctaLabel: "Acceder",
          modalTitle: "Crea tu catálogo",
          modalContent: `
### Es fácil!

- Inicia sesión con Gmail
- Completa tus datos de usuario
- Personaliza tu catálogo
- Carga tus productos y servicios
- ¡Listo!

      `
        },
        btnSize: "medium",
        btnColor: "light",
        btnFill: "solid"
      },
      // {
      //   ctaLabel: "Contactar",
      //   ctaType: "whatsapp",
      //   ctaWhatsapp: {
      //     phoneMessage: 'Hola Tranqui Pro! quiero más información sobre el Catálogo Virtual',
      //     useBrandPhone: true
      //   },
      //   btnSize: "medium",
      //   btnColor: "light",
      //   btnFill: "solid"
      // }
    ]
    // ctaLabel: "Crear catálogo",
    // ctaType: "signup",
    // ...stringsMapLayoutBlocks.modalSignUp
  },
  // beneficios del producto
  textGridWithTitle: {
    items: [
      {
        ionIcon: trendingUpOutline,
        title: "Oportunidades\nde venta",
        content: "Organiza y haz accesibles tus productos para maximizar las oportunidades de negocio",
      },
      {
        ionIcon: starOutline,
        title: "Ofrece una\nexperiencia moderna",
        content: "Impresiona a los clientes con un catálogo digital\ninteractivo y profesional",
      },
      {
        faIcon: TbPigMoney,
        title: "Ahorra tiempo\ny recursos",
        content: "Centraliza todas las fotos en un solo lugar para facilitar la gestión y visualización para el negocio y los clientes",
      },      
    ],
  },
  
  customerReviews: {
    title: "Lo que dicen nuestros clientes",
    subtitle: "Testimonios reales de empresarios que han transformado su negocio con nuestro Catálogo Virtual",
    items: [
      {
        title: "Eficiencia en Inventario",
        content: "Con el Catálogo Virtual, gestionar mi inventario es ahora más fácil y eficiente, lo que ha llevado a un aumento significativo en mis ventas"
      },
      {
        title: "Proceso de Ventas Simplificado",
        content: "El Catálogo Virtual ha simplificado mi proceso de ventas, permitiéndome presentar mis productos de manera atractiva y profesional a mis clientes"
      },
      {
        title: "Acceso desde Cualquier Dispositivo",
        content: "La adaptabilidad del Catálogo Virtual a dispositivos móviles ha facilitado que mis clientes puedan consultar mi catálogo y hacer pedidos desde cualquier lugar y en cualquier momento"
      },       
      {
        title: "Variedad de Productos",
        content: "Gracias al Catálogo Virtual, puedo mostrar mi variedad de productos de manera organizada y atractiva, lo que ha contribuido a aumentar mis ventas"
      },
    ],    
  },

  // Portfolio
  portfolio: {
    title: "Nuestro Portafolio",
    subtitle: "Descubre algunos de nuestros proyectos destacados",
    // ctaLabel: "Únete a la Red",
    // ctaType: "signup",
    ctaLabel: "Contactar",
    ctaType: "whatsapp",
    ...stringsMapLayoutBlocks.modalSignUp,
    items: [
      {
        title: "Web Institucional",
        content: "Destaca las empresas de tus clientes y convierte visitantes en clientes con nuestra solución de Web Institucional de ventas, moderna y eficaz",
        ctaLink: "/m/page/web-institucional",
        image: "/images/landing-thumb-02.png"
      },
      {
        title: "Catálogo Virtual",
        content: "Facilita que tus clientes presenten sus productos de forma efectiva, gestionen pedidos de manera sencilla y ordenada, todo con filtros personalizables",
        ctaLink: "/m/page/catalogo",
        image: "/images/catalogo-thumb.png"
      }
    ]
  },

  frequentAnswers: {
    title: "Preguntas frecuentes",
    items: [
      {
        title: "¿Cómo puedo ofrecer el Catálogo Virtual a mis clientes?",
        content: "¡Es sencillo! Únete a nuestra red como colaborador y podrás ofrecer el Catálogo Virtual a tus clientes sin recargo de precio, agregando valor a tus servicios",
      },
      {
        title: "¿Qué dispositivos pueden utilizar el Catálogo Virtual?",
        content: "El Catálogo Virtual es compatible con todos los dispositivos modernos, incluyendo computadoras de escritorio, tablets y smartphones, permitiendo a tus clientes acceder desde cualquier lugar",
      },
      {
        title: "¿Ofrecen soporte técnico para mis clientes?",
        content: "Sí, nuestro equipo de soporte técnico está disponible para ayudarte a resolver cualquier duda o problema que puedas tener, garantizando la satisfacción de tus clientes",
      },
      {
        title: "¿Puedo personalizar el Catálogo Virtual según las necesidades de mis clientes?",
        content: "¡Por supuesto! Podemos adaptar el diseño y las funcionalidades del Catálogo Virtual para satisfacer las necesidades específicas de tus clientes, ofreciendo soluciones personalizadas",
      },
      {
        title: "¿Cómo gestionan la seguridad y privacidad de los datos de mis clientes?",
        content: "Nos comprometemos a implementar medidas robustas de seguridad y privacidad para proteger los datos de tus clientes, cumpliendo estándares de seguridad y garantizando la confidencialidad de la información"
      },
      {
        title: "¿Qué requisitos necesito cumplir para unirme a la red de colaboradores de Tranqui Pro?",
        content: "Para unirte a nuestra red de colaboradores, necesitas ser una agencia o profesional autónomo con experiencia en tecnología y estar dispuesto a ofrecer soluciones de calidad a tus clientes, siguiendo nuestros estándares y procesos establecidos"
      },
    ]    
  },
  // ingresar
  ctaSignUp: {
    title: "Únete a Nuestra Red de Colaboradores",
    subtitle: "Descubre cómo puedes formar parte de nuestra comunidad y beneficiarte de nuestras soluciones tecnológicas",
    btnList: [
      {
        ctaLabel: "Contactar",
        ctaType: "whatsapp",
        ctaWhatsapp: {
          phoneMessage: 'Hola Tranqui! Me interesa saber más sobre la red y los servicios que ofrecen',
          useBrandPhone: true
        },
        btnSize: "medium",
        btnColor: "light",
        btnFill: "solid"
      }
    ]
    // ctaType: "signup",
    // ctaLabel: "Unirme y crear catálogo",
    // ...stringsMapLayoutBlocks.modalSignUp
  },
  // footer
  footer: {
    ...stringsMapLayoutBlocks.footer,
    btnList: [
      {
        ctaLabel: "Contactar",
        ctaType: "whatsapp",
        ctaWhatsapp: {
          phoneMessage: 'Hola Tranqui! Me interesa saber más sobre los servicios que ofrecen',
          useBrandPhone: true
        },
        btnSize: "medium",
        btnColor: "light",
        btnFill: "solid"
      }
    ]
  }
};

const specStack = [
  // {
  //   // custom block example
  //   strings: blocks.headerHero,
  //   template: HeaderHeroStaticBlock
  // },
  {
    type: 'pageHeader',
    variant: 'heroStatic',
    strings: blocks.headerHero,
    template: CustomHeader,
    params: {
      bgStyles: [{
        screen: 'xxs',
        type: 'gradient',
        bgColorDirection: 'to bottom right',
        bgColorFrom: 'primary', 
        bgColorTo: 'secondary',
        // classes: {
        //   bgContainer: 'h-96'
        // }
      }],
      logoStyles: [{
        screen: 'xxs',
        size: 10,
        source: 'logoLight',
        resolution: 'full',
        shape: 'transparent',
        round: 'none',
        showSiteName: false
      }, {
        screen: 'md',
        size: 12
      }, {
        screen: 'lg',
        size: 14
      }],
    }
  },
  {
    type: 'textGrid',
    variant: 'compact',
    strings: blocks.textGridWithTitle,
    cardContainerStyles: [{
      screen: 'xxs',
      marginY: 24,
      marginX: 8
    }, {
      screen: 'md',
      marginY: 32,
      marginX: 12
    }, {
      screen: 'lg',
      marginY: 40,
      marginX: 40
    }, {
      screen: 'xl',
      marginY: 40,
      marginX: 64
    }, {
      screen: '2xl',
      marginY: 56,
      marginX: 72
    }],
    "titleStyles": [
      {
        "textAlign": "center",
        "textColor": "#000000",
        "screen": "xxs",
        "font": "secondary",
        "textSize": "2xl",
        "fontVariations": [
          "bold"
        ]
      },
      {
        "textSize": "2xl",
        "screen": "2xl"
      }
    ],
    "itemCardStyles": [
      {
        "align": "center",
        "screen": "xxs"
      }
    ],
    "imgCardStyles": [
      {
        "width": "fit",
        "align": "center",
        "marginY": 2,
        "screen": "xxs"
      }
    ],
    "titleCardStyles": [
      {
        "screen": "xxs",
        "align": "center",
        "width": "full"
      }
    ],
    "contentStyles": [
      {
        "screen": "xxs",
        "textSize": "base",
        "textColor": "black",
        "fontVariations": [
          "normal"
        ],
        "font": "secondary",
        "textAlign": "center"
      }
    ],
  },
  {
    type: 'cta',
    variant: 'hero',
    strings: blocks.ctaSignUp,
    bgStyles: [{
      screen: 'xxs',
      type: 'gradient',
      bgColorDirection: 'to bottom right',
      bgColorFrom: 'primary', 
      bgColorTo: 'secondary',
      // classes: {
      //   bgContainer: 'h-96'
      // }
    }],
    cardContainerStyles: [{
      screen: 'xxs',
      marginY: 24,
      marginX: 8
    }, {
      screen: 'md',
      marginY: 32,
      marginX: 12
    }, {
      screen: 'lg',
      marginY: 40,
      marginX: 40
    }, {
      screen: 'xl',
      marginY: 40,
      marginX: 64
    }, {
      screen: '2xl',
      marginY: 56,
      marginX: 72
    }],
    "titleStyles": [
      {
        "screen": "xxs",
        "textSize": "4xl",
        "font": "secondary",
        "textAlign": "left",
        "textColor": "primary-contrast",
        "fontVariations": [
          "bold"
        ]
      },
    ],
    "subtitleStyles": [
      {
        "screen": "xxs",
        "textSize": "lg",
        "font": "secondary",
        "textAlign": "left",
        "textColor": "primary-contrast"
      },
    ],
  },
  {
    type: 'reviews',
    variant: 'grid',
    strings: blocks.customerReviews,
    cardContainerStyles: [{
      screen: 'xxs',
      marginY: 24,
      marginX: 8
    }, {
      screen: 'md',
      marginY: 32,
      marginX: 12
    }, {
      screen: 'lg',
      marginY: 40,
      marginX: 40
    }, {
      screen: 'xl',
      marginY: 40,
      marginX: 64
    }, {
      screen: '2xl',
      marginY: 56,
      marginX: 72
    }]
  },
  // {
  //   type: 'ctaMenu',
  //   variant: 'grid',
  //   strings: blocks.portfolio
  // },
  {
    type: 'frecuentAnswers',
    variant: 'columnToggle',
    strings: blocks.frequentAnswers,
    cardContainerStyles: [{
      screen: 'xxs',
      marginY: 24,
      marginX: 8
    }, {
      screen: 'md',
      marginY: 32,
      marginX: 12
    }, {
      screen: 'lg',
      marginY: 40,
      marginX: 40
    }, {
      screen: 'xl',
      marginY: 40,
      marginX: 64
    }, {
      screen: '2xl',
      marginY: 56,
      marginX: 72
    }],
    titleStyles: [
      {
        "textAlign": "center",
        "textColor": "#000000",
        "screen": "xxs",
        "font": "secondary",
        "textSize": "3xl",
        "fontVariations": [
          "bold"
        ]
      }
    ],
    titleCardStyles: [
      {
        "screen": "xxs",
        "align": "center",
        "width": "full",
        "marginY": 4
      }
    ],
    subtitleStyles: [
      {
        "screen": "xxs",
        "textSize": "lg",
        "font": "secondary",
        "textAlign": "left",
        "fontVariations": [
          "bold"
        ],
        "textColor": "black"
      },
    ],
    contentStyles: [
      {
        "screen": "xxs",
        "textSize": "base",
        "font": "secondary",
        "textAlign": "left",
        "textColor": "black"
      },
    ]
  },
  {
    type: 'pageFooter',
    variant: 'linksFixedCTA',
    strings: blocks.footer,
    // params: { showCTA: false }
  },
];

export const RouteCatalogoFree = routePageStack({ specDesign, specStack });