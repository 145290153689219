// 'x0.25', 'x0.5', etc., son relativas al viewport height (vh), implementado con Tailwind

export const blockHeightField = {
  type: 'select',
  design: 'buttons',
  name: 'Alto del bloque',
  options: ['auto', { label: 'contenido', value: 'fit' }, 'full', 'x0.25', 'x0.5', 'x0.75', 'x1', 'x1.25', 'x1.5', 'x2', 'x2.5'],
  descriptionText: 'Selecciona la altura del bloque en relación al tamaño del alto de la ventana.',
  helpText: 'Las opciones van desde "auto" para ajustar automáticamente, hasta "x2.5" para ocupar 250% del alto de la ventana.'
};


export const applyClassHeight = (round, className = '') => {
  if (round) {
    switch(round) {
      case 'auto':
        className += ' h-auto';
        break;
      case 'fit':
        className += ' h-fit'; // Asume que 'h-fit' se ajusta al contenido
        break;
      case 'full':
        className += ' h-screen'; // Full height del viewport
        break;
      case 'x0.25':
        className += ' h-[25vh]'; // 25% del viewport height
        break;
      case 'x0.5':
        className += ' h-[50vh]'; // 50% del viewport height
        break;
      case 'x0.75':
        className += ' h-[75vh]'; // 75% del viewport height
        break;
      case 'x1':
        className += ' h-[100vh]'; // 100% del viewport height
        break;
      case 'x1.25':
        className += ' h-[125vh]'; // 125% del viewport height
        break;
      case 'x1.5':
        className += ' h-[150vh]'; // 150% del viewport height
        break;
      case 'x2':
        className += ' h-[200vh]'; // 200% del viewport height
        break;
      case 'x2.5':
        className += ' h-[250vh]'; // 250% del viewport height
        break;
      default:
        className += ' h-auto'; // Valor por defecto
    }
  }

  return className;
};

export const heightClasses = `
  h-auto h-fit h-screen h-[25vh] h-[50vh] h-[75vh] h-[100vh] h-[125vh] h-[150vh] h-[200vh] h-[250vh]
`;
