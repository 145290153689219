import _ from "lodash";
import { createContext, useContext, useEffect, useState, useRef } from "react";
import BadgeLoading from "../../components/ui/BadgeLoading";
import applyLayoutDesign from "../layoutHome/applyLayoutDesign";
import { withProvider } from "../../libs/utils";
import { fetchBrand } from "./fetchBrand";


const designContext = createContext();

export const useDesign = () => {
  const context = useContext(designContext);
  if (!context) throw new Error("There is no DesignStack provider");
  return context;
};

export function DesignProvider({ children }) {
  const [designStore, setDesignStore] = useState({
    'main': 'pending'
  });
  const appliedDesignsRef = useRef(null);

  useEffect(() => {
    // detecta si hay un nuevo pending, si hay entonces hace el fetch
    _.find(designStore, (value, instance) => {
      if (value === 'pending') {
        setDesignStore((prev) => ({ ...prev, [instance]: 'loading' }));
        fetchAndApply(instance);
      }
    });
  }, [designStore]);

  const fetchAndApply = async (instance) => {
    const result = await fetchBrand({ instance });
    if (result) {
      const { brandSpecs, brandDocs } = result;
      setDesignStore((prev) => ({ ...prev, [instance]: { brandSpecs, brandDocs } }));
    } else {
      setDesignStore((prev) => ({ ...prev, [instance]: '404' }));
    }
    appliedDesignsRef.current = null; // marcar como no aplicado
    return result;
  };

  const applyDesign = (instance, force) => {
    if (!designStore[instance]) {
      setDesignStore((prev) => ({ ...prev, [instance]: 'pending' }));
      return { isDesignApplied: false, specDesign: null, brandDocs: null };
    }
    else if (designStore[instance] === 'pending' || designStore[instance] === 'loading') {
      return { isDesignApplied: false, specDesign: null, brandDocs: null };
    }
    else if (_.isObject(designStore[instance])) {
      if (force) {
        return fetchAndApply(instance);
      }
      if (appliedDesignsRef.current !== instance) {
        // Solo aplicar si no se ha aplicado antes
        applyLayoutDesign(designStore[instance].brandSpecs);
        appliedDesignsRef.current = instance; // marcar como aplicado
      }
      return { isDesignApplied: true, specDesign: designStore[instance].brandSpecs, brandDocs: designStore[instance].brandDocs };
    }
    else if (designStore[instance] === '404') {
      if (force) {
        return fetchAndApply(instance);
      }
      else {
        return applyDesign('main');
      }
    }
    else {
      return { isDesignApplied: false, specDesign: null, brandDocs: null };
    }
  };

  return (
    <designContext.Provider
      value={{
        applyDesign,
        designStore
      }}
    >
      {children}
    </designContext.Provider>
  );
}

export const DesignLoader = (props) => {
  const { children, match } = props;
  const { instance } = match.params;

  const { applyDesign } = useDesign();
  const { specDesign, isDesignApplied } = applyDesign(instance || 'main');

  if (!isDesignApplied) {
    return (
      <div className="py-12 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-dark" />
      </div>
    );
  }

  if (props.render) {
    const Render = props.render;
    return (
      <Render {...props} specDesign={specDesign} />
    );
  }

  return children;
};

export const DesignLoaderProvider = withProvider(DesignLoader, DesignProvider);
