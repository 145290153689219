import config from "../../../config";
import Model from "../../../libs/ModelClass";

/**
 * Actualizar taxonomyType de Nombres de logos por color en brands
 */
const newDescriptionText = `Preferentemente SVG o PNG
Tamaño máximo 1 MB`;

export default async function migration() {
  console.log('migration 001-renombrar-logos');

  // Actualizar taxonomyType de Nombres de logos por color en brands
  const EntityModel = Model.extend(config.modules.entity.entitiesEntitySlug);
  const instancesBrandsEntityDocs = await EntityModel.filterByAttributes({
    'nameSlug': config.modules.instances.instancesBrandsEntitySlug,
    'isInstance': 'false',
    'deleted': 'false'
  });
  const instancesBrandsEntityDoc = instancesBrandsEntityDocs?.[0];
  console.log('instancesBrandsEntityDoc', instancesBrandsEntityDoc)
  if (instancesBrandsEntityDoc) {
    const TaxonomyTypeModel = Model.extend(config.modules.entity.taxonomyTypesEntitySlug);
    // Renombrar logoDark
    const logoDarkDocs = await TaxonomyTypeModel.filterByAttributes({
      'nameSlug': 'logoDark',
      'entityId': instancesBrandsEntityDoc.id,
      'deleted': 'false'
    });
    const logoDarkDoc = logoDarkDocs?.[0];
    if (logoDarkDoc) {
      logoDarkDoc.data.name = 'Logo de color oscuro';
      logoDarkDoc.data.param = {
        ...logoDarkDoc.data.param,
        descriptionText: newDescriptionText
      };
      console.log('logoDark found', logoDarkDoc)
      await logoDarkDoc.save();
    }
    else {
      console.log('logoDark not found');
    }
    // Renombrar logoLight
    const logoLightDocs = await TaxonomyTypeModel.filterByAttributes({
      'nameSlug': 'logoLight',
      'entityId': instancesBrandsEntityDoc.id,
      'deleted': 'false'
    });
    const logoLightDoc = logoLightDocs?.[0];
    if (logoLightDoc) {
      logoLightDoc.data.name = 'Logo de color claro';
      logoLightDoc.data.param = {
        ...logoLightDoc.data.param,
        descriptionText: newDescriptionText
      };
      console.log('logoLight found', logoLightDoc)
      await logoLightDoc.save();
    }
    else {
      console.log('logoLight not found');
    }
  }
  else {
    console.log('instancesBrandsEntityDoc not found');
  }
}