import _ from 'lodash';
import config from '../../config';
import { RouteEntityCrudForm } from '../entity/RouteEntityCrudForm';
import { IonAlert } from '@ionic/react';
import { useInstallIntance } from '../panel/Install';
import toast from 'react-hot-toast';
import { urlPrefix, withOutPrefix, withPrefix } from '../instance/utilsInstance';
import { getJson } from '../../libs/utils';
import Model from '../../libs/ModelClass';
import { usePanel } from '../panel/usePanel';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import { useState } from 'react';
import { useAuth } from '../user/AuthContext';
import { AlertLogger, useAlertLogger } from '../panel/AlertLogger';
import { createContractsByBundle } from '../instance/RouteInstanceModules';


const modulesSlugsToInstall = ['cart', 'blockStack'];
const bundlesSlugsToInstall = ['core', 'store'];
const instancesEntitySlug = config.modules.instances.instancesEntitySlug;

const createContractsForNewInstance = async (instanceDoc, settings, pushLog) => {
  await pushLog('Registrando la activación del servicio');
  const planSpec = instanceDoc.data.plan;
  await createContractsByBundle(instanceDoc, planSpec, settings);
};

export const saveRoles = async ({ rolesData, instanceDoc }) => {
  const Role = Model.extend('roles');
  const instance = instanceDoc.data.hash;
  const isMain = instance === 'main';
  rolesData = rolesData.map((data) => {
    let permissions = {};
    // replace placeholder instance prefix
    _.forEach(data.permissions, (actions, resource) => {
      if (_.includes(resource, '.')) {
        resource = withPrefix(instance, withOutPrefix(resource));
      }
      permissions[resource] = actions;
    });
    return {
      ...data,
      permissions,
      nameSlug: isMain ? data.nameSlug : withPrefix(instance, withOutPrefix(data.nameSlug)),
      instanceId: instanceDoc.id
    }
  });
  await Role.createMany(rolesData);
  return rolesData;
};

export const savePages = async ({ pagesData, instanceDoc }) => {
  const instance = instanceDoc.data.hash;
  const PageModel = Model.extend(withPrefix(instance, 'pages'));
  // assign
  pagesData = pagesData.map(({ id, ...data}) => {
    return {
      ...data,
      instanceId: instanceDoc.id
    }
  });
  await PageModel.createMany(pagesData);
  return pagesData;
};

const saveSeedData = async ({ instanceDoc, logger }) => {
  logger && logger(`Registrando datos iniciales`);
  
  // save default roles
  let rolesData = await getJson('/data/user/instanceRoles.json');
  if (rolesData) {
    await saveRoles({ rolesData, instanceDoc });
  }
  
  // save default pages
  let pagesData = await getJson('/data/blockStack/defaultPages.json');
  if (pagesData) {
    await savePages({ pagesData, instanceDoc });
  }
};

export const RouteInstanceCreate = (props) => {
  let { history, userAuth, settings } = props;
  const ownerId = userAuth.userDoc.id;
  const { instancesDocs, setInstancesDocs, setSelectedInstance } = usePanel();
  const { width, height } = useWindowSize();
  const { refreshUser } = useAuth();
  const [ newInstanceDoc, setNewInstanceDoc ] = useState();
  const { pushLog, logs, present, dismiss, isModalOpen } = useAlertLogger();
  const { installInstance } = useInstallIntance({ 
    authData: userAuth,
    modulesSlugsToInstall,
    bundlesSlugsToInstall,
    saveSeedData,
    logger: pushLog
  });
  let backRoute = {
    url: `/a/usersSections/instances`,
    title: 'Instancias'
  };
  const context = {
    entitySlug: instancesEntitySlug,
    docId: 'new',
    defaultBackRoute: backRoute.url,
    breadcrumbs: [
      backRoute,
      {
        title: "Registrar Instancia"
      }
    ],
    onBeforeSave: (modifiedFormValues) => {
      modifiedFormValues.ownerId = ownerId;
      modifiedFormValues.isVisible = true;
    },
    onAfterSave: async (newDoc) => {
      // show installing modal
      try {
        present();
        await installInstance(newDoc);
        await createContractsForNewInstance(newDoc, settings, pushLog);
        await refreshUser(newDoc);
        setInstancesDocs([ newDoc, ...instancesDocs ]);
        setNewInstanceDoc(newDoc);
        dismiss();
      } catch (error) {
        console.error(error);
        toast.error(error.message);
        dismiss();
      }
    }
  };

  return (<>
    {/* show alert with confetti */}
    {newInstanceDoc ? (<>
      <IonAlert
        isOpen={true}
        onDidDismiss={() => setNewInstanceDoc(null)}
        backdropDismiss={false}
        header="Felicidades!"
        subHeader="Tu nueva Instancia está lista"
        buttons={[
          {
            text: 'Personalizar',
            role: 'confirm',
            handler: () => {
              // go to customization
              history.push(urlPrefix(newInstanceDoc.data.hash, '/a/instancesBrands/update'));
            },
          }
        ]}
      />
      <Confetti width={width} height={height} recycle={true} className="!z-[200]" />
    </>) : null}
    {/* Logs */}
    <AlertLogger logs={logs} isOpen={isModalOpen} />
    {/* form */}
    <RouteEntityCrudForm
      {...props}
      instance={'main'}
      entitySlug={instancesEntitySlug}
      context={context}
      exclude={[ 'isVisible' ]}
    />
  </>);
}
