import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { MantineReactTable } from 'mantine-react-table';
import { Box, Button, NumberInput, TextInput, Group, ActionIcon } from '@mantine/core';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { MRT_Localization_ES } from 'mantine-react-table/locales/es';
import { IonButton, IonIcon } from '@ionic/react';
import { createOutline } from 'ionicons/icons';
import { usePrevious } from '../../libs/usePrevious';
import useBreakpoints from '../../libs/useBreakpoints';


const InvoiceItemsTable = ({ value, onChange }) => {
  const valuePrev = usePrevious(value);
  const [items, setItems] = useState(value || []);
  const { isMinBreakpointActive } = useBreakpoints();

  // clear if value = null with useEffect
  useEffect(() => {
    if (_.isEmpty(value) && !_.isEmpty(valuePrev)) {
      setItems([]);
    }
  }, [valuePrev]);

  const addItem = () => {
    const newItem = {
      cantidad: 1,
      descripcion: '',
      precioUnitario: 0,
      precioTotal: 0,
      exentas: 0,
      iva5: 0,
      iva10: 0,
    };
    const updatedItems = [...items, newItem];
    setItems(updatedItems);
    onChange(updatedItems);
  };

  const deleteItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
    onChange(updatedItems);
  };

  const updateItem = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index][field] = value;

    if (field === 'cantidad' || field === 'precioUnitario') {
      updatedItems[index].precioTotal = updatedItems[index].cantidad * updatedItems[index].precioUnitario;
    }

    setItems(updatedItems);
    onChange(updatedItems);
  };

  const handleReorder = (newOrder) => {
    console.log('newOrder', newOrder);
    
  };

  const columns = [
    {
      accessorKey: 'cantidad',
      header: 'Cantidad',
      Cell: ({ row }) => (
        <NumberInput
          value={row.original.cantidad}
          onChange={(val) => updateItem(row.index, 'cantidad', val)}
          min={1}
          styles={{ input: { maxWidth: 80 } }}
        />
      ),
    },
    {
      accessorKey: 'descripcion',
      header: 'Descripción',
      Cell: ({ row }) => (
        <TextInput
          value={row.original.descripcion}
          onChange={(e) => updateItem(row.index, 'descripcion', e.target.value)}
          styles={{ input: { maxWidth: 200 } }}
        />
      ),
    },
    {
      accessorKey: 'precioUnitario',
      header: 'Precio Unitario',
      Cell: ({ row }) => (
        <NumberInput
          value={row.original.precioUnitario}
          onChange={(val) => updateItem(row.index, 'precioUnitario', val)}
          min={0}
          precision={2}
          styles={{ input: { maxWidth: 100 } }}
        />
      ),
    },
    {
      accessorKey: 'precioTotal',
      header: 'Total',
      Cell: ({ row }) => (
        <NumberInput value={row.original.precioTotal} readOnly precision={2} styles={{ input: { maxWidth: 100 } }} />
      ),
    },
    {
      accessorKey: 'taxes',
      header: 'Impuestos',
      Cell: ({ row }) => (
        <NumberInput
          value={row.original.taxes}
          onChange={(val) => updateItem(row.index, 'taxes', val)}
          min={0}
          styles={{ input: { maxWidth: 100 } }}
        />
      ),
    },
    {
      id: 'actions',
      header: 'Acciones',
      Cell: ({ row }) => (
        <ActionIcon color="red" onClick={() => deleteItem(row.index)}>
          <IconTrash size={18} />
        </ActionIcon>
      ),
    },
  ];

  let density = !isMinBreakpointActive('md') ? 'xs' : isMinBreakpointActive('lg') ? 'xl' : 'md';
  console.log('density', density)

  return (
    <>
      <Box style={{ width: '100%', maxWidth: '100%', overflowX: 'auto' }}>
      <MantineReactTable
        columns={columns}
        data={items}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        enablePagination={false}
        enableRowOrdering={true}
        enableRowDragging={true}
        onDraggingRowChange={handleReorder}
        density={density}
        style={{ width: '100%' }}
        localization={MRT_Localization_ES}
      />
      </Box>
      <IonButton className="mt-4" onClick={addItem} color="primary" size="small" fill="outline">
        <IonIcon icon={createOutline} slot="start" size="small" />
        Agregar ítem
      </IonButton>
    </>
  );
};

const RenderForm = (props) => {
  const { value, onChange, form, formApi } = props;

  return (
    <InvoiceItemsTable value={value} onChange={onChange} />
  );
};

const dataTypePaymentConfig = {
  RenderForm
};

export default dataTypePaymentConfig;
