import { registerParts } from "./BlockStack";
import { heightClasses } from "./parts/FieldHeight";
import { roundClasses } from "./parts/FieldRound";
import PartBackground, { backgroundClasses } from "./parts/PartBackground";
import PartBtnList, { btnListClasses } from "./parts/PartBtnList";
import PartCard, { cardClasses } from "./parts/PartCard";
import PartCardContainer, { cardContainerClasses } from "./parts/PartCardContainer";
import PartImage, { imageClasses } from "./parts/PartImage";
import PartLogo from "./parts/PartLogo";
import PartText, { textClasses } from "./parts/PartText";


registerParts([
  PartBackground,
  PartBtnList,
  PartLogo,
  PartText,
  PartCard,
  PartImage,
  PartCardContainer
]);

export const PartsClasses = () => {
  const classes = `
    ${backgroundClasses}
    ${cardContainerClasses}
    ${btnListClasses}
    ${textClasses}
    ${cardClasses}
    ${imageClasses}

    ${roundClasses}
    ${heightClasses}
  `;
  return <div className={classes}></div>;
}
