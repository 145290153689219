import React, { lazy, Suspense } from 'react';


const Render = lazy(() => import('./RawInputColor'));

const RawInputColorLazy = ({ render, fallback, ...props }) => {
  return (
    <Suspense fallback={fallback}>
      {render ? render(<Render {...props} />) : <Render {...props} />}
    </Suspense>
  );
};

export default RawInputColorLazy;