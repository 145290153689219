import { useState } from 'react';
import { AnimationAddClassWhenVisible } from '../../../components/ui/AnimationAddClassWhenVisible';
import { lineBreaks } from '../../../libs/utils';
import { Background } from "../parts/PartBackground";
import { Card } from "../parts/PartCard";
import { Text } from "../parts/PartText";
import { CardContainer } from "../parts/PartCardContainer"; 
import { cardContainerStylesField } from "../parts/stylesFields"; 
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Importa los iconos

export default function () {
  return {
    type: 'frecuentAnswers',
    variant: 'columnToggle',
    template: FrecuentAnswersAColumn,
    presets,
    params: {
      'bgStyles': { 
        name: 'Fondo',
        type: 'part',
        part: 'background'
      },
      'cardContainerStyles': cardContainerStylesField,
      // título de intro
      'titleStyles': { 
        name: 'Título',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      'titleCardStyles': {
        name: 'Tarjeta del Título',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'card'
      },
      // preguntas
      'subtitleStyles': { 
        name: 'Formato del texto de las Preguntas',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
      // respuestas
      'contentStyles': { 
        name: 'Formato del texto de las Respuestas',
        descriptionText: 'Estilos según tamaño de pantalla',
        type: 'part',
        part: 'text'
      },
    },
    // fields of text tab
    strings: {
      title: {
        type: 'text',
        name: 'Titulo'
      },
      // {items: [{title,content}]}
      'items': {
        type: 'collection',
        name: 'Preguntas frecuentes y respuestas',
        fields: {
          'title': {
            type: 'text',
            name: 'Pregunta'
          },
          'content': {
            type: 'text',
            name: 'Respuesta'
          },
        }
      },
    },
    partsMap: [
      {
        'label': 'Bloque', // Grupo principal
        'background': ['bgStyles'], // Estilos de fondo
        'card': ['cardContainerStyles'] // Estilos del contenedor principal
      },
      {
        'label': 'Título', // Grupo para el título
        'strings': ['title'], // Contenido del título
        'text': ['titleStyles'], // Formato del texto del título
        'card': ['titleCardStyles'] // Estilos de la tarjeta del título
      },
      {
        'label': 'Items',
        'strings': ['items'], // Texto de las preguntas y respuestas
      },
      {
        'label': 'Preguntas', // Grupo para las preguntas
        'text': ['subtitleStyles'] // Formato del texto de las preguntas
      },
      {
        'label': 'Respuestas', // Grupo para las respuestas
        'text': ['contentStyles'] // Formato del texto de las respuestas
      }
    ]
  };
};

export function FrecuentAnswersAColumn(props) { 
  let {
    title,
    items,
    bgStyles,
    specDesign,
    cardContainerStyles,
    subtitleStyles,
    contentStyles,
    titleStyles,
    titleCardStyles
  } = props;
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <Background params={bgStyles} classes={{ bgContainer: "snap-start" }} specDesign={specDesign}>
      <CardContainer params={cardContainerStyles} specDesign={specDesign}>
        {title ? (
          <AnimationAddClassWhenVisible classToAdd="animate-fadeIn">
            <Card 
              params={titleCardStyles}
              specDesign={specDesign} 
            >
              <Text 
                textParams={titleStyles}
                specDesign={specDesign} 
                className="text-center"
                classDefault="text-3xl font-bold mb-4"
              >
                {lineBreaks(title)}
              </Text>
            </Card>
          </AnimationAddClassWhenVisible>
        ) : null}
        {/* Questions and Answers (Without Card wrapper) */}
        <div className="grid grid-flow-row grid-cols-1 font-brand-secondary">
          {items?.map((item, index) => (
            <div 
              key={index} 
              className={`
                cursor-pointer 
                border-b 
                border-gray-200 
                py-3 
                px-4 
                transition-colors 
                duration-200 
                ease-in-out 
                ${index === activeIndex ? 'bg-gray-100' : 'hover:bg-gray-50'}
              `}
              onClick={() => handleAccordionClick(index)}
            >
              <div className="flex items-center flex-nowrap gap-4">
                {/* Icono */}
                {index === activeIndex ? (
                  <FaChevronUp className="text-brand-primary" />
                ) : (
                  <FaChevronDown className="text-gray-500" />
                )}

                {/* Pregunta */}
                {item?.title ? (
                  <div className="basis-full">
                    <AnimationAddClassWhenVisible classToAdd="animate-fadeIn">
                      <Text textParams={subtitleStyles} classDefault="text-md md:text-lg font-semibold" specDesign={specDesign}>
                        {lineBreaks(item.title)}
                      </Text>
                    </AnimationAddClassWhenVisible>
                  </div>
                ) : null}
              </div>

              {/* Respuesta (Dentro del área colapsable) */}
              <div
                className={`
                  transition-max-height 
                  duration-300 
                  ease-in-out 
                  overflow-hidden 
                  ${index === activeIndex ? 'max-h-screen' : 'max-h-0'}
                `}
              >
                {item?.content ? (
                  <div className="mt-2">
                    <Text 
                      textParams={contentStyles} 
                      specDesign={specDesign}
                      className="lg:text-md"
                    >
                      {lineBreaks(item.content)}
                    </Text>
                  </div>
                ) : null}
              </div>
            </div> 
          ))}
        </div>
      </CardContainer>
    </Background>
  );
}

const presets = {
  'base': {
    "bgStyles": [
      {
        "bgColor": "#ffffff",
        "screen": "xxs",
        "bgVideoUrl": "",
        "bgOverlayAlpha": 0,
        "type": "color"
      }
    ],
    "cardContainerStyles": [
      {
        "screen": "xxs",
        "marginX": 4,
        "marginY": 24,
        "cardWidth": "full"
      },
      {
        "screen": "md",
        "marginX": 24,
        "marginY": 32
      },
      {
        "padding": 24,
        "screen": "lg",
        "marginY": 40
      },
      {
        "screen": "xl",
        "marginX": 64,
        "marginY": 40
      },
      {
        "screen": "2xl",
        "marginX": 72,
        "marginY": 56
      }
    ],
    "titleCardStyles": [
      {
        "width": "full",
        "marginY": 4,
        "screen": "xxs"
      },
      {
        "screen": "md",
        "marginY": 8
      }
    ],
    "titleStyles": [
      {
        "textSize": "3xl",
        "fontVariations": [
          "bold"
        ],
        "textAlign": "center",
        "screen": "xxs",
        "textColor": "#000000",
        "font": "secondary"
      },
      {
        "textSize": "4xl",
        "screen": "lg",
      },
      {
        "textSize": "5xl",
        "screen": "2xl"
      }
    ],
    "subtitleStyles": [
      {
        "textSize": "lg",
        "textAlign": "center",
        "screen": "xxs",
        "textColor": "#000000",
        "font": "secondary",
        "fontVariations": [
          "bold"
        ],
      }
    ],
    "contentStyles": [
      {
        "textSize": "base",
        "textAlign": "center",
        "screen": "xxs",
        "textColor": "#000000",
        "font": "secondary"
      },
      {
        "textSize": "base",
        "screen": "lg",
        "textAlign": "left",
      }
    ]
  },

  'base-details': {
    "bgStyles": [
      {
        "bgColor": "primary",
        "screen": "xxs",
        "bgVideoUrl": "",
        "bgOverlayAlpha": 0,
        "type": "color"
      }
    ],
    "cardContainerStyles": [
      {
        "round": "2xl",
        "padding": 4,
        "screen": "xxs",
        "marginY": 16,
        "marginX": 10,
        "cardWidth": "fit",
        "shadow": "lg",
        "bgParams": {
          "type": "color",
          "bgVideoUrl": "",
          "bgOverlayAlpha": 0,
          "bgColor": "#ffffff"
        }
      },
      {
        "marginX": 12,
        "marginY": 32,
        "screen": "md"
      },
      {
        "cardWidth": "1/2",
        "padding": 6,
        "marginY": 40,
        "shadow": "lg",
        "screen": "lg"
      },
      {
        "padding": 8,
        "screen": "xl",
        "marginY": 40,
        "marginX": 72
      },
      {
        "padding": 10,
        "marginY": 56,
        "screen": "2xl",
        "marginX": 72
      }
    ],
    "titleCardStyles": [
      {
        "width": "full",
        "marginY": 4,
        "screen": "xxs"
      },
      {
        "screen": "md",
        "marginY": 8
      }
    ],
    "titleStyles": [
      {
        "textSize": "3xl",
        "fontVariations": [
          "bold"
        ],
        "textAlign": "center",
        "screen": "xxs",
        "textColor": "primary",
        "font": "secondary"
      },
      {
        "textSize": "4xl",
        "screen": "lg",
      },
      {
        "textSize": "5xl",
        "screen": "2xl"
      }
    ],
    "subtitleStyles": [
      {
        "textSize": "lg",
        "textAlign": "center",
        "screen": "xxs",
        "textColor": "#000000",
        "font": "secondary",
        "fontVariations": [
          "bold"
        ],
      }
    ],
    "contentStyles": [
      {
        "textSize": "base",
        "textAlign": "center",
        "screen": "xxs",
        "textColor": "#000000",
        "font": "secondary"
      },
      {
        "textSize": "base",
        "screen": "lg",
        "textAlign": "left",
      }
    ]
  },
};