import _ from 'lodash';
import { useState } from 'react';
import { Form } from 'react-final-form';
import toast from 'react-hot-toast';
import { withRouter } from 'react-router-dom';
import IonBtnLoading from './ui/IonBtnLoading';
import { FormChangeSubscriber, FormField } from '../components/Form';
import { IonBadge, IonButtons, IonCard, IonCardContent, IonCardHeader, IonContent, IonFooter, IonHeader, IonItem, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import PartOfModule from './Module/PartOfModule';
import { useModule } from '../libs/ModuleContext';


const SectionCrudForm = (props) => {
  let {
    instance,
    history,
    entityDoc,
    entitySlug,
    match,
    fieldName,
    index,
    doc, // typeof Model
    defaultValue,
    editStyle, // onsite modal route
    isModalOpen = true,
    title,
    onClose,
    onSave,
    fieldsRequired,
    onValidation = (() => null),
    onFormChange,
    FormInputFields, // ({form, values, handleSubmit, submitting, fieldsRequired}) => FormFields inputs
    showTitle = true,
    showToast = true,
    showSaveButton = true,
    showCloseButton = true,
    saveBtnLabel,
    saveBtnColor,
    closeBtnLabel,
    classes = {}
  } = props;

  const [isSaving, setIsSaving] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const { isAllowed } = useModule();

  FormInputFields = FormInputFields || ((props) => (<>
    <FormField name="name" title="Nombre" placeholder="Nombre" {...props} />
  </>));

  const handleSubmit = async (values) => {
    setIsSaving(true);
    await onSave(values);
    setIsSaving(false);
    showToast && toast.success('Datos guardados');
  };

  const validateForm = (values) => {
    const errors = {};
    if (fieldsRequired && fieldsRequired.find(field => field === 'name')) {
      if (!values.name) {
        errors.name = ' ';
      }
    }
    onValidation(values, errors);
    setHasErrors(Object.keys(errors).length > 0);
    return errors;
  };

  const handleClose = () => {
    if (editStyle === 'modal' || editStyle === 'onsite') {
      onClose && onClose();
    } 
    else if (editStyle === 'route') {
      history.go(-1);
    }
  };

  const DocTitle = ({ className }) => {
    return (
      <div className={className}>
        <IonTitle className={`flex flex-row items-center p-0 m-0 gap-2`}>
          {title || entityDoc?.data.name}
        </IonTitle>
        {doc?.id ? (
          <IonBadge size="small" color="secondary">Edición</IonBadge>  
        ) : (
          <IonBadge size="small" color="secondary">Nuevo</IonBadge>
        )}
      </div>
    )
  };

  const renderContentModal = ({ form, values, errors, handleSubmit, submitting }) => {    
    return (<>
      {onFormChange ? (
        <FormChangeSubscriber doc={doc} onFormChange={onFormChange} />
      ) : null}
      {showTitle ? (
        <IonHeader className={`p-2 ${classes.formTitle}`}>
          <DocTitle />
        </IonHeader>
      ) : null}
      <IonContent className='ion-padding'>
        <form 
          onSubmit={(event) => {
            // TODO: prevent submit on pressing enter on a field
            event.preventDefault(); // No funciona
          }}
        >
          {FormInputFields ? (<FormInputFields {...props} {...{fieldName, index, instance, entitySlug, form, values, classes, handleSubmit, submitting, fieldsRequired}} />) : ''}
        </form>
      </IonContent>
      <IonFooter className="grid grid-cols-2 gap-4 p-2">
        {showCloseButton ? (
          <IonBtnLoading
            label={closeBtnLabel || 'Cerrar'}
            size="small"
            fill="solid"
            color="light"
            onClick={handleClose}
          />
        ) : (
          <div />
        )}
        {showSaveButton ? (
          <IonBtnLoading
            label={saveBtnLabel || 'Guardar'}
            size="small"
            fill="solid"
            color={saveBtnColor || 'primary'}
            onClickAsync={handleSubmit}
            disabled={submitting || isSaving || hasErrors}
          />
        ) : (
          <div />
        )}
      </IonFooter>
    </>);
  };

  const renderContentCard = (formProps) => { 
    let { form, values, errors, handleSubmit, submitting } = formProps;
    return (<>
      <IonHeader className="sticky top-0">
        <IonToolbar className="px-2 md:px-4">
          <IonButtons slot="start">
            {showTitle ? (
              <DocTitle className="py-1" />
            ) : null}
          </IonButtons>
          <IonButtons slot="end">
            <PartOfModule
              type="crud"
              action="FormToolbarEnd"
              entitySlug={entitySlug}
              param={{ doc, form, values, classes, handleSubmit, submitting, fieldsRequired, instance, entitySlug, isAllowed, history, match }} 
            />
            {showCloseButton ? (
              <IonBtnLoading
                label={closeBtnLabel || 'Cerrar'}
                size="small"
                fill="clear"
                color="medium"
                onClick={handleClose}
              />
            ) : (
              <div />
            )}
            {showSaveButton ? (
              <IonBtnLoading
                label={saveBtnLabel || 'Guardar'}
                size="small"
                fill="solid"
                color={saveBtnColor || 'primary'}
                onClickAsync={handleSubmit}
                disabled={submitting || isSaving || hasErrors}
              />
            ) : (
              <div />
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonCard className={`m-2 md:m-4 mb-48 ${classes.cardContainer}`}>
        {onFormChange ? (
          <FormChangeSubscriber onFormChange={onFormChange} />
        ) : null}
        {/* {showTitle ? (
          <IonCardHeader className={`${classes.formTitle}`}>
            <DocTitle className="p-0" />
          </IonCardHeader>
        ) : null} */}
        <IonCardContent className='ion-padding'>
          <form 
            onSubmit={(event) => {
              // TODO: prevent submit on pressing enter on a field
              event.preventDefault(); // No funciona
            }}
          >
            {FormInputFields ? (<FormInputFields {...props} {...{fieldName, index, instance, entitySlug, form, values, classes, handleSubmit, submitting, fieldsRequired}} />) : ''}
          </form>
        </IonCardContent>
      </IonCard>
    </>);
  };
  
  _.defaults(classes, {
    formTitle: 'text-xl font-semibold mb-4 mr-3'
  });

  return (
    <>
      {/* as modal */}
      {editStyle === 'modal' ? (<>
        <IonModal
          isOpen={isModalOpen}
          onDidDismiss={onClose}
          backdropDismiss={false}
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={doc ? (doc.data || doc) : defaultValue}
            validate={validateForm}
            render={renderContentModal}
          />
        </IonModal>
      </>) : (<>
        {/* route */}
        <Form
          onSubmit={handleSubmit}
          initialValues={doc ? (doc.data || doc) : defaultValue}
          validate={validateForm}
          render={renderContentCard}
        />
      </>)}
    </>
  );
};

export default withRouter(SectionCrudForm);
