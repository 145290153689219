import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { IonTitle, IonCard, IonCheckbox } from '@ionic/react';
import dayjs from 'dayjs';
import { inputClasses } from '../../components/EntityTaxonomyForm';
import ObjectList from '../entity/ObjectList';
import { toAmount, getAmountNum, subtractAmountToNum } from '../../components/Form/utilsCurrency';


export const paymentFields = {
  num: {
    type: 'number',
    name: 'Pago'
  },
  amount: {
    type: 'numberAmount',
    name: 'Monto'
  },
  dueDate: {
    type: 'date', 
    name: 'Fecha de vencimiento',
    show: {
      format: 'ddd, DD - MMMM - YYYY'
    }
  },
  maxDueDate: {
    type: 'date',
    name: 'Fecha de corte',
    show: {
      format: 'ddd, DD - MMMM - YYYY'
    }
  },
  issuedDate: {
    type: 'date',
    name: 'Fecha de emisión',
    show: {
      format: 'ddd, DD - MMMM - YYYY'
    }
  },
  interest: {
    type: 'numberAmount',
    name: 'Interés'
  },
  // punitiveInterest: {
  //   type: 'numberAmount',
  //   name: 'Interés Punitivo',
  //   show: {
  //     format: 'currency'
  //   }
  // },
  remainingAmount: {
    type: 'numberAmount',
    name: 'Monto Restante'
  },
  amortization: {
    type: 'numberAmount',
    name: 'Amortización'
  },
  interests: {
    type: 'numberAmount',
    name: 'Interés'
  },
  paidDate: {
    type: 'date',
    name: 'Fecha de pago',
    show: {
      format: 'ddd, DD - MMMM - YYYY'
    }
  },
  status: {
    type: 'select',
    name: 'Estado',
    options: [{
      value: 'paid',
      label: 'Pagado'
    }, {
      value: 'pending',
      label: 'Pendiente'
    }, {
      value: 'overdue',
      label: 'Vencido'
    }, {
      value: 'rejected',
      label: 'Rechazado'
    }]
  },

  // meta
  meta_maxActivationDate: {
    type: 'date',
    name: 'Activado hasta fecha',
    show: {
      format: 'ddd, DD - MMMM - YYYY'
    }
  }
};

export const addDurationArray = (date, interval) => {
  let dateObj = dayjs(date);
  interval?.forEach(({ value, interval }) => {
    dateObj = dateObj.add(value, interval);
  });
  return dateObj;
};

export const calculatePayments = (paymentConfig = {}, contractData = {}) => {
  const {
    calcMethod,
    compensatoryInterest,
    interval,
    punitiveInterest,
    startStrategy,
    firstDueDateDelay,
    maxDueTime
  } = paymentConfig;
  const { creditAmount, paymentsQty, firstDueDate, paidAmount, paidQty } = contractData;

  if (!creditAmount || !paymentsQty || !firstDueDate) {
    return null;
  }

  let financedAmount = paidAmount ? subtractAmountToNum(creditAmount, paidAmount) : getAmountNum(creditAmount);
  financedAmount = _.isNaN(financedAmount) ? 0 : financedAmount;
  let payments = [];
  let totalAmount = 0;
  let totalInterest = 0;
  let remainingAmount = financedAmount;
  let issuedDate = dayjs(contractData.issuedDate);
  let paymentDate = dayjs(issuedDate);

  // apply strategy for first due date
  if (startStrategy === 'firstDueDateDelay' && firstDueDateDelay) {
    paymentDate = addDurationArray(paymentDate, firstDueDateDelay);
  } else if (startStrategy === 'customFirstDueDate' && firstDueDate) {
    paymentDate = dayjs(firstDueDate);
  } else if (startStrategy === 'contractDate') {
    paymentDate = dayjs(contractData.issuedDate);
  }

  // calc payments
  for (let i = 0; i < paymentsQty; i++) {
    let paymentAmount, interest, amortization;

    if (calcMethod === 'french') {
      paymentAmount = calculateFrenchPayment(financedAmount, paymentsQty, compensatoryInterest);
      interest = remainingAmount * (compensatoryInterest / 12);
      amortization = paymentAmount - interest;
    } else if (calcMethod === 'german') {
      amortization = financedAmount / paymentsQty;
      interest = remainingAmount * (compensatoryInterest / 12);
      paymentAmount = amortization + interest;
    }

    if (remainingAmount < amortization) {
      amortization = remainingAmount;
    }

    let paymentDueDate;
    if (maxDueTime) {
      paymentDueDate = addDurationArray(paymentDate, maxDueTime);
    }

    let newPayment = {
      num: i + 1,
      amount: toAmount(paymentAmount),
      issuedDate: issuedDate.format('YYYY-MM-DD'),
      dueDate: paymentDate.format('YYYY-MM-DD'),
      maxDueDate: paymentDueDate?.format('YYYY-MM-DD'),
      remainingAmount: toAmount(remainingAmount - amortization),
      amortization: toAmount(amortization),
      interests: toAmount(interest),
      status: 'pending'
    };

    // mark as paid if already payed
    if (paidQty && paidQty > i) {
      newPayment.paidDate = dayjs(contractData.issuedDate).format('YYYY-MM-DD');
      newPayment.status = 'paid';
    }

    payments.push(newPayment);

    totalAmount += paymentAmount;
    totalInterest += interest;
    remainingAmount -= amortization;
    paymentDate = addDurationArray(paymentDate, interval);
  }

  return {
    payments,
    totalAmount: toAmount(totalAmount),
    totalInterest: toAmount(totalInterest)
  };
};

export const calculateFrenchPayment = (financedAmount, paymentsQty, compensatoryInterest) => {
  financedAmount = getAmountNum(financedAmount);
  const monthlyInterest = compensatoryInterest / 12;
  return (financedAmount * monthlyInterest * Math.pow(1 + monthlyInterest, paymentsQty)) / (Math.pow(1 + monthlyInterest, paymentsQty) - 1);
};

// paymentConfig = {
//   "customFirstDueDate": true,
//   "calcMethod": "french", // "german", "french"
//   "compensatoryInterest": 0.12,
//   "interval": [
//     {
//       "interval": "month",
//       "value": 1
//     }
//   ],
//   "punitiveInterest": 0.1,
//   "firstDueDateDelay": [
//     {
//       "interval": "day",
//       "value": 60
//     }
//   ],
//   "graceTime": [
//     {
//       "interval": "day",
//       "value": 5
//     }
//   ]
// }

// contract = {
//   creditAmount: 20000000,
//   paymentsQty: 24,
//   dueDate: 5 // Día del mes en el que se realiza el pago
// }

// show or calc payment list
const PaymentsList = (props) => {
  let { data, paymentConfig, contractData, showSelector, selectedIds, onChangeSelectedIds } = props;
  const entitySlug = 'payments';
  const [paymentsSpec, setPaymentsSpec] = useState({});
  const [selectedPayments, setSelectedPayments] = useState([]);

  useEffect(() => {
    if (data) {
      setPaymentsSpec(data);
    } else {
      setPaymentsSpec(calculatePayments(paymentConfig, contractData));
    }
  }, [paymentConfig, contractData]);

  useEffect(() => {
    setSelectedPayments(selectedIds || []);
  }, [selectedIds]);

  const handleSelectPayment = (paymentId) => {
    let newSelectedPayments;
    if (selectedPayments.includes(paymentId)) {
      newSelectedPayments = selectedPayments.filter(id => id !== paymentId);
    } else {
      newSelectedPayments = [...selectedPayments, paymentId];
    }
    setSelectedPayments(newSelectedPayments);
    onChangeSelectedIds && onChangeSelectedIds(newSelectedPayments);
  };

  if (!paymentsSpec?.payments) {
    return null;
  }

  return (<>
    <div className="flex flex-row gap-2">
      <div className="">
        <IonTitle className={`px-0 ${inputClasses.fieldLabel}`}>
          Monto con interés
        </IonTitle>
        {paymentsSpec.totalAmount?.qty}
      </div>
      <div className="">
        <IonTitle className={`px-0 ${inputClasses.fieldLabel}`}>
          Interés
        </IonTitle>
        {paymentsSpec.totalInterest?.qty}
      </div>
    </div>
    <IonTitle className={`mt-4 px-0 ${inputClasses.fieldLabel}`}>
      Lista de Pagos
    </IonTitle>
    <IonCard className="mx-0 mt-0">
      <ObjectList {...props}
        design="table"
        entitySlug={entitySlug}
        values={paymentsSpec.payments}
        fields={paymentFields}
        classes={{
          row: 'flex items-center' // Agregar clase para alinear elementos en la fila
        }}
        prependColumn={(doc) => (
          showSelector && (
            <IonCheckbox
              slot="start"
              className="!m-0"
              checked={selectedPayments.includes(doc.id)}
              onIonChange={() => handleSelectPayment(doc.id)}
            />
          )
        )}
      />
    </IonCard>
  </>);
};

export default PaymentsList;