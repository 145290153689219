import _ from 'lodash';
import dataTypeBlockStack from '../blockStack/dataTypeBlockStack';


export default function () {
  return {
    slug: 'pagesTemplates',
    name: 'Templates de páginas',
    scope: 'global',
    bundles: ['store'],
    entities: [
      'pagesTemplates'
    ],
    permissions: [
      { slug: 'pagesTemplates', label: 'Templates de páginas', actionsToAdd: ['instance:read', 'instance:list', 'instance:create', 'instance:update'] },
    ],
    components: {
      'pagesTemplates': {
        crud: {},
        codedDataTypes: {
          'stack': dataTypeBlockStack
        }
      }
    }
  };
};